import React, { Component, createRef } from "react";
// import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";
import "./style.css";
import * as LINK from "../../../../constants/Links";
// import type { MenuProps } from 'antd';
import { Divider, Radio, Table, Pagination, Input, Button, Modal, Image, Tag, Switch as SwitchA, message, DatePicker, Tooltip, Form, Select, TreeSelect, Checkbox, Avatar, Card, Row, Col, InputNumber, Collapse, Tabs, Popover, Alert, Dropdown, Menu, Space, List, Skeleton,Empty } from 'antd';
import moment from 'moment';

import * as Colors from "../../../../constants/Colors";
import { arrColors } from "../../../../constants/Colors";
import Draggable from 'react-draggable';
import {
FolderViewOutlined, UnorderedListOutlined,
DeleteOutlined, AppstoreOutlined,
EditOutlined, SearchOutlined, PlusCircleOutlined, SafetyOutlined, DiffOutlined, SaveOutlined, CheckOutlined, CarOutlined, GiftOutlined
} from '@ant-design/icons';
import { formatMN1, CutString, getParamsUrl, checkScreenSize } from "../../../../service";
import BoxOrder from "./../box/index";
import { META } from "../../../../utils/constant";
import IndexMobile from "./indexMobile";
import { Runfirst } from "../../../../constants/Runfirst";
import { Icons } from "../../../../constants/ListIcons";
import { TotalBottom } from "../../../../components/TotalBottom/TotalBottom";

const { Search } = Input;
const { RangePicker } = DatePicker;

class Index extends Component {

    constructor(props) {
        super(props);
        this.draggleRef = new createRef(null);
        this.formRef = new createRef(null);
        this.state = {
            meta: {
                ...META
            },
            ListOrders: [],
            ListStore: [],
            ListUsers: [],
            ListProvinces: [],
            ListDistricts: [],
            ListWars: [],
            Province_id_: "",
            groupReport:{},

            rowSelection: {
                onChange: (selectedRowKeys, selectedRows) => {
                    console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                },
                getCheckboxProps: (record) => ({
                    disabled: record.name === 'Disabled User',
                    // Column configuration not to be checked
                    name: record.name,
                }),
            },
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.title.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',
                    sorter: (a, b) => a.slug - b.slug,

                },
                {
                    title: 'Giá',
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,

                },
                {
                    title: 'Giảm giá',
                    dataIndex: 'discount',
                    sorter: (a, b) => a.discount - b.discount,

                },
                {
                    title: 'Danh mục ',
                    dataIndex: 'category',
                    // render: (text, record, index) => (

                    //     <span>{text}</span>
                    // )

                },
                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                                    <DiffOutlined style={{ color: Colors.colorView }} /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            data: [],
            StoreOrders: {},
            visible: false,
            confirmLoading: false,
            modalText: "",
            isloading: false,
            ListCategory: [],
            treeData: [],
            search: {},
            ListTags: [],
            isLoadingSearch: false,
            statusSearch: "",
            visibledetail: false,

            bounds: {
                left: 0,
                top: 0,
                bottom: 0,
                right: 0,
            },
            record: "",
            disabled: true,
            isShowList: false,
            ListEcommerceConfig: [],
            ListEcommerceType: [],
            idsCheck:[],
            selectedRows:[],
            selectedRowKeys:[],
            isSket:true,
            itemsChose: [
                
                {
                    key: '1',
                    label:"Đồng bộ",
                    children: [
                        {
                            key: '1-1',
                            label: '3rd menu item',
                        },
                        {
                            key: '1-2',
                            label: '4th menu item',
                        },
                    ],
                },
                {
                    key: '2',
                    label: <span onClick={this.onchangeChoose}>Thêm vào menu</span>,
                },
                {
                    key: '3',
                    label:<span onClick={this.deletMutiple}>Xoá</span>,
                },
                {
                    key: '4',
                    label:<span onClick={this.choseAll}>Chọn tất cả</span>,
                },
            ],
        }

    }
    
    choseAll =()=>{
        const { ListOrders } = this.state;
        const dataProducts = ListOrders.data;
        const dataChecks=[];
        dataProducts.filter(item => {
            dataChecks.push(item.id)
        })
        this.setState(state =>
            {
                return {
                    idsCheck: dataChecks,
                    
                }
            })
        
    }
    deletMutiple=()=>{
        this.setState(state =>
            {
                return {
                   visible:true,
                   isdeleteMutiple:true,
                   modalText: <span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>
                }
            })
    }
    onChangeCheck = (item) =>
        {
            const { idsCheck,ListOrders } = this.state;
            let idsCheck_ = [...idsCheck]
            if (!idsCheck_.includes(item.id)) {
                idsCheck_ = [...idsCheck, item.id]
            } else {
                idsCheck_ = idsCheck.filter(el =>
                {
                    return el !== item.id
                })
            }
            const dataProducts = ListOrders.data;
            const dataChecks = dataProducts.filter(item => {
                return idsCheck_.includes(item.id)
            })
            // const isCheckEcom=[];
            // for (let x in dataChecks){
            //     const item= dataChecks[x];
            //     const ecommerce = item.ecommerce;
            //     for (let y in ecommerce) {
            //         const item_ = ecommerce[y];
            //         if (!isCheckEcom.includes(item_.code)){
            //             isCheckEcom.push(item_.code)
            //         }
            //     }
            // }
            // console.log('====================================');
            // console.log(idsCheck_, dataChecks, isCheckEcom);
            // console.log('====================================');
            this.setState(state =>
            {
                return {
                    idsCheck: idsCheck_,
                    // isCheckEcom: isCheckEcom
                }
            })
        }
    
    SetisShowList = (type) => {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state => {
            return {
                isShowList: type
            }
        })
    }
    setBounds = (bounds) => {
        this.setState(state => {
            return {
                bounds: bounds
            }
        })
    }
    setDisabled = (type) => {
        this.setState(state => {
            return {
                disabled: type
            }
        })
    }
    onStart = (_event, uiData) => {
        const { clientWidth, clientHeight } = window.document.documentElement;
        const targetRect = this.draggleRef.current?.getBoundingClientRect();

        if (!targetRect) {
            return;
        }

        this.setBounds({
            left: -targetRect.left + uiData.x,
            right: clientWidth - (targetRect.right - uiData.x),
            top: -targetRect.top + uiData.y,
            bottom: clientHeight - (targetRect.bottom - uiData.y),
        });
    };
    onChangetext = (e, type, group) => {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    setVisibleDetail = (type) => {
        this.setState(state => {
            return {
                visibledetail: type
            }
        })
    }
    handleOkDetail = (e) => {
        this.setState(state => {
            return {
                record: e,
                isreset: false
            }
        }, () => {
            this.setVisibleDetail(true);
        })


    };

    handleCancelDetail = () => {
        this.setState(state => {
            return {
                isreset: true
            }
        }, () => {
            this.setVisibleDetail(false);
        })
        // console.log('Clicked cancel button');
        this.setVisibleDetail(false);
    };

    componentDidMount = () => {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        this.props.SearchUserRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        },{data:{}});
        this.props.ListProvincesRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        const urlParams = getParamsUrl();
        if (urlParams.province_id) {
            this.props.ListDistrictsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: urlParams.province_id
            });

            this.setState(state => {
                return {
                    Province_id_: urlParams.province_id,

                }
            })
        }
        if (urlParams.district_id) {
            this.props.ListWarsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
                provinceid: urlParams.province_id, districtid: urlParams.district_id
            });
        }
        // const urlParams = getParamsUrl();
        this.formRef.current.setFieldsValue(urlParams);
        this.props.SearchOrdersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: urlParams });
        this.props.ListStoreRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        });
        this.props.SettingsShowByCodeRequest({ code: "ORDER" })

        let productsData = localStorage.getItem("productsData");

        if (productsData) {

            productsData = JSON.parse(productsData);
        } else {
            productsData = {}
        }

        const runfirstdata = {};
        if (productsData) {

            for (let x in Runfirst) {
                if (x === "ListEcommerceConfig" || x === "ListEcommerceType") {
                    if (!productsData[x] || !productsData[x].data) {

                        const dataF = []

                        Runfirst[x](this.props, this.state, dataF)


                    } else {
                        runfirstdata[x] = productsData[x].data
                    }
                }
            }
        }

        this.setState(state => {
            return { ...state, ...runfirstdata }
        })
    }
    static getDerivedStateFromProps(nextProps, prevState) {
        if (nextProps.Web.SettingsShowByCode && nextProps.Web.SettingsShowByCode?.data !== prevState.SettingsShowByCode?.data) {


            return {
                SettingsShowByCode: nextProps.Web.SettingsShowByCode,
                StoreOrders: nextProps.Web.StoreOrders,
                ListOrders: nextProps.Web.ListOrders,

            };

        }
        if (nextProps.Web.StoreOrders && nextProps.Web.StoreOrders !== prevState.StoreOrders) {
            // console.log(nextProps.Web.StoreOrders);

            return {
                StoreOrders: nextProps.Web.StoreOrders,
                ListOrders: nextProps.Web.ListOrders,

            };

        }
        if (nextProps.Web && nextProps.Web.ListOrders !== prevState.ListOrders) {
            // console.log("nextProps.Web.ListOrdersnextProps.Web.ListOrders",nextProps.Web.ListOrders);
            return {
                ListOrders: nextProps.Web.ListOrders,
                isLoadingSearch: false
            };


        }

        if (nextProps.Web && nextProps.Web.ListUsers.data !== prevState.ListUsers) {


            return {
                ListUsers: nextProps.Web.ListUsers.data,

            };


        }

        if (nextProps.Web && nextProps.Web.ListProvinces.data !== prevState.ListProvinces) {

            return {
                ListProvinces: nextProps.Web.ListProvinces.data,

            };


        }


        if (nextProps.Web && nextProps.Web.ListWars.data !== prevState.ListWars) {


            return {
                ListWars: nextProps.Web.ListWars.data,

            };


        }


        if (nextProps.Web && nextProps.Web.ListDistricts.data !== prevState.ListDistricts) {


            return {
                ListDistricts: nextProps.Web.ListDistricts.data,

            };


        }
        if (nextProps.Web && nextProps.Web.ListStore !== prevState.ListStore) {


            return {
                ListStore: nextProps.Web.ListStore.data,

            };


        }
        if (Runfirst) {
            for (let x in Runfirst) {
                if (x === "ListEcommerceConfig" || x === "ListEcommerceType") {
                    if (nextProps.Web[x] && nextProps.Web[x].data && nextProps.Web[x].data !== prevState[x]) {


                        return {
                            [x]: nextProps.Web[x].data,

                        };

                    }
                }


            }

        }
        return null;


    }

    componentDidUpdate(prevProps, prevState) {
        // if (this.state.ListEcommerceConfig !== prevState.ListEcommerceConfig) {

        //     const checkSyncdata = [];
        //     const ListEcommerceData = this.state.ListEcommerceConfig;
        //     ListEcommerceData.map((item, index) =>
        //     {
        //         if (item) {
        //             checkSyncdata.push({ label: item.name, value: item.code })
        //         }

        //     });
        //     this.setState(state =>
        //     {
        //         return {
        //             checkSync: checkSyncdata
        //         }
        //     })
        // }
        if (this.state.StoreOrders !== prevState.StoreOrders) {
            let { StoreOrders, isDuplicate } = this.state;

            try {
                if (StoreOrders.code === 200) {
                    if (StoreOrders.message && typeof StoreOrders.message === "string") {
                        message.success(StoreOrders.message)
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    this.formRef.current.setFieldsValue(urlParams);
                    this.props.SearchOrdersRequest({
                        limit: this.state.meta.per_page,
                        page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: urlParams });

                }
                else {
                    if (StoreOrders.message && typeof StoreOrders.message === "string") {

                        message.error(StoreOrders.message)
                    } else {
                        for (let x in StoreOrders.message) {
                            let mess = StoreOrders.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.handleCancel()
            this.setConfirmLoading(false);
            this.setState(state => {
                return {
                    StoreOrders: "",

                    isDuplicate: false
                }
            })
            this.props.ResetStoreOrders();

        }
        if (this.state.ListOrders !== prevState.ListOrders) {
            let { ListOrders } = this.state;

            if (ListOrders.code !== 200 && ListOrders.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
            }

            this.data()
        }
        if (this.state.SettingsShowByCode !== prevState.SettingsShowByCode) {

            try {
                const data_ = JSON.parse(this.state.SettingsShowByCode?.data?.data);

                const dataprint = data_?.list_status_default
                if (dataprint) {

                    this.setState(state => {
                        return {
                            arrSaveBill: dataprint,
                            data: []
                        }
                    }, () => {
                        this.data()
                    })
                }

            } catch (error) {
                console.error(error);
            }
        }

        if (this.state.ListProvinces !== prevState.ListProvinces) {

            const urlParams = getParamsUrl();

            if (urlParams.province_id) {
                this.formRef.current.setFieldsValue({ province_id: (1 * urlParams.province_id) });
            }

        }
        if (this.state.ListDistricts !== prevState.ListDistricts) {

            const urlParams = getParamsUrl();
            if (urlParams.district_id) {
                this.formRef.current.setFieldsValue({ district_id: (1 * urlParams.district_id) });
            }
        }
        if (this.state.ListWars !== prevState.ListWars) {

            const urlParams = getParamsUrl();
            if (urlParams.war_id) {
                this.formRef.current.setFieldsValue({ war_id: (1 * urlParams.war_id) });
            }


        }
    }
    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }

    setColumn_ = (filters) => {
        this.setState(state => {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        width: "5%",
                        align: 'center',

                    },
                    {
                        title: 'Mã',
                        dataIndex: 'v_number',
                        // filterMode: 'tree',
                        // width: "7%",
                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) => {

                            return record.title.startsWith(value)

                        },
                        sorter: (a, b) => a.title - b.title,
                        render: (text, record, index) => {
                            return <span style={{ color: Colors.pop2[2], fontSize: "bold" }}>{text}</span>
                        }
                    },

                    {
                        title: 'Người mua',
                        dataIndex: 'fullname',
                        // width: "7%",
                        sorter: (a, b) => a.fullname - b.fullname,
                        filterSearch: true,
                        filters: filters.slug,
                        onFilter: (value, record) => {

                            return record.fullname === value

                        },

                    },
                    {
                        title: 'Phone',
                        dataIndex: 'phone_number',
                        align: 'center',


                    },
                    {
                        title: 'Địa chỉ',
                        dataIndex: 'address',

                        render: (text, record, index) => {
                            return CutString(text, 20, false)
                        }

                    },

                    {
                        title: 'Sản phẩm',
                        dataIndex: 'ttsp',
                        align: 'center',

                        render: (text, record, index) => {

                            return <button className="reset_btn" style={{ color: Colors.color1 }} onClick={() => this.handleOkDetail(record)}>xem</button>
                        }

                    },
                    {
                        title: 'Mã VD',
                        dataIndex: 'lading_code',
                        align: 'left',

                        render: (text, record, index) => {

                            return CutString(text, 20, false)
                        }

                    },

                    {
                        title: 'Tổng tiền',
                        dataIndex: 'total_money',
                        align: 'left',
                        sorter: (a, b) => a.total_money - b.total_money,
                        render: (text, record, index) => {

                            return <span>{formatMN1(parseFloat(text)+parseFloat(record.fee_ship))}</span>
                        }

                    },
                    {
                        title: 'TT sau GG',
                        dataIndex: '',
                        align: 'left',
                        sorter: (a, b) => (a.total_money -a.discount-a.total_discount_detail) - (b.total_money-b.discount-b.total_discount_detail),
                        render: (text, record, index) => {

                            return <span>{formatMN1(parseFloat(record.total_money)-parseFloat(record.discount) -parseFloat(record.total_discount_detail) )}</span>
                        }

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        // width: "7%",
                        filterSearch: true,
                        filters: filters.created_at,
                        onFilter: (value, record) => {

                            return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                        },
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) => {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Xác nhận',
                        dataIndex: 'status_text',
                        align: 'center',
                        width: "15%",
                        render: (text, record, index) => {
                            // console.log(this.state.arrSaveBill);
                            let color="";
                            return <Popover placement="top" content={<div style={{ display: "flex", flexDirection: "column", padding: "10px" }}>
                                {this.state.arrSaveBill && this.state.arrSaveBill.length > 0 && this.state.arrSaveBill.map((item, index) => {
                                    if(record.status==item.id){
                                        color=item.color;
                                    }
                                    return <div key={index}> <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                        onClick={() => {
                                            this.updateStatus(item.id, record);
                                        }}
                                    >
                                        {Icons && Icons.map((Icon, index_) => {

                                            if (item.icon === Icon.name) {
                                                const Ic = Icon.icon;
                                                return <Ic key={index_} />
                                            }


                                        })}
                                        <span style={{ marginLeft: "10px" }}>{item.name}</span>
                                    </button>
                                        <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                                    </div>
                                })}
                                {/* <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                    onClick={() =>
                                    {
                                        this.update_(1, record);
                                    }}
                                >
                                    <SafetyOutlined /> <span style={{ marginLeft: "10px" }}>Xác nhận đơn hàng</span>
                                </button>
                                <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                                <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                    onClick={() =>
                                    {
                                        this.update_(2, record);
                                    }}
                                >
                                    <GiftOutlined /> <span style={{ marginLeft: "10px" }}>Đang đóng hàng</span>
                                </button>
                                <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                                <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                    onClick={() =>
                                    {
                                        this.update_(3, record);
                                    }}
                                >
                                    <CheckOutlined /> <span style={{ marginLeft: "10px" }}>Chờ chuyển hàng</span>
                                </button>
                                <Divider dashed={true} style={{ borderColor: Colors.colorblue, margin: "5px 0" }} />
                                <button className="reset_btn" style={{ display: "flex", flexDirection: "row", justifyContent: "flex-start", padding: "5px 0px", alignItems: "center", cursor: "pointer" }}
                                    onClick={() =>
                                    {
                                        this.update_(4, record);
                                    }}
                                >
                                    <CarOutlined /> <span style={{ marginLeft: "10px" }}>Gửi hàng di</span>
                                </button> */}

                            </div>} title="Lưu" >
                                {record?.status_transport && record?.status_transport?.[record?.status_transport.length - 1] && <Tag color={color} style={{ cursor: "pointer" }}>{CutString(record?.status_transport?.[record?.status_transport.length - 1].name, 10, false)}</Tag>}
                                {!record?.status_transport || !record?.status_transport?.[record?.status_transport.length - 1] && <Tag color={color} style={{ cursor: "pointer" }}>Mới Tạo</Tag>}

                            </Popover>
                        },
                    },
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "7%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.handleOkDetail(record) }}> <FolderViewOutlined style={{ color: Colors.colorView }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                                        <DiffOutlined style={{ color: Colors.colorView }} /></button>
                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }
    duplicateItem = (record) => {
        this.setState(state => {
            return {
                isNewList: true,
                isDuplicate: true
            }
        })
        this.SetisLoading(true)
        this.props.duplicateOrdersRequest({ id: record.id })
    }
    update_ = (e, record) => {
        this.setState(state => {
            return {
                isNewList: true
            }
        })

        record.status = e
        this.SetisLoading(true)
        this.props.StoreOrdersRequest(record, record.id);

    }

    updateStatus = (e, record) => {
      
      
        this.SetisLoading(true)
        this.props.UpdateStatusOrdersRequest({order_id:record.id, status:e});

    }
    data = () => {
        try {
         
            
            let { ListOrders } = this.state;
            console.log("ListOrdersListOrdersListOrdersListOrders ",ListOrders);
            if (ListOrders.length <= 0) {
                return;
            }

            let data = [];
            let listfil = {};
            let meta = {
                ...this.state.meta,
                current_page: ListOrders?.data.current_page,
                first_page_url: ListOrders?.data.first_page_url,
                from: ListOrders?.data.from,
                last_page: ListOrders?.data.last_page,
                last_page_url: ListOrders?.data.last_page_url,
                links: ListOrders?.data.links,
                next_page_url: ListOrders?.data.next_page_url,
                path: ListOrders?.data.path,
                per_page: ListOrders?.data.per_page,
                prev_page_url: ListOrders?.data.prev_page_url,
                to: ListOrders?.data.to,
                total: ListOrders?.data.total,
            };
            let filtilte = [];
            let filslug = [];
            let filprice = [];
            let fildiscount = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            ListOrders?.data.data.map((item, index) => {
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                fildiscount = [...fildiscount, {
                    text: item.discount,
                    value: item.discount,
                }]
                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = item.id
                data.push(item)

            });
            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]

            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, discount: fildiscount }
            this.setColumn_(listfil);
            this.setState(state => {
                return {
                    data: data,
                    meta: meta,
                    isSket:false,
                    groupReport:ListOrders?.groupReport
                }
            }, () => {
                this.setState(state => {
                    return {
                        isNewList: false,
                    }
                });

            })
        } catch (error) {
            console.error(error);
        }
    }

    editRows = (record) => {

        this.props.history.push({
            pathname: LINK.WEB_ORDERS_EDIT + "/" + record.id,
        });

    }

    onFinishForm = (values) => {
        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
        // delete filteredvalues.created_at
        this.props.SearchOrdersRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: values });

        const searchParams = new URLSearchParams(filteredvalues).toString();
        this.props.history.push(`${LINK.WEB_ORDERS}?${searchParams}`);
        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }
    onResetFrom = () => {

        this.formRef.current.resetFields();
        this.formRef.current.submit();
        this.setState(state => {
            return {
                isNewList: false
            }
        })
    }
    deleteModal = (record) => {
        this.setState(state => {
            return {
                record: { ...record, deleted: 1 },
                // isNewList:true
            }
        }, () => {
            this.setModalText(<span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>)
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    setModalText = (type) => {
        this.setState(state => {
            return {
                modalText: type
            }
        })
    }
    setVisible = (type) => {
        this.setState(state => {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) => {
        this.setState(state => {
            return {
                confirmLoading: type
            }
        })
    }
    handleOkDelete = () => {
        const { record } = this.state


        this.setConfirmLoading(true);
        if(this.state.isdeleteMutiple){
            this.props.DeleteMultipleOrdersRequest({ids:this.state.idsCheck})
        }else{
            this.props.DeleteOrdersRequest(record.id)
        }
        
        this.setState(state => {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    OnPagination = (page, pageSize) => {
        this.props.ListOrdersRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        });
    }
    updateRecord = (e, record) => {
        record.status = e
        this.SetisLoading(true)
        this.props.StoreOrdersRequest(record, record.id);
        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }

    onchangeProvinces = (e) => {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
        this.setState(state => {
            return {
                Province_id_: e
            }
        })
        this.props.ListDistrictsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, provinceid: e
        });
    }
    onchangeDistricts = (e) => {
        this.props.ListWarsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort,
            provinceid: this.state.Province_id_, districtid: e
        });
    }
    changeTabOrder = (type) => {
        this.props.history.push(type);

    }
    render() {
        let { routers } = this.props;

        return (
            <div className="main_content order_" style={{ overflow: "unset" }}>


                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Modal
                    title={
                        <div
                            style={{
                                width: '100%',
                                cursor: 'move',
                            }}
                            onMouseOver={() => {
                                if (this.state.disabled) {
                                    this.setDisabled(false);
                                }
                            }}
                            onMouseOut={() => {
                                this.setDisabled(true);
                            }}
                            onFocus={() => { }}
                            onBlur={() => { }} // end
                        >
                            Chi tiết: <span style={{ color: Colors.colorblue }}>{this.state.record.v_number}</span>
                        </div>
                    }
                    modalRender={(modal) => (
                        <Draggable
                            disabled={this.state.disabled}
                            bounds={this.state.boundsTrans}
                            onStart={(event, uiData) => this.onStart(event, uiData)}
                        >
                            <div ref={this.draggleRef}>{modal}</div>
                        </Draggable>
                    )}
                    visible={this.state.visibledetail}
                    onOk={this.handleOkDetail}
                    onCancel={this.handleCancelDetail}

                    footer={[

                    ]}
                    width={"95%"}
                    bodyStyle={{
                        height: "80vh",
                        overflowY: "scroll"
                    }}
                    style={{ top: "60px", position: "relative" }}
                >
                    <div className="modal_content_">
                        <BoxOrder isView={true} record={this.state.record} idrecord={this.state.record.id} isreset={this.state.isreset} handleCancelDetail={this.handleCancelDetail} />
                    </div>
                </Modal>
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>

                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Đơn hàng</span>
                            {this.state.ListEcommerceType &&  this.state.ListEcommerceType.map((value) => {

                                            return   <Popover placement="bottom" content={
                                                <List

                                                    itemLayout="horizontal"

                                                    dataSource={this.state.ListEcommerceConfig?.filter((item) => {return item.ecommerce_type_id==value.id})}
                                                    renderItem={(item) => (
                                                        <List.Item>
                                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                                <Link to={LINK.WEB_ORDERS_TIKTOK + "/" + item?.id_}>{item?.name}</Link>

                                                            </div>


                                                        </List.Item>
                                                    )}
                                                />

                                            } trigger="click">
                                                <Tag icon={<div dangerouslySetInnerHTML={{ __html: value.icon }} />} color={value.color} className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active d-flex" : "tabs_ecommerce  d-flex"} >{value.name}</Tag>
                                            </Popover>
                                            })}
                            {/* <Popover placement="bottom" content={
                                <List

                                    itemLayout="horizontal"

                                    dataSource={this.state.ListEcommerceConfig}
                                    renderItem={(item) => (
                                        <List.Item
                                        >
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Link to={LINK.WEB_ORDERS_TIKTOK + "/" + item?.id_}>{item?.name}</Link>

                                            </div>


                                        </List.Item>
                                    )}
                                />

                            } trigger="click">
                                <Tag icon={<i className="fab fa-tiktok"></i>} color="purple" className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active" : "tabs_ecommerce"} >Tiktok</Tag>
                            </Popover> */}

                            {/* <Tag color="#f50" className={this.state.tabOrder === "shopee" ? "tabs_ecommerce active" : "tabs_ecommerce"} onClick={() => this.changeTabOrder("shopee")}>Shopee</Tag> */}
                        </div>
                        <Link to={LINK.WEB_ORDERS_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>} bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                            // maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px",

                        }}
                    >
                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="orderSearch" onFinish={this.onFinishForm} ref={this.formRef}

                                    style={{ padding: 0,width:"100%" }}
                                >
                                    <Form.Item style={{ margin: 0 }}>
                                        <Input.Group compact>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="v_number" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Mã đơn" name="v_number" id="v_number" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="phone_number" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Số điện thoại" name="phone_number" id="phone_number" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="lading_code" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Mã vận đơn" name="lading_code" id="lading_code" style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="customer" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="tên,phone,mã kh..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="note" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Ghi chú..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="products" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="tiêu đề,slug,mã sản phẩm..." style={{ width: "100%" }} />
                                                    </Form.Item>

                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="address" noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Input placeholder="Địa chỉ" style={{ width: "100%" }} />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"store_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Cửa hàng"
                                                            treeDefaultExpandAll
                                                            options={(this.state.ListStore || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"user_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Người tạo"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"user_id_customer_care"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhân viên chăm sóc"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"user_id_marketer"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhân viên marketing"

                                                            options={(this.state.ListUsers || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name={"province_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            // treeData={this.state.treeData}

                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Tỉnh/thành"

                                                            options={(this.state.ListProvinces || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                            onChange={this.onchangeProvinces}
                                                        /></Form.Item>
                                                </div>
                                            </div>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"district_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            // treeData={this.state.treeData}

                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Quận huyện"

                                                            options={(this.state.ListDistricts || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                            onChange={this.onchangeDistricts}

                                                        /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"war_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Phường/xã"

                                                            options={(this.state.ListWars || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))} /></Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name={"info_transport_id"} noStyle style={{
                                                        width: "100%"
                                                    }}>
                                                        <Select
                                                            showSearch
                                                            style={{ width: '100%' }}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Nhà vận chuyển" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item noStyle style={{
                                                        width: "100%"
                                                    }} >
                                                        <RangePicker style={{
                                                            width: "100%",
                                                        }} name="created_at" id="created_at" onChange={(e, date) => { this.onChangetext(date, "created_at", "search") }} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                <div style={{ display: "flex" }}>
                                                    <Tooltip title="search">
                                                        <Button type="primary" htmlType="submit" style={{ width: "100%" }} icon={<SearchOutlined />} loading={this.state.isLoadingSearch} />
                                                    </Tooltip>
                                                    <Tooltip title="Reset">
                                                        <Button style={{ width: "100%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                    </Tooltip>
                                                    {(this.state.idsCheck.length > 0 || this.state.selectedRowKeys.length>0) &&  
                                                            
                                                            <Dropdown
                                                                menu={{
                                                                    items:this.state.itemsChose
                                                            }}
                                                                placement="bottom"
                                                                arrow
                                                                trigger={"hover"}
                                                              
                                                               
                                                            >
                                                                <Button type={"primary"} htmlType="button">Lựa chọn</Button>
                                                            </Dropdown>
                                                            } 
                                                </div>

                                                </div>
                                                {/* <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Tooltip title="Reset">
                                                        <Button style={{ width: "100%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                    </Tooltip>
                                                    {(this.state.idsCheck.length > 0 || this.state.selectedRowKeys.length>0) &&  
                                                            
                                                            <Dropdown
                                                                menu={{
                                                                    items:this.state.itemsChose
                                                            }}
                                                                placement="bottom"
                                                                arrow
                                                                trigger={"hover"}
                                                              
                                                               
                                                            >
                                                                <Button type={"primary"} htmlType="button">Lựa chọn</Button>
                                                            </Dropdown>
                                                            } 
                                                </div> */}
                                            </div>

                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>

                        </div>
                        {this.state.isSket && <Skeleton active />}
                        {!this.state.isSket && this.state.data.length<=0 && <Empty/>}
                        {this.state.data.length > 0 && <>
                            {(checkScreenSize().width < 600 || !this.state.isShowList) && <IndexMobile lists={this.state.data} loading={this.state.loading}
                                updateRecord={this.updateRecord}
                                editRows={this.editRows}
                                deleteModal={this.deleteModal}
                                duplicateItem={this.duplicateItem}
                                current_page={this.state.meta.current_page}
                                per_page={this.state.meta.per_page}
                                last_page={this.state.meta.last_page}
                                OnPagination={this.OnPagination}
                                isNewList={this.state.isNewList}
                                arrSaveBill={this.state.arrSaveBill}
                                onChangeCheck={this.onChangeCheck}
                                idsCheck={this.state.idsCheck}
                            />}
                            {checkScreenSize().width >= 600 && this.state.isShowList && <>
                                <Table
                                    rowClassName={(record, index) =>
                                        {
                                            return "__row_";
                                        }}
                                        rowSelection={{
                                            onChange: (selectedRowKeys, selectedRows) =>
                                            {
                                                this.setState(state=>{
                                                    return{
                                                        selectedRowKeys: selectedRowKeys,
                                                        selectedRows: selectedRows
                                                    }
                                                })
                                                console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                                            },
                                            getCheckboxProps: (record) => ({
                                                // disabled: record.name === 'Disabled User',
                                                // // Column configuration not to be checked
                                                name: record.name,
                                            }),
                                        }}
                                    columns={this.state.columns}
                                    dataSource={this.state.data}
                                    pagination={false}
                                    // scroll={{
                                    //     y: "55vh",
                                    // }}

                                    style={{ paddingBottom: "30px" }}
                                />
                                <div className="pagination_">
                                    <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                </div>
                            </>

                            }
                        </>
                        }
                    </Card>
                </div>
                <TotalBottom  groupReport={this.state.groupReport}/>

            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {

        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {

        ListOrdersRequest: (data) => {
            dispatch(ActionsWeb.ListOrdersRequest(data));
        },
        ListStoreRequest: (data) => {
            dispatch(ActionsWeb.ListStoreRequest(data));
        },
        SearchUserRequest: (body, data) => {
            dispatch(ActionsWeb.SearchUserRequest(body, data));
        },
        ListProvincesRequest: (data) => {
            dispatch(ActionsWeb.ListProvincesRequest(data));
        },
        ListDistrictsRequest: (data) => {
            dispatch(ActionsWeb.ListDistrictsRequest(data));
        },
        ListWarsRequest: (data) => {
            dispatch(ActionsWeb.ListWarsRequest(data));
        },
        SearchOrdersRequest: (body, data) => {
            dispatch(ActionsWeb.SearchOrdersRequest(body, data));
        },
        StoreOrdersRequest: (data, id) => {
            dispatch(ActionsWeb.StoreOrdersRequest(data, id));
        },
        ResetStoreOrders: () => {
            dispatch(ActionsWeb.ResetStoreOrders());
        },
        duplicateOrdersRequest: (body) => {
            dispatch(ActionsWeb.duplicateOrdersRequest(body));
        },
        ListStatusTransportRequest: (body) => {
            dispatch(ActionsWeb.ListStatusTransportRequest(body));
        },
        SettingsShowByCodeRequest: (body) => {
            dispatch(ActionsWeb.SettingsShowByCodeRequest(body));
        },
        DeleteOrdersRequest: (body) => {
            dispatch(ActionsWeb.DeleteOrdersRequest(body));
        },
        DeleteMultipleOrdersRequest: (body) => {
            dispatch(ActionsWeb.DeleteMultipleOrdersRequest(body));
        },
        ListEcommerceConfigRequest : (body) => {
            dispatch(ActionsWeb.ListEcommerceConfigRequest(body));
        },
        UpdateStatusOrdersRequest : (body) => {
            dispatch(ActionsWeb.UpdateStatusOrdersRequest(body));
        },
        ListEcommerceTypeRequest: (body) => {
            dispatch(ActionsWeb.ListEcommerceTypeRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Index);