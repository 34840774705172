import React from 'react'
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import * as Colors from '../../constants/Colors';
import { linkWeb } from '../../constants/LinkApi';
import * as LINK from "./../../constants/Links";
import
{
  CaretLeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
  ShopOutlined,
  UserOutlined,
  PieChartOutlined,
  DesktopOutlined, LogoutOutlined,
  MailOutlined,
  AppstoreAddOutlined, SmileOutlined,
  LoadingOutlined, RetweetOutlined
} from '@ant-design/icons';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { getCookie } from '../../service';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutRequest, SetUserRequest } from '../../actions/login';
import { SettingsShowByCodeRequest } from '../../actions/indexWeb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import {  isloader, toggleColapNav } from '../../actions/indexApp';
import * as Types from "./../../constants/TypesactionApp";

export const Header = () => {

  const [settings, setsettings] = useState(null);
  const dispatch = useDispatch()
  const { Web, appReducer } = useSelector((state) => state);

  const [user, setuser] = useState(null);
  const history = useHistory();
  const logout_ = () =>
  {
  
    dispatch(isloader(true))
    dispatch(LogoutRequest({ user_name: user.user_name }));

  }
  useEffect(() =>
  {
    dispatch(isloader(false))
    if (Web.Logout.code === 200) {

      window.location = LINK.LOGIN;
    }

    return () =>
    {

    };
  }, [Web.Logout]);
  useEffect(() => {
    dispatch(SettingsShowByCodeRequest({ code: "ADMIN" }));
    let user = getCookie("user");
    if (user) {
      user = JSON.parse(user);
      dispatch(SetUserRequest(user));
      setuser(user);
    }
    let settings = localStorage.getItem("settings");
    if (settings && typeof settings !== "undefined") {
      settings = JSON.parse(settings);
      setsettings(settings);
      
    }
    return () => {
      
    };
  }, []);
  const toggleCollapsed=()=>{
    
    dispatch({
      type: Types.ACTIVE,
      result: !appReducer.active,
    });
  }
  useEffect(() => {
    
    return () => {
      
    };
  }, [appReducer.isSyncData]);
 
  
 const resfreshProductsData = () =>
  {
    localStorage.setItem("productsData", "")
    window.location.reload();

  }
  return (
    <div className=" br-page max-height-60 bg_main boder-main-b">
      <div className="header-chat">
        <div className="group-page">
          <div className="page-chat">
            <div className="page-live logo_top" >
              <img src={settings?.logo ? `${LINK.myHostimgServerImg}/${settings?.logo}` : `${LINK.myHostimg}/logorim.png`} alt="" style={{ borderColor: Colors.pop2[0] }} />
            </div>
            <div className="page-live page-add btn_bar row">
              <button
                type="button"
                onClick={toggleCollapsed}
                style={{
                  width: "fit-content"
                }}
                className="reset_btn btn_top_action"
              >
                {appReducer.active ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              </button>
              <button
                type="button"
                onClick={() =>
                {
                  history.goBack()
                }}
                style={{
                  width: "fit-content"
                }}
                className="reset_btn btn_top_action"
              >
                <CaretLeftOutlined />
              </button>
            </div>


          </div>
        </div>
        <div className="profile-noti">

          <ul>

            {appReducer.isSyncData && <li>

              <div>
                <Spin indicator={<LoadingOutlined
                  style={{
                    fontSize: 24,
                    color: Colors.white
                  }} spin />} />
              </div>
            </li>}
            <li>
              <div> <span className="icon cusor-pointer text-reset" style={{ color: Colors.colorGreen }} onClick={resfreshProductsData}><RetweetOutlined /></span></div>
            </li>
            <li>
              <div> <span className="icon text-reset" style={{ color: Colors.pop2[3] }}><ShopOutlined /></span><span className="text" style={{ color: Colors.pop2[0] }}>{user?.store?.name}</span></div>
            </li>
            <li>
              <div> <span className="icon text-reset" style={{ color: Colors.pop2[3] }}><UserOutlined /></span><span className="text" style={{ color: Colors.pop2[0] }}>{user?.name}</span></div>
            </li>
            <li>
              <div><button className="reset_btn text-reset" onClick={logout_}> <span className="icon text-reset" style={{ color: Colors.pop2[0] }}><LogoutOutlined style={{ color: Colors.colorDelete }} /></span></button></div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  )
}
