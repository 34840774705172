import React, { Component, createRef } from "react";
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";

import * as ActionsApp from "../../../../actions/indexApp";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import { Divider, Radio, Table, Pagination, Input, Button, Modal, Image, Tag, Switch as SwitchA, message, DatePicker, Tooltip, Form, TreeSelect, Select, Card, Row, Col, Popover, List, Dropdown, Drawer, Checkbox, Skeleton, Empty, } from 'antd';
import moment from 'moment';
import { arrColors } from "./../../../../constants/Colors";
import * as Colors from "../../../../constants/Colors";
import {
FolderViewOutlined,
DeleteOutlined, AppstoreOutlined,
EditOutlined, SearchOutlined, PlusCircleOutlined, UnorderedListOutlined, DiffOutlined,ExportOutlined

} from '@ant-design/icons';
import { formatMN1, CutString, SetProductsData, getParamsUrl, checkScreenSize, getCookie, convertToBoolean } from "../../../../service";
import { META } from "../../../../utils/constant";
import ProductsMobile from "./ProductsMobile";

import * as caseSocket from "../../../../constants/CaseSocket";

import { Runfirst } from "../../../../constants/Runfirst";

import TreeData from "./../../../../components/treeData/index";
import { PRO_ALL, PRO_WININVOICE } from "../../../../constants/ExportExel";
import { ExportButton } from "../../../../components/ExportButton/ExportButton";
import SyncMultipleProduct from "../../../../components/ecomerceProducts/SyncMultipleProduct";

const { RangePicker } = DatePicker;

const getDataDefault = ["ListTags", "ListCategory", "ListMenuHome", "ListEcommerceConfig", "ListEcommerceType"];
class Products extends Component {

    constructor(props) {
        super(props);
        this.formSearchRef = createRef(null)
        this.formSyncRef = createRef(null)
        this.state = {
            meta: {
                ...META
            },
            ListProducts: [],

            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.title.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',
                    sorter: (a, b) => a.slug - b.slug,

                },
                {
                    title: 'Giá',
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,

                },
                {
                    title: 'Giảm giá',
                    dataIndex: 'discount',
                    sorter: (a, b) => a.discount - b.discount,

                },
                {
                    title: 'Danh mục ',
                    dataIndex: 'category',
                    // render: (text, record, index) => (

                    //     <span>{text}</span>
                    // )

                },
                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            searchData:{},
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            data: [],
            StoreProducts: {},
            visible: false,
            confirmLoading: false,
            modalText: "",
            isloading: false,
            ListCategory: [],
            treeData: [],
            search: {},
            ListTags: [],
            isLoadingSearch: false,
            statusSearch: "",
            isShowList: false,
            ListEcommerceConfig: [],
            ListEcommerceType: [],
            idsCheck: [],
            selectedRows: [],
            selectedRowKeys: [],
            // itemsExport: [

                
            //     {
            //         key: '1',
            //         label: <span onClick={()=>this.exportExxel(PRO_WININVOICE)}>Xuất wininvoice</span>,
            //     },
            //     {
            //         key: '2',
            //         label: <span onClick={()=>this.exportExxel(PRO_ALL)}>Xuất tất cả</span>,
            //     },
            // ],
            itemsChose: [

                {
                    key: '1',
                    label: "Đồng bộ",
                    children: [
                        {
                            key: '1-1',
                            label: '3rd menu item',
                        },
                        {
                            key: '1-2',
                            label: '4th menu item',
                        },
                    ],
                },
                {
                    key: '2',
                    label: <span onClick={this.onchangeChoose}>Thêm vào menu</span>,
                },
                {
                    key: '3',
                    label: <span onClick={this.deletMutiple}>Xoá</span>,
                },
                {
                    key: '4',
                    label: <span onClick={this.choseAll}>Chọn tất cả</span>,
                },
            ],
            isVisibleDrawer: false,
            isVisibleDrawerCate: false,
            checkSync: [],
            isLoadingSync: false,
            isCheckEcom: [],
            menuHomeChoses: [],
            isSket: true,
            itemSyncMutiple:{},

        }

    }

    exportExxel=(type)=>{

    }
    SetisVisibleDrawer = (type) => {

        this.setState(state => {
            return {
                isVisibleDrawer: type
            }
        })
    }
    isVisibleDrawerCate = (type) => {

        this.setState(state => {
            return {
                isVisibleDrawerCate: type
            }
        })
    }
    SetisShowList = (type) => {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state => {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) => {
        this.setState(state => {
            return {
                isLoadingSearch: type
            }
        })
    }
    onChangetext = (e, type, group) => {

        let target = e.target;
        if (target) {
            let value = target.value;
            let name = target.name;


            this.setState(state => {
                return {
                    [group]: { ...state[group], [name]: value }
                }
            })
        }
        else {

            this.setState(state => {
                return {
                    [group]: { ...state[group], [type]: e }
                }
            })
        }

    }

    datacate = () => {
        let { ListCategory } = this.state;


        if (ListCategory.length <= 0) {
            return;
        }

        const result = { listfil: [], data: [] }
        this.renderTableTree(Object.values(ListCategory), result, false);


        this.setState(state => {
            return {
                treeData: Object.values(ListCategory),
                // meta: meta
            }
        })

    }
    renderTableTree = (arr, result, isChildren = false, num = 0, level = 0) => {
        arr.map((item, index) => {
            num++

            result.listfil.push({
                text: item.name,
                value: item.name,
            })

            item.value = item.id;
            item.title = item.name;

            if (item.children) {
                level++
                this.renderTableTree(Object.values(item.children), result, true, num, level);
                item.children = Object.values(item.children)
            }

        });

    }
    componentDidMount = () => {
        const isshowList = localStorage.getItem("isShowList")

        this.SetisShowList((1 * isshowList) === 0 ? false : true)

        if (!this.props.Web.ListProducts.data) {
            const urlParams = getParamsUrl();
            const filteredvalues = Object.fromEntries(
                Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
            );
            // created_at
            if (filteredvalues.fromdate) {
                filteredvalues.created_at = [];
                filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
            }
            if (filteredvalues.category_id) {
                filteredvalues.category_id = filteredvalues.category_id.split(",");
                filteredvalues.category_id = filteredvalues.category_id.map(str => parseInt(str));
            }
            if (filteredvalues.tags_id) {
                filteredvalues.tags_id = filteredvalues.tags_id.split(",");
                filteredvalues.tags_id = filteredvalues.tags_id.map(str => parseInt(str));
            }

            this.formSearchRef.current.setFieldsValue(filteredvalues)

            this.props.SearchProductsRequest({
                limit: this.state.meta.per_page,
                page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
            }, { data: filteredvalues });

        }
        else {
            this.setState(state => {
                return {
                    ListProducts: this.props.Web.ListProducts
                }
            }, () => { this.data() })

        }


        let productsData = localStorage.getItem("productsData");

        if (productsData) {

            productsData = JSON.parse(productsData);
        } else {
            productsData = {}
        }

        const runfirstdata = {};
        if (productsData) {

            for (let x in Runfirst) {

                if (getDataDefault.includes(x)) {
                    if (!productsData[x] || !productsData[x].data) {
                        const filterDataCate = {
                            data: {
                                name: "",
                                slug: "",
                                ischild: "",
                                isparent: "",
                                type: 0,
                                position: "",
                                created_at: ""
                            }
                        }
                        let dataF = [];
                        if (x === "ListCategory") {
                            dataF = filterDataCate;
                        }


                        Runfirst[x](this.props, this.state, dataF)


                    } else {
                        runfirstdata[x] = productsData[x].data
                    }
                }


            }
        }
        // console.log(runfirstdata);
        this.setState(state => {
            return { ...state, ...runfirstdata }
        })


    }
    static getDerivedStateFromProps(nextProps, prevState) {
        // console.log("StoreProductsMenuhome,",nextProps.Web);
        if (nextProps.Web && nextProps.Web.StoreProductsMenuHome && nextProps.Web.StoreProductsMenuHome !== prevState.StoreProductsMenuHome) {

            // console.log("StoreProductsMenuhome,",nextProps.Web.StoreProductsMenuHome);
            return {
                isLoadingSync: false,
                isloadingSyncList: false,
                StoreProductsMenuHome: nextProps.Web.StoreProductsMenuHome,

            };


        }
        if (nextProps.Web && nextProps.Web.syncProductsToTiktok !== prevState.syncProductsToTiktok) {


            return {
                syncProductsToTiktok: nextProps.Web.syncProductsToTiktok,
                isLoadingSync: false,
                isloadingSyncList: false,
                idsCheck: [],
                ListProducts: nextProps.Web.ListProducts,


            };


        }

        if (nextProps.appReducer && nextProps.appReducer.socket !== prevState.socket) {


            return {
                socket: nextProps.appReducer.socket,
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false

            };


        }

        // if (nextProps.Web.ListEcommerceConfig && nextProps.Web.ListEcommerceConfig.data !== prevState.ListEcommerceConfig) {

        //     return {
        //         ListEcommerceConfig: nextProps.Web.ListEcommerceConfig.data,

        //     };

        // }
        // if (nextProps.Web.ListEcommerceType && nextProps.Web.ListEcommerceType.data !== prevState.ListEcommerceType) {

        //     return {
        //         ListEcommerceType: nextProps.Web.ListEcommerceType.data,

        //     };

        // }
        if (nextProps.Web && nextProps.Web.ListProducts !== prevState.ListProducts) {


            return {
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false
            };


        }
        if (nextProps.Web.StoreProducts && nextProps.Web.StoreProducts !== prevState.StoreProducts) {


            return {
                StoreProducts: nextProps.Web.StoreProducts,

            };

        }

        if (Runfirst) {
            for (let x in Runfirst) {
                if (nextProps.Web[x] && nextProps.Web[x].data && nextProps.Web[x].data !== prevState[x]) {


                    return {
                        [x]: nextProps.Web[x].data,

                    };

                }

            }

        }

        return null;


    }
    SetDrawer = (item) => {
        const { ListEcommerceConfig, isCheckEcom } = this.state;
        
        
        const listConfig=[];
        ListEcommerceConfig.map((el, index) => {
            if (parseInt(item.id) === parseInt(el.ecommerce_type_id)) {
                listConfig.push(el)
              
            }


        });
        console.log(item,ListEcommerceConfig,isCheckEcom,listConfig);
        
        this.setState(state => {
            return {
                itemSyncMutiple: item,
                isVisibleDrawer: true,
                checkSync: listConfig

            }
        })
    }
    componentDidUpdate(prevProps, prevState) {
        if (this.state.syncProductsToTiktok !== prevState.syncProductsToTiktok) {

            this.modalInfo("Thông báo", "Đang xử lý dữ liệu! quá trình có thể mất nhiều thời gian");

            this.props.setIsync(true);

        }
        if (this.state.StoreProductsMenuHome !== prevState.StoreProductsMenuHome) {
            const { StoreProductsMenuHome } = this.state;
            try {
                if (StoreProductsMenuHome.code === 200) {
                    if (StoreProductsMenuHome.message && typeof StoreProductsMenuHome.message === "string") {
                        message.success(StoreProductsMenuHome.message)
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (StoreProductsMenuHome.message && typeof StoreProductsMenuHome.message === "string") {

                        message.error(StoreProductsMenuHome.message)
                    } else {
                        for (let x in StoreProductsMenuHome.message) {
                            let mess = StoreProductsMenuHome.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.props.ResetStoreProductsMenuhomeRequest();


        }
        if (this.state.socket !== prevState.socket) {
            // let { ListCategory } = this.state

            this.connectSocket();

        }
        if (this.state.ListEcommerceType !== prevState.ListEcommerceType) {
            let { ListEcommerceType } = this.state;

            let ListEType = ListEcommerceType.map((item, index) => {
                return {
                    key: item.id,
                    label: (<a href="#" onClick={() => {
                        this.SetDrawer(item)
                    }}>
                        {item.name}
                    </a>),
                }
            });
            const itemsChose = this.state.itemsChose;
            itemsChose[0] = {
                key: '1',
                label: "Đồng bộ",
                children: ListEType,
            }
            // let itemsChose = [{
            //     key: '1',
            //     label: "Đồng bộ",
            //     children: ListEType,
            // }, {
            //     key: '2',
            //     label: "Thêm vào menu",
            // },
            //     {
            //         key: '3',
            //         label: "Xóa",
            //     },]
            this.setState(state => {
                return {
                    itemsChose: itemsChose
                }
            })
        }
        // if (this.state.ListEcommerceConfig !== prevState.ListEcommerceConfig) {
        //     let { ListEcommerceConfig, itemsChose } = this.state;
        //     let listType = itemsChose[0].children;
        //     listType.map((item, index) => {
        //         item.children=[];
        //         ListEcommerceConfig.map((el, i) => {
        //             if (el.ecommerce_type_id === item.key){
        //                 item.children.push({
        //                     key: el.id,
        //                     label: el.name,
        //                 })
        //             }
        //         });

        //     });
        //     itemsChose[0].children = listType;
        //     this.setState(state =>
        //     {
        //         return {
        //             itemsChose: itemsChose
        //         }
        //     })
        // }
        if (this.state.ListProducts !== prevState.ListProducts) {
            let { ListProducts } = this.state;

            if (ListProducts.code !== 200 && ListProducts.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
            }

            this.data()
        }

        if (this.state.ListCategory !== prevState.ListCategory) {
            let { ListCategory } = this.state

            // if (ListCategory) {
            //     this.datacate()
            // }

        }


        if (this.state.StoreProducts !== prevState.StoreProducts) {
            let { StoreProducts, isDuplicate } = this.state;

            try {
                if (StoreProducts.code === 200) {
                    if (StoreProducts.message && typeof StoreProducts.message === "string") {
                        message.success(StoreProducts.message)
                        this.setState(state => {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchProductsRequest({
                        limit: this.state.meta.per_page,
                        page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreProducts.message && typeof StoreProducts.message === "string") {

                        message.error(StoreProducts.message)
                    } else {
                        for (let x in StoreProducts.message) {
                            let mess = StoreProducts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false)
            this.setVisible(false)
            this.setState(state => {
                return {
                    StoreProducts: "",
                    isDuplicate: false
                }
            })
            this.props.ResetStoreProducts();



        }
    }
    componentWillUnmount() {
        this.props.resetProductsRequest();
    }
    connectSocket = () => {

        // const newSocket = new WebSocket(linkSocket);

        // newSocket.addEventListener('open', () =>
        // {
        //     console.log('Connected to WebSocket server');
        // });
        if (this.state.socket) {

            this.state.socket.addEventListener('message', (event) => {

                let json = JSON.parse(event.data);
                console.log('====================================');
                console.log("connectSocketconnectSocketconnectSocketjsonjson", json);
                console.log('====================================');
                switch (json.type) {
                    case caseSocket.PUSH_SYNC_DATA_PR_TIKTOK:
                        let user = getCookie("user");
                        user = JSON.parse(user);

                        if (user.id === json.user_id) {
                            try {
                                // let syncProducts = JSON.parse(json.data)
                                // this.processSyncPrData(syncProducts);
                                this.props.setIsync(false);
                                // this.props.setNotification({ show: true, title: "Thông báo", text: "Đã xư lý xong dữ liệu tiktok" });
                                // console.log('====================================');
                                // console.log("json.msgjson.msg", syncProducts);
                                // console.log('====================================');
                            } catch (error) {

                                console.error("errorerror", error);
                            }

                        }
                        break;

                    default:
                        break;
                }

            });
        }


    }
    SetisLoading = (type) => {
        this.setState(state => {
            return {
                isloading: type
            }
        })
    }
    modalInfo = (text, content) => {
        Modal.info({
            title: text,
            content: content,
            onOk() { },
            onCancel() { },
        });
    };
    setColumn_ = (filters) => {
        this.setState(state => {
            return {
                columns: [
                    {
                        title: 'STT',
                        dataIndex: 'key',
                        width: "5%",
                        align: 'center',

                    },
                    {
                        title: 'Code',
                        dataIndex: 'code',
                        // filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.code,
                        onFilter: (value, record) => {

                            return record.code.startsWith(value)

                        },
                        sorter: (a, b) => a.code - b.code,
                    },
                    {
                        title: 'Tiêu đề',
                        dataIndex: 'title',
                        // filterMode: 'tree',
                        filterSearch: true,
                        filters: filters.title,
                        onFilter: (value, record) => {

                            return record.title.startsWith(value)

                        },
                        sorter: (a, b) => a.title - b.title,
                        render: (text, record, index) => {
                            return CutString(text, 20, false)
                        }
                    },
                    {
                        title: 'thumbnail',
                        dataIndex: 'thumbnail',
                        render: (text, record, index) => {

                            return text[0] ? <Image
                                width={50}
                                height={50}
                                style={{ objectFit: "contain" }}
                                src={LINK.myHostimgServerImg + "/" + text[0]}
                            />
                                // <img src={LINK.myHostimgServerImg + "/" + text[0]}
                                //     style={{ width: "80px", height: "80px", objectFit: "contain" }} alt="" />
                                : ""
                        }
                    },
                    {
                        title: 'Slug',
                        dataIndex: 'slug',
                        sorter: (a, b) => a.slug - b.slug,
                        filterSearch: true,
                        filters: filters.slug,
                        onFilter: (value, record) => {

                            return record.slug === value

                        },
                        render: (text, record, index) => {
                            return CutString(text, 20, false)
                        }
                    },
                    {
                        title: 'Giá',
                        dataIndex: 'price',
                        sorter: (a, b) => a.price - b.price,
                        filterSearch: true,
                        filters: filters.price,
                        onFilter: (value, record) => {

                            return record.price === value

                        },
                        render: (text, record, index) => {

                            return <span>{formatMN1(text)}</span>
                        }

                    },
                    {
                        title: 'Giảm giá',
                        dataIndex: 'discount',
                        sorter: (a, b) => a.discount - b.discount,
                        render: (text, record, index) => {

                            return <span>{formatMN1(text)}</span>
                        },
                        filterSearch: true,
                        filters: filters.discount,
                        onFilter: (value, record) => {

                            return record.discount === value

                        },
                    },
                    {
                        title: 'Danh mục ',
                        dataIndex: 'category_id',
                        render: (text, record, index) => {


                            return Object.values(record.category_id).map((item, index) =>
                            (

                                <Tag key={index} color={arrColors[index]} >{CutString(item.name, 5, false)}</Tag>
                            ))

                        }


                    },
                    // {
                    //     title: 'Menu Web',
                    //     dataIndex: 'menu_home_id',
                    //     render: (text, record, index) => 
                    //     {


                    //         return Object.values(record.menu_home_id).map((item, index) =>
                    //         (

                    //             <Tag key={index} color={arrColors[index]} >{CutString(item.name, 5, false)}</Tag>
                    //         ))

                    //     }


                    // },
                    {
                        title: 'Tags ',
                        dataIndex: 'tags_id',
                        render: (text, record, index) => {
                            return Object.values(record.tags_id).map((item, index) =>
                            (

                                <Tag key={index} color={arrColors[index]}>{CutString(item.name, 5, false)}</Tag>
                            ))

                        },


                    },
                    {
                        title: 'Hiển thị',
                        dataIndex: 'hidden',
                        filters: filters.hidden,
                        onFilter: (value, record) => {

                            return record.hidden === value

                        },

                        sorter: (a, b) => a.hidden > b.hidden,
                        render: (text, record, index) => {

                            return <SwitchA defaultChecked={!convertToBoolean(record.hidden)} onChange={(e) => { this.updateRecord(e, record) }}
                                loading={this.state.isloading} />
                        },

                    },
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_at',
                        filterSearch: true,
                        filters: filters.created_at,
                        onFilter: (value, record) => {
                            return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                        },
                        sorter: (a, b) => a.created_at - b.created_at,
                        render: (text, record, index) => {
                            return moment(text).format('DD/MM/YYYY h:m:s');;
                        }

                    },

                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "10%",
                        render: (text, record, index) => (
                            <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                                <span className="">
                                    <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(record)} >
                                        <EditOutlined style={{ color: Colors.colorEdit }} /> </button>
                                    <button className="reset_btn btn_delete btn__" onClick={() => { this.deleteModal(record) }}><DeleteOutlined style={{ color: Colors.colorDelete }} /></button>
                                    <button className="reset_btn btn_view btn__" onClick={() => { this.duplicateItem(record) }}>
                                        <DiffOutlined style={{ color: Colors.colorView }} /></button>

                                        
                                      
                                </span>
                            </div>
                        ),
                    },
                ]
            }
        })
    }

    updateRecord = (e, record) => {
        const tmp = { ...record, hidden: !convertToBoolean(e) }
        let catetmp = [];
        // let tagstmp = Object.keys(record.tags_id);
        let tagstmp = [];

        Object.values(record.tags_id).map((item, index) => {
            item["value"] = item.id;
            item["label"] = item.name;
            tagstmp.push(1 * item.id)
        });
        Object.values(record.category_id).map((item, index) => {
            item["value"] = item.id;
            item["label"] = item.name;
            catetmp.push(1 * item.id)
        });
        tmp.category_id = catetmp;
        tmp.tags_id = tagstmp;

        this.SetisLoading(true)
        this.props.HiddenProductsRequest(tmp, tmp.id);
        this.setState(state => {
            return {
                isNewList: true
            }
        })
    }
    data = () => {
        try {

            let { ListProducts } = this.state;

            if (ListProducts.length <= 0) {
                return;
            }

            let data = [];
            let listfil = {};
            let meta = {
                ...this.state.meta,
                current_page: ListProducts.current_page,
                first_page_url: ListProducts.first_page_url,
                from: ListProducts.from,
                last_page: ListProducts.last_page,
                last_page_url: ListProducts.last_page_url,
                links: ListProducts.links,
                next_page_url: ListProducts.next_page_url,
                path: ListProducts.path,
                per_page: 1 * ListProducts.per_page,
                prev_page_url: ListProducts.prev_page_url,
                to: ListProducts.to,
                total: ListProducts.total,
            };

            let filtilte = [];
            let filcode = [];
            let filslug = [];
            let filprice = [];
            let fildiscount = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            (ListProducts.data || []).map((item, index) => {
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filcode = [...filcode, {
                    text: item.code,
                    value: item.code,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                fildiscount = [...fildiscount, {
                    text: item.discount,
                    value: item.discount,
                }]
                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = item.id
                data.push(item)

            });
            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]
            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, discount: fildiscount, code: filcode }
            this.setColumn_(listfil);

            this.setState(state => {
                return {
                    data: data,
                    isSket: false,
                    meta: meta
                }
            }, () => {
                this.setState(state => {
                    return {
                        isNewList: false,
                    }
                });

            })
        } catch (error) {
            console.error(error);
        }
    }

    editRows = (record) => {
        let catetmp = [];
        // let tagstmp = Object.keys(record.tags_id);
        let tagstmp = [];
        const state = { ...record };
        Object.values(record.tags_id).map((item, index) => {
            item["value"] = item.id;
            item["label"] = item.name;
            tagstmp.push(1 * item.id)
        });
        Object.values(record.category_id).map((item, index) => {
            item["value"] = item.id;
            item["label"] = item.name;
            catetmp.push(1 * item.id)
        });
        state.category_id = catetmp;
        state.tags_id = tagstmp;


        this.props.history.push({
            pathname: LINK.WEB_PRODUCTS_EDIT + "/" + record.id,
            state: state
        });


    }
    duplicateItem = (record) => {
        this.SetisLoading(true)
        this.props.duplicateProductRequest({ id: record.id })
        this.setState(state => {
            return {
                isNewList: true,
                isDuplicate: true
            }
        })
    }
    onSearch = (values) => {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }

        this.props.SearchProductsRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_PRODUCTS}?${searchParams}`);
        this.setState(state => {
            return {
                isNewList: true,
                searchData:filteredvalues
            }
        })


    }
    onResetFrom = () => {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state => {
            return {
                isNewList: false
            }
        })
    }
    deleteModal = (record) => {
        this.setState(state => {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () => {
            this.setModalText(<span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>)
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    setModalText = (type) => {
        this.setState(state => {
            return {
                modalText: type
            }
        })
    }
    setVisible = (type) => {
        this.setState(state => {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) => {
        this.setState(state => {
            return {
                confirmLoading: type
            }
        })
    }
    handleOkDelete = () => {
        const { record } = this.state
        this.setModalText('Đang xóa');
        // let catetmp = [];
        // // let tagstmp = Object.keys(record.tags_id);
        // let tagstmp = [];

        // Object.values(record.tags_id).map((item, index) =>
        // {
        //     item["value"] = item.id;
        //     item["label"] = item.name;
        //     tagstmp.push(1 * item.id)
        // });
        // Object.values(record.category_id).map((item, index) =>
        // {
        //     item["value"] = item.id;
        //     item["label"] = item.name;
        //     catetmp.push(1 * item.id)
        // });
        // record.category_id = catetmp;
        // record.tags_id = tagstmp;
        // console.log("this.state.idsCheckthis.state.idsCheck",this.state.selectedRowKeys);
        // return;
        if (this.state.isdeleteMutiple) {
            this.props.DeleteMutpleProductsRequest({ ids: this.state.selectedRowKeys })
        } else {
            this.props.DeleteProductsRequest(record, record.id)
        }
        this.setConfirmLoading(true);

        this.setState(state => {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () => {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    OnPagination = (page, pageSize) => {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        this.props.SearchProductsRequest({
            limit: pageSize,
            page: page, column: this.state.meta.column, sort: this.state.meta.sort
        }, { data: filteredvalues });
        // this.props.ListProductsRequest({
        //     limit: pageSize,
        //     page: page, column: this.state.meta.column, sort: this.state.meta.sort
        // });
    }

    choseAll = () => {
        const { ListProducts } = this.state;
        const dataProducts = ListProducts.data;
        const dataChecks = [];
        dataProducts.filter(item => {
            dataChecks.push(item.id)
        })
        this.setState(state => {
            return {
                idsCheck: dataChecks,

            }
        })

    }
    onChangeCheck = (item) => {
        const { idsCheck, ListProducts } = this.state;
        let idsCheck_ = [...idsCheck]
        if (!idsCheck_.includes(item.id)) {
            idsCheck_ = [...idsCheck, item.id]
        } else {
            idsCheck_ = idsCheck.filter(el => {
                return el !== item.id
            })
        }
        const dataProducts = ListProducts.data;
        const dataChecks = dataProducts.filter(item => {
            return idsCheck_.includes(item.id)
        })
        const isCheckEcom = [];
        for (let x in dataChecks) {
            const item = dataChecks[x];
            const ecommerce = item.ecommerce;
            for (let y in ecommerce) {
                const item_ = ecommerce[y];
                if (!isCheckEcom.includes(item_.code)) {
                    isCheckEcom.push(item_.code)
                }
            }
        }
        // console.log('====================================');
        // console.log(idsCheck_, dataChecks, isCheckEcom);
        // console.log('====================================');
        this.setState(state => {
            return {
                idsCheck: idsCheck_,
                isCheckEcom: isCheckEcom
            }
        })
    }


    
    deletMutiple = () => {
        this.setState(state => {
            return {
                visible: true,
                isdeleteMutiple: true,
                modalText: <span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>
            }
        })
    }
    onchangeChoose = (e) => {

        this.isVisibleDrawerCate(true)
        // StoreProductsMenuhomeRequest
    }
    getMenuChose = (menus) => {
        console.log(menus);
        this.setState(state => {
            return {
                menuHomeChoses: menus
            }
        })
    }

    submitSaveMenuHome = () => {

        console.log("products", this.state.idsCheck);
        console.log("menuHomeChoses", this.state.menuHomeChoses);
        if (this.state.idsCheck.length <= 0 || this.state.menuHomeChoses.length <= 0) {
            message.warning("Vui lòng chọn danh mục và sản phẩm!")
            return;
        }
        const data = {
            id_products: this.state.idsCheck,
            id_menuhome: this.state.menuHomeChoses
        }
        this.setState(state => {
            return {
                isLoadingSync: true
            }
        })

        this.props.StoreProductsMenuhomeRequest(data);
    }
    render() {
        let { routers } = this.props;

        return (
            <div className="main_content pro_" style={{ overflow: "unset" }}>
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                <Drawer
                    title={"Chọn danh mục"} placement="right"
                    onClose={() => this.isVisibleDrawerCate(false)}
                    visible={this.state.isVisibleDrawerCate}
                    size={"small"}
                >
                    <div className="box-footer-button">
                        <TreeData data={this.state.ListMenuHome}
                            isReset={this.state.isVisibleDrawerCate}
                            getMenuChose={this.getMenuChose}

                        />

                        <div>
                            <Button type="primary" htmlType="button" loading={this.state.isLoadingSync}
                                onClick={this.submitSaveMenuHome}
                            >
                                Lưu
                            </Button>
                        </div>
                    </div>



                </Drawer>
                <SyncMultipleProduct itemSyncMutiple={this.state.itemSyncMutiple} visible={this.state.isVisibleDrawer} SetisVisible={this.SetisVisibleDrawer} checkSync={this.state.checkSync} selectedRowKeys={this.state.selectedRowKeys}  selectedRows={this.state.selectedRows} />
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", width: "100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Sản phẩm</span>
                        {this.state.ListEcommerceType &&  this.state.ListEcommerceType.map((value) => {

                            return   <Popover placement="bottom" content={
                                <List

                                    itemLayout="horizontal"

                                    dataSource={this.state.ListEcommerceConfig?.filter((item) => {return item.ecommerce_type_id==value.id})}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Link to={LINK[value.link] + "/" + item?.id_}>{item?.name}</Link>

                                            </div>


                                        </List.Item>
                                    )}
                                />

                            } trigger="click">
                                <Tag icon={<div dangerouslySetInnerHTML={{ __html: value.icon }} />} color={value.color} className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active d-flex" : "tabs_ecommerce  d-flex"} >{value.name}</Tag>
                            </Popover>
                        })}
                           


                            {/* <Popover placement="bottom" content={
                                <List

                                    itemLayout="horizontal"

                                    dataSource={this.state.ListEcommerceConfig?.filter((value, index) => {
                                        return value.ecommerce_type_id=2;
                                    })}
                                    renderItem={(item) => (
                                        <List.Item>
                                            <div style={{ display: "flex", flexDirection: "column" }}>
                                                <Link to={LINK.WEB_PRODUCTS_TKTOK + "/" + item?.code}>{item?.name}</Link>

                                            </div>


                                        </List.Item>
                                    )}
                                />

                            } trigger="click">
                                <Tag icon={<i class="fab fa-tiktok"></i>} color="purple" className={this.state.tabOrder === "tiktok" ? "tabs_ecommerce active" : "tabs_ecommerce"} >Lazada</Tag>
                            </Popover> */}
                        </div>

                        <Link to={LINK.WEB_PRODUCTS_EDIT + "/add"}>
                            <Button size="small" type="primary"><PlusCircleOutlined /></Button>
                        </Link>
                    </div>
                    } bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                            // maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px",

                        }}
                    >
                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <Form.Item style={{ margin: 0, width: "100%" }}>
                                        <Input.Group compact>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="code" />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_  col-lg-2 col-md-3 col-sm-6">

                                                    <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="Tiêu đề" />
                                                    </Form.Item>
                                                </div>

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="category_id" noStyle style={{ margin: 0 }}>
                                                        <TreeSelect
                                                            showSearch
                                                            style={{ width: "100%" }}
                                                            treeData={this.state.ListCategory}
                                                            dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                                            placeholder="Danh mục"
                                                            multiple
                                                            treeDefaultExpandAll

                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="tags_id" noStyle style={{ margin: 0 }}>
                                                        <Select
                                                            mode="multiple"
                                                            style={{ width: "100%" }}
                                                            maxTagCount='responsive'
                                                            placeholder="Tags"
                                                            options={(this.state.ListTags || []).map((d) => ({
                                                                value: d.id,
                                                                label: d.name,
                                                            }))}
                                                        />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker style={{ width: "100%", }} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                            {(this.state.idsCheck.length > 0 || this.state.selectedRowKeys.length > 0) &&

                                                                <Dropdown
                                                                    menu={{
                                                                        items: this.state.itemsChose
                                                                    }}
                                                                    placement="bottom"
                                                                    arrow
                                                                    trigger={"hover"}


                                                                >
                                                                    <Button type={"primary"} htmlType="button">Lựa chọn</Button>
                                                                </Dropdown>
                                                            }

                                                              
                                                            <ExportButton searchData={this.state.searchData} meta={this.state.meta}/>



                                                        </div>
                                                    </Form.Item>

                                                </div>
                                            </div>






                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>


                        </div>
                        {this.state.isSket && <Skeleton active />}
                        {!this.state.isSket && this.state.data.length <= 0 && <Empty />}
                        {this.state.data.length > 0 && <>    {(checkScreenSize().width < 600 || !this.state.isShowList) && <ProductsMobile lists={this.state.data} loading={this.state.loading}
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            onChangeCheck={this.onChangeCheck}
                            idsCheck={this.state.idsCheck}
                        />}
                            {checkScreenSize().width >= 600 && this.state.isShowList && <> <Table
                                rowClassName={(record, index) => {
                                    return "__row_";
                                }}
                                rowSelection={{
                                    onChange: (selectedRowKeys, selectedRows) => {
                                        this.setState(state => {
                                            return {
                                                selectedRowKeys: selectedRowKeys,
                                                selectedRows: selectedRows
                                            }
                                        })
                                        console.log(`selectedRowKeys: ${selectedRowKeys}`, 'selectedRows: ', selectedRows);
                                    },
                                    getCheckboxProps: (record) => ({
                                        // disabled: record.name === 'Disabled User',
                                        // // Column configuration not to be checked
                                        name: record.name,
                                    }),
                                }}
                                columns={this.state.columns}
                                dataSource={this.state.data}
                                pagination={false}
                                // scroll={{
                                //     y: "90vh",
                                // }}
                                style={{ paddingBottom: "30px" }}
                            />
                                <div className="pagination_">
                                    <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                                </div></>}
                        </>
                        }

                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) => {
    return {
        appReducer: state.appReducer,
        Web: state.Web
    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {
        InfoPackageActiveRequest: (body) => {
            dispatch(ActionsWeb.InfoPackageActiveRequest(body));
        },
        ListCategoryRequest: (body) => {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
        // ////////////////

        ListInfoTransportRequest: (body) => {
            dispatch(ActionsWeb.ListInfoTransportRequest(body));
        },

        // ////////////////

        ListTagsRequest: (body) => {
            dispatch(ActionsWeb.ListTagsRequest(body));
        },
        ////////////////////////

        ListStoreRequest: (body) => {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },



        ////////////////////////

        ListClassifysRequest: (body) => {
            dispatch(ActionsWeb.ListClassifysRequest(body));
        },
        /////////////////////////


        // //////////////

        ListInfoPackageRequest: (body) => {
            dispatch(ActionsWeb.ListInfoPackageRequest(body));
        },

        // ///////////////////////
        ListAttributeProductsRequest: (body) => {
            dispatch(ActionsWeb.ListAttributeProductsRequest(body));
        },
        ListProductsRequest: (data) => {
            dispatch(ActionsWeb.ListProductsRequest(data));
        },
        SearchProductsRequest: (body, data) => {
            dispatch(ActionsWeb.SearchProductsRequest(body, data));
        },
        StoreProductsRequest: (data, id) => {
            dispatch(ActionsWeb.StoreProductsRequest(data, id));
        },
        duplicateProductRequest: (data, id) => {
            dispatch(ActionsWeb.duplicateProductRequest(data, id));
        },
        DeleteProductsRequest: (data, id) => {
            dispatch(ActionsWeb.DeleteProductsRequest(data, id));
        },
        HiddenProductsRequest: (data, id) => {
            dispatch(ActionsWeb.HiddenProductsRequest(data, id));
        },
        ResetStoreProducts: () => {
            dispatch(ActionsWeb.ResetStoreProducts());
        },
        setIsync: (body) => {
            dispatch(ActionsApp.setIsync(body));
        },
        SyncProductsToTiktokRequest: (data) => {
            dispatch(ActionsWeb.SyncProductsToTiktokRequest(data));
        },
        SearchCategoryRequest: (body, data) => {
            dispatch(ActionsWeb.SearchCategoryRequest(body, data));
        },
        SearchMenuHomeRequest: (body, data) => {
            dispatch(ActionsWeb.SearchMenuHomeRequest(body, data));
        },
        ListEcommerceConfigRequest: (body) => {
            dispatch(ActionsWeb.ListEcommerceConfigRequest(body));
        },
        ListEcommerceTypeRequest: (body) => {
            dispatch(ActionsWeb.ListEcommerceTypeRequest(body));
        },
        StoreProductsMenuhomeRequest: (body) => {
            dispatch(ActionsWeb.StoreProductsMenuhomeRequest(body));
        },
        ResetStoreProductsMenuhomeRequest: () => {
            dispatch(ActionsWeb.ResetStoreProductsMenuhomeRequest());
        },
        DeleteMutpleProductsRequest: (body) => {
            dispatch(ActionsWeb.DeleteMutpleProductsRequest(body));
        },
        resetProductsRequest: (body) => {
            dispatch(ActionsWeb.resetProductsRequest(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);