import React, { useRef } from 'react'
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import * as Colors from '../../constants/Colors';
import { linkWeb } from '../../constants/LinkApi';
import * as LINK from "../../constants/Links";
import
{
  CaretLeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
  ShopOutlined,
  UserOutlined,
  PieChartOutlined,
  DesktopOutlined, LogoutOutlined,
  MailOutlined,ExportOutlined,
  AppstoreAddOutlined, SmileOutlined,
  LoadingOutlined, RetweetOutlined,SearchOutlined
} from '@ant-design/icons';
import { Menu, Spin,Input,Button ,Dropdown} from 'antd';
import { useEffect } from 'react';
import { checkScreenSize, getCookie } from '../../service';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ListMenusRequest } from '../../actions/indexWeb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { v4 as uuidv4 } from 'uuid';
import { Icons } from "../../constants/ListIcons";
import { META } from "../../utils/constant";
import { OverlayLoading } from "../overlayLoading";
import * as ActionsWeb from "./../../actions/indexWeb";
import { PRO_ALL, PRO_WININVOICE } from '../../constants/ExportExel';

const { Search } = Input;
export const ExportButton = ({ searchData,meta }) =>
{

  const [itemsExport, setitemsExport] = useState( [

                
    {
        key: '1',
        label: <span onClick={()=>exportExcel(PRO_WININVOICE)}>Xuất wininvoice</span>,
    },
    {
        key: '2',
        label: <span onClick={()=>exportExcel(PRO_ALL)}>Xuất tất cả</span>,
    },
]);
  const dispatch = useDispatch()

  useEffect(() =>
  {
    
    return () =>
    {

    };
  }, []);
  const downloadFile = (blob, filename) => {
    const url = window.URL.createObjectURL(new Blob([blob]));
    const link = document.createElement('a');
    link.href = url;
    link.setAttribute('download', filename); // or any other extension
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };
  const exportExcel=(key)=>{
      const ex=async ()=>{
        try {
          const body = meta; // Thay 'someCode' bằng giá trị thực tế
          body.key=key
          const data = searchData;
          data.isdetail=1;
        
          
          // Gọi hàm ExportProductsRequest với await
          const response = await ActionsWeb.ExportProductsRequest({
            limit: body.per_page,
            page: body.current_page, column: "products.id", sort: body.sort,key:body.key
        },{ data: data });
         
          
          // Xử lý kết quả trả về nếu cần
          const { blob, filename } = response;
         downloadFile(blob, filename);
        } catch (error) {
          // Xử lý lỗi nếu có
          console.error('Error:', error);
        }
      }

      ex();
  }
  return (
    <Dropdown
        menu={{
            items: itemsExport
        }}
        placement="bottom"
        arrow
        trigger={"hover"}


    >
      <Button style={{ backgroundColor: Colors.colorInfo, color: Colors.white }} htmlType="button" onClick={()=>{}}>
      <ExportOutlined style={{ color: Colors.white }} /></Button>
    </Dropdown>
  )
}
