import React, { useEffect, useRef, useState } from 'react';

import * as LINK from "../../../../constants/Links";
import './style.css';
import { Divider, Radio, Table, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import
{
    SearchOutlined,
    CaretDownOutlined, FileImageOutlined,  PlusOutlined,
    MinusOutlined
} from '@ant-design/icons';

import { useDispatch, useSelector } from 'react-redux';
import { META } from "../../../../utils/constant";
import * as ActionsWeb from "../../../../actions/indexWeb";
import Upload from "./Upload";
const { TextArea } = Input;
const Index = (props) =>
{
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [imgesInfo, setimgesInfo] = useState({});
    const [imgesInfoRemove, setimgesInfoRemove] = useState([]);
    const [isloading, setIsloading] = useState(false);
    const [isupload, setisupload] = useState(0);
    const [values, setValues] = useState(null);
    const [record, setRecord] = useState({ deleted: 0, type: 2, active: 1 });
    const [required, setrequired] = useState({
        avata: false

    });
    const [typeResUpload, setypeResUpload] = useState(null);
    const [UploadFileLa, setUploadFileLa] = useState("");
    const [phones, setPhones] = useState(["123456789"]);
    const [emails, setEmails] = useState(["exmaple@gmail.com"]);
    const [address, setAddress] = useState(["123 abc"]);
    const [formdata_, setFormdata] = useState(false);
    const formRef = useRef(null)

    const onFinishForm = (values) =>
    {
        // console.log('====================================');
        // console.log(record);
        // console.log('====================================');

        record.imgesInfoRemove = imgesInfoRemove.filter(item =>
        {
            item.folder = '';
            return !item.originFileObj
        });

        if (imgesInfo.file) {
            for (let x in imgesInfo.file) {
                // const element = imgesInfo.file[x];

                values[x] = record[x] ? record[x] : values[x]
            }
        }
        if (imgesInfo.files) {
            for (let x in imgesInfo.files) {
                // const element = imgesInfo.file[x];
                values[x] = record[x] ? record[x] : values[x]
            }
        }
        if (imgesInfo.video) {
            for (let x in imgesInfo.video) {
                // const element = imgesInfo.file[x];
                values[x] = record[x] ? record[x] : values[x]
            }
        }
        const phone_ = [];
        const email_ = [];
        const address_ = []
        for (let x in values) {
            if (x.includes("phone")) {
                phone_.push(values[x])
                delete values[x];
            }
            if (x.includes("email")) {
                email_.push(values[x])
                delete values[x];
            }
            if (x.includes("address")) {
                address_.push(values[x])
                delete values[x];
            }
        }
        values.phone = phone_;
        values.email = email_;
        values.address = address_;
        const record_ = { ...record, code: "ADMIN", title: "Cài đặt admin", type: 2, active: 1, deleted: 0, data: JSON.stringify(values) }

        // return;
        setIsloading(true)
        dispatch(ActionsWeb.StoreSettingsRequest(record_, record_.id ? record_.id : ""));

    }
    useEffect(() =>
    {


        dispatch(ActionsWeb.SettingsShowByCodeRequest({ code: "ADMIN" }))
        return () =>
        {

        };
    }, []);
    useEffect(() =>
    {
        if (Web.StoreSettings?.code) {

            try {
                if (Web.StoreSettings.code === 200) {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {
                        message.success(Web.StoreSettings.message)
                    } else {

                        message.error("Có lỗi!")
                    }


                }
                else {
                    if (Web.StoreSettings.message && typeof Web.StoreSettings.message === "string") {

                        message.error(Web.StoreSettings.message)
                    } else {
                        for (let x in Web.StoreSettings.message) {
                            let mess = Web.StoreSettings.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }

                    }

                }

            } catch (error) {
                message.error("Có lỗi!")

            }
            setIsloading(false)
            dispatch(ActionsWeb.ResetStoreSettings());
        }


        return () =>
        {

        };
    }, [Web.StoreSettings]);

    useEffect(() =>
        {
            if(phones && phones.length>0){
               for (let i = 0; i < phones.length; i++) {
                    const element = phones[i];
                    console.log(element);
                    formRef.current.setFieldsValue({["phone"+(i+1)]:element})
               }
            }
            if(emails && emails.length>0){
                for (let i = 0; i < emails.length; i++) {
                    const element = emails[i];
                    console.log(element);
                    formRef.current.setFieldsValue({["email"+(i+1)]:element})
               }
            }
            if(address && address.length>0){
                for (let i = 0; i < address.length; i++) {
                    const element = address[i];
                    console.log(element);
                    formRef.current.setFieldsValue({["address"+(i+1)]:element})
               }
            }
            // setrender(!render)
           console.log(phones,emails,address);
            return () =>
            {
    
            };
        }, [phones,emails,address]);
    useEffect(() =>
    {

        if (Web.SettingsShowByCode.data) {
            if (Web.SettingsShowByCode.data.code === "ADMIN") {
                setRecord(Web.SettingsShowByCode.data)
                if (Web.SettingsShowByCode.data.data) {
                const formdata = JSON.parse(Web.SettingsShowByCode.data.data)
              console.log(formdata);
                setPhones(formdata.phone)
                setEmails(formdata.email)
                setAddress(formdata.address)
                const imgesInfo_ = {
                    file: {
                        icon: [{
                            uid: -1,
                            name: formdata.icon[0],
                            status: 'done',
                            url: LINK.myHostimgServerImg + "/" + formdata.icon[0],
                        }],
                        logo: [{
                            uid: -2,
                            name: formdata.logo[0],
                            status: 'done',
                            url: LINK.myHostimgServerImg + "/" + formdata.logo[0],
                        }], icon_circel: [{
                            uid: -3,
                            name: formdata.icon_circel[0],
                            status: 'done',
                            url: LINK.myHostimgServerImg + "/" + formdata.icon_circel[0],
                        }]
                    }
                }
                setimgesInfo(imgesInfo_)
                formRef.current.setFieldsValue(formdata)
                
            }
         
            }
        }
        return () =>
        {

        };
    }, [Web.SettingsShowByCode]);
    useEffect(() =>
    {
        if (isupload === typeResUpload) {
            onFinishForm(values);
        }
        return () =>
        {

        };
    }, [typeResUpload]);
    useEffect(() =>
    {

        if (Web.UploadFileLa) {

            let type = Web.UploadFileLa.type;

            if (Web.UploadFileLa.code === 200) {
                setRecord({ ...record, [type]: Web.UploadFileLa.data })
                setypeResUpload(type)

            } else {
                message.warn("Upload file lỗi!")
            }


            dispatch(ActionsWeb.ResetUploadFileLa);

        }
        return () =>
        {

        };
    }, [Web.UploadFileLa]);
    const setimgesInfo_ = (typef, type, data) =>
    {


        if (typef === "file" || typef === "video") {
            const imageDelete = setimgesInfo?.[typef]?.[type];
            if (imageDelete?.[0]) {
                imageDelete[0]["folder"] = type;

            }
            setimgesInfoRemove(imageDelete ? [...imgesInfoRemove, ...imageDelete] : [...imgesInfoRemove])

            setimgesInfo({
                ...imgesInfo, [typef]: { ...imgesInfo[typef], [type]: data }
            })


        } else {
            setimgesInfo({
                ...imgesInfo, [typef]: { ...imgesInfo[typef], [type]: data }
            })

        }

    }

    const setimgesInfoRemove_ = (typef, type, data) =>
    {

        data["folder"] = type;
        let images = "";
        if (typef === "files") {
            images = setimgesInfo?.[typef]?.[type]?.filter(item =>
            {
                return item !== data.name;
            })
            setimgesInfoRemove([...imgesInfoRemove, data])

            setimgesInfo({
                ...imgesInfo, [typef]: { ...imgesInfo[typef], [type]: images }
            })

        } else {
            setimgesInfoRemove([...imgesInfoRemove, data])


        }


    }

    const uploadFile = (values) =>
    {


        setValues(values)
        setrequired({})



        let isupload = 0;

        if (imgesInfo.file) {
            for (let x in imgesInfo.file) {
                const element = imgesInfo.file[x];
                let arravata = element.filter(item =>
                {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    dispatch(ActionsWeb.UploadFileLaRequest(arravata, "settings", x));
                    isupload = x
                }
            }


        }
        if (imgesInfo.files) {
            for (let x in imgesInfo.files) {
                const element = imgesInfo.files[x];
                let arravata = element.filter(item =>
                {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    dispatch(ActionsWeb.UploadFileLaRequest(arravata, "settings", x));

                    isupload = x
                }
            }

        }
        if (imgesInfo.video) {
            for (let x in imgesInfo.video) {
                const element = imgesInfo.video[x];
                let arravata = element.filter(item =>
                {
                    return item.originFileObj;
                })
                if (arravata.length > 0) {
                    dispatch(ActionsWeb.UploadFileLaRequest(arravata, "video", x));
                    isupload = x
                }
            }

        }
        setisupload(isupload);

        if (isupload === 0) {
            onFinishForm(values);
        }
    }
    return (
        <div className='dropdown_rim'>
            <Form name="form" className="form" ref={formRef} onFinish={uploadFile}
                layout={"horizontal"}
                style={{
                    padding: "0px !important",
                    width: "100%",
                    display: "flex",
                    margin: 0
                }}>
                <div className='row'>
                    <div className='col-lg-12'>
                        <Form.Item name="title" label="Tiêu đề phần mềm" layout={"horizontal"}>
                            <Input placeholder='Tiêu đề phần mềm' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="description" label="Mô tả" layout={"horizontal"}>
                            <TextArea placeholder='Mô tả' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                      
                      {phones && phones.length > 0 && phones.map((item, index) =>
                      {
                          return <Form.Item key={index} name={"phone" + (index + 1)} label={"Điện thoại " + (index + 1)} layout={"horizontal"} initialValue={item}>
                              <Input placeholder={'Điện thoại ' + (index + 1)} addonAfter={<><PlusOutlined onClick={() => { setPhones([...phones, ""]) }} style={{ marginRight: "10px" }} /><MinusOutlined onClick={() =>
                              {
                                  if (phones.length>1){
                                      setPhones(phones.filter(el =>
                                      {
                                          return el !== item
                                      }))
                                  }
                                
                              }
                              } /></>} />
                          </Form.Item>
                      })}
                     
                  </div>

                  <div className='col-lg-12'>
                      {emails && emails.length > 0 && emails.map((item, index) =>
                      {
                          return <Form.Item key={index} name={"email" + (index + 1)} label={"Email " + (index + 1)} layout={"horizontal"} initialValue={item}>
                              <Input placeholder={'Email ' + (index + 1)} addonAfter={<><PlusOutlined onClick={() => { setEmails([...emails, ""]) }} style={{ marginRight: "10px" }} /><MinusOutlined onClick={() =>
                              {
                                  if (emails.length>1){
                                      setEmails(emails.filter(el =>
                                      {
                                          return el !== item
                                      }))
                                  }
                                 
                              }
                              } /></>} />
                          </Form.Item>
                      })}
                  

                  </div>
                  <div className='col-lg-12'>
                      {address && address.length > 0 && address.map((item, index) =>
                      {
                          return <Form.Item key={index} name={"address" + (index + 1)} label={"Địa chỉ " + (index + 1)} layout={"horizontal"} initialValue={item}>
                              <Input placeholder={'Địa chỉ ' + (index + 1)} addonAfter={<><PlusOutlined onClick={() => { setAddress([...address, ""]) }} style={{ marginRight: "10px" }} /><MinusOutlined onClick={() =>
                              {
                                  if (address.length>1){
                                      setAddress(address.filter(el =>
                                      {
                                          return el !== item
                                      }))
                                  }
                                 
                              }
                              } /></>} />
                          </Form.Item>
                      })}
                   

                  </div>
                    <div className='col-lg-12'>
                        <Form.Item name="icon" label="Icon" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"icon"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["icon"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Icon" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="logo" label="Logo" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"logo"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["logo"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Logo" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="icon_circel" label="Icon tròn" layout={"horizontal"}>
                            <Upload isCropImg={false} multiple={false} max={1} type_={"icon_circel"} type_f={"file"} setimgesInfo={setimgesInfo_} setimgesInfoRemove={setimgesInfoRemove_} fileList={imgesInfo?.file?.["icon_circel"]} accept=".jpg,.jpeg,.webp,.png,.svg" title="Icon tròn" icon={<FileImageOutlined />} />
                        </Form.Item>
                    </div>

                    <div className='col-lg-12'>
                        <Form.Item label="Công ty" layout={"horizontal"}>
                           
                        </Form.Item>
                        <Form.Item name="companyName" label="Tên công ty" layout={"horizontal"}>
                            <Input placeholder='Tên công ty' />
                        </Form.Item>
                    </div>

                    <div className='col-lg-12'>
                        <Form.Item name="taxCode" label="Mã số thuế" layout={"horizontal"}>
                            <Input placeholder='Mã số thuế' />
                        </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item name="companyAddress" label="Địa chỉ công ty" layout={"horizontal"}>
                            <Input placeholder='Địa chỉ công ty' />
                        </Form.Item>
                    </div>


                    <div className='col-lg-12'>
                        <Form.Item label="kết nối Wininvoice api" layout={"horizontal"}>
                           
                        </Form.Item>
                        <Form.Item name="wininvoiceClientID" label="clientID" layout={"horizontal"}>
                            <Input placeholder='clientID' />
                        </Form.Item>
                    </div>
                 
                    <div className='col-lg-12'>
                      
                        <Form.Item name="wininvoiceClientSecret" label="clientSecret" layout={"horizontal"}>
                            <Input placeholder='clientSecret' />
                        </Form.Item>
                    </div>
                    
                    <div className='col-lg-12'>
                      
                      <Form.Item name="wininvoiceVat" label="Vat" layout={"horizontal"}>
                          <Input placeholder='Vat' />
                      </Form.Item>
                  </div>
                  <div className='col-lg-12'>
                      
                      <Form.Item name="wininvoiceBaseUrl" label="Url Api" layout={"horizontal"}>
                          <Input placeholder='Url Api' />
                      </Form.Item>
                    </div>

                    <div className='col-lg-12'>
                      
                      <Form.Item name="wininvoiceInvName" label="Mẫu hóa đơn" layout={"horizontal"}>
                          <Input placeholder='Mẫu hóa đơn' />
                      </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                      
                      <Form.Item name="wininvoiceInvSerial" label="Ký hiệu hóa đơn" layout={"horizontal"}>
                          <Input placeholder='Ký hiệu hóa đơn' />
                      </Form.Item>
                    </div>
                    <div className='col-lg-12'>
                        <Form.Item>

                            <Button type="primary" loading={isloading} style={{ marginRight: "10px" }} htmlType="submit">Cập nhật</Button>
                        </Form.Item>
                    </div>

                  
                </div>
            </Form>
        </div>
    );
};

export default Index;
