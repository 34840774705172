import React, { useRef,useState,useEffect} from 'react'




export const VideoViewer = ({width,height,file,src }) => {

  const [videoSrc, setVideoSrc] = useState(null);
  const [videoName, setVideoName] = useState('');
  const videoRef = useRef(null);
  useEffect(() => {
    setVideoSrc(null);
    if (file && file.originFileObj) {

      const actualFile = file.originFileObj.originFileObj || file.originFileObj;
      if (actualFile instanceof File) {
     
        console.log(actualFile);
        try {
          const url = URL.createObjectURL(actualFile);
          setVideoSrc(url);

          // Giải phóng tài nguyên khi component bị unmount hoặc video thay đổi
          return () => {
            URL.revokeObjectURL(url);
          };
        } catch (error) {
          console.error('Error creating ObjectURL:', error);
        }
      } else {
        console.error('actualFile không phải là một File object.');
      }
    }else{
      setVideoSrc(src);
    }

   
    
  
    // return () => {
      
    // };
  }, [file,src]);
  useEffect(() => {
   
    if (videoRef.current) {
      videoRef.current.load(); // Reload video when src changes
    }
    return () => {
      
    };
  }, [videoSrc]);
  return (
    <div>

      {videoSrc && (
        <div>
         
          <video  key={videoSrc}  ref={videoRef} width={width} height={height} controls autoPlay muted>
            <source src={videoSrc} type="video/mp4" />
           
          </video>
        </div>
      )}
  </div>
  )
}
