import { Button, Dropdown, Form, Input, InputNumber, Popover, Select } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { v4 as uuidv4 } from 'uuid';
import
{
    CaretDownOutlined,FileImageOutlined
} from '@ant-design/icons';
import Upload from "./Upload";
import { ECOMMERCE_KEY } from "../../../../constants/DefaultData";
const TableClassify = (props) =>
{

   
    
    const [showDropdow, setshowDropdow] = useState("");
    const [data, setData] = useState([
        {
            "name": "Biến thể-0-1",
            "children": [
                {
                    "name": "Biến thể-1-1",
                    "children": [
                        {
                            "name": "Biến thể-2-1",
                            "children": [
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                },
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                }
                            ],
                            "rowSpan": 2
                        },
                        {
                            "name": "Biến thể-2-1",
                            "children": [
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                },
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                }
                            ],
                            "rowSpan": 2
                        }
                    ],
                    "rowSpan": 4
                },
                {
                    "name": "Biến thể-1-222",
                    "children": [
                        {
                            "name": "Biến thể-2-1",
                            "children": [
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                },
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                }
                            ],
                            "rowSpan": 2
                        },
                        {
                            "name": "Biến thể-2-1",
                            "children": [
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                },
                                {
                                    "name": "Biến thể-3-1",
                                    "children": []
                                }
                            ],
                            "rowSpan": 2
                        }
                    ],
                    "rowSpan": 4
                }
            ],
            "rowSpan": 8
        }]);
    const [tableData, setTableData] = useState([]);
    const [fileList, setFileList] = useState([]);
   
    useEffect(() =>
    {
          
            
        setFileList(props.fileList);
      
        return () => { };
    }, [props.fileList]);
    useEffect(() =>
    {

        if (props.data) {
         
            const generateTableData = async (data) =>
            {
                let rows = await renderNestedItemsAsync(data);

                setTableData(rows);
            };

            generateTableData(props.data);
        }
        return () => { };
    }, [props.data,fileList]);

    useEffect(() =>
    {

        if (props.data) {

            const generateTableData = async (data) =>
            {
                let rows = await renderNestedItemsAsync(data);

                setTableData(rows);
            };

            generateTableData(props.data);
        }
        return () => { };
    }, [showDropdow,fileList]);
    const setshowDropdow_=(key)=>{
        if (showDropdow===key){
            setshowDropdow("")
        }else{
            setshowDropdow(key)
        }
    }

    const getFilteredDataImage = (key_) => {
        const data =  fileList;

        // console.log("datadatadatadata ",key_,data);
        const filteredKeys = Object.keys(data).filter(key => key==key_);
        const filteredData = [];
      
        
        filteredKeys.forEach(key => {
          filteredData.push(data[key]);
        });
        // console.log("filteredDatafilteredData ",filteredData[0]);
        
        return filteredData[0];
      }
    // Hàm để đảm bảo đệ quy hoàn toàn
    async function renderNestedItemsAsync(data)
    {
       
        let arrParent = {}
        const renderedItems = [];
        const renderedItemHead = [];
        const updateItemValue = async (data, level = 0, parent_item = null) =>
        {
            let check = false;

            return Promise.all((data ||[]).map(async (item, indi) =>
            {


                if (item.children && item.children.length > 0) {
                    if (parent_item) {
                        arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
                    }
                    await updateItemValue(item.children, level + 1, item);

                } else {

                    const tmp = Object.values({ ...arrParent });
                    if (parent_item) {
                        tmp.push({ ...parent_item, children: [] });
                    }
                    
                    tmp.push({ ...item, children: [] });
                  
                    if (renderedItemHead.length<=0){
                        renderedItemHead.push(<tr key={uuidv4()}>
                             <th style={{ 
                                // minWidth:"unset",width:"50px" 
                                }}>Hình ảnh</th>
                            {Array.from({ length: tmp.length }, (_, index) =>
                            {
                                return <th></th>

                            })}
                             
                            <th>Giá</th>
                            <th>Giảm giá</th>
                            <th>Phần trăm</th>
                            <th>Giá giảm</th>
                            <th>Số lượng</th>
                            <th>Cửa hàng</th>
                            <th>Sku</th>
                        </tr>)
                    }
                    let key_="";
                    let key_2=""
                    renderedItems.push(
                        <tr key={uuidv4()}>

                        
                        {tmp.map((d_, ide_) =>
                        {
                            key_2 += d_.key
                        
                            
                           
                        })}
                        <td><Upload isCropImg={false} multiple={false} max={1} type_={"classify"+key_2} focus={props.focus} type_f={1} setimgesInfo={props.setimgesInfo} setimgesInfoRemove={props.setimgesInfoRemove} fileList={getFilteredDataImage("classify"+key_2)} accept=".jpg,.jpeg,.webp,.png,.svg" title="Ảnh bìa" icon={<FileImageOutlined />} /></td>    
                        {tmp.map((d_, ide_) =>
                        {
                            key_ += d_.key
                           
                            
                            return <td><Input bordered={false}  style={{ minWidth:"100px" }} placeholder="" readOnly value={d_?.name} /></td>
                        })}

                            <td className="price_classify">
                            <Form.Item  noStyle
                                initialValue={item?.price}
                                    name={`price_${key_}`}
                            >
                                <Input bordered={false} addonBefore="₫" placeholder=""
                                style={{ minWidth:"100px" }}
                                        onChange={(e) => props.onChangeDiscountItem(e, "price", `${key_}`)}
                                     />
                                </Form.Item>
                            </td>
                            <td className="discount_classify" >   
                                <Form.Item  noStyle
                                    initialValue={item?.discount}
                                    name={`discount_${key_}`}
                                >
                                    <Input bordered={false} addonBefore="₫" placeholder=""  style={{ minWidth:"100px" }}
                                        // onFocus={() => props.ColorCell("discount_classify", true)}
                                        // onBlur={() => { props.ColorCell("discount_classify", false) }} 
                                        onChange={(e) => props.onChangeDiscountItem(e, "discount", `${key_}`)}
                                         />
                                </Form.Item>
                            </td>
                            <td className="discount_percent_classify">
                                <Form.Item  noStyle
                                    initialValue={item?.discount_percent}
                                    name={`discount_percent_${key_}`}
                                >
                                    <Input bordered={false} addonBefore="%" placeholder=""  style={{ minWidth:"100px" }}
                                        // onFocus={() => props.ColorCell("discount_percent_classify", true)}
                                        // onBlur={() => { props.ColorCell("discount_percent_classify", false) }} 
                                        onChange={(e) => props.onChangeDiscountItem(e, "discount_percent", `${key_}`)}
                                         />
                                </Form.Item>
                            </td>
                            <td className="discount_price_classify"> 
                                <Form.Item  noStyle
                                    initialValue={item?.discount_price}
                                    name={`discount_price_${key_}`}
                                    readOnly
                                >
                                    <Input bordered={false} addonBefore="₫" placeholder=""  style={{ minWidth:"100px" }}
                                        // onFocus={() => props.ColorCell("discount_price_classify", true)}
                                        // onBlur={() => { props.ColorCell("discount_price_classify", false) }} 
                                         />
                                </Form.Item>
                            </td>
                            <td className="amount_price_classify">
                                <div className="d-flex">
                                <Form.Item  noStyle
                                    initialValue={item?.amount}
                                    name={`amount_price_${key_}`}
                                >
                                    <InputNumber bordered={false} placeholder=""  style={{ minWidth:"100px" }}
                                        onChange={(e) => props.onChangeAmountItem(e, "amount_price_", `${key_}`)}

                                        // onFocus={() => props.ColorCell("amount_price_classify", true)}
                                        // onBlur={() => { props.ColorCell("amount_price_classify", false) }} 
                                    />
                                </Form.Item>
                              
                                <div className={`amout_detail`}>
                                    <Button htmlType="button" ><CaretDownOutlined /></Button>
                                    {/* <div className="amout_detail_hidden_overlay" onClick={() => { setshowDropdow(null) }}></div> */}
                                    <div className="amout_detail_hidden">

                                        {item?.store_ids && item?.store_ids.map((item_, index_) =>
                                        {

                                            return <div key={index_} className="flex_">
                                                <Form.Item noStyle
                                                    name={`amount_price_item_d_${key_}_${index_}`}
                                                    initialValue={item_?.amount}>
                                                    <InputNumber bordered={false} placeholder=""
                                                     onChange={(e) => props.onChangeAmountItemSub(e, "amount_price_item_d_", `${key_}`, index_)}
                                                    />
                                                </Form.Item>
                                                <Form.Item noStyle
                                                    initialValue={item_.store_id}
                                                    name={`house_item_d_${key_}_${index_}`}
                                                    readOnly
                                                >
                                                    <Select

                                                        maxTagCount='responsive'
                                                        style={{
                                                            width: 250,
                                                        }}
                                                        placeholder="Cửa hàng"
                                                        options={(props.ListStore || []).map((d) => ({
                                                            value: d.id,
                                                            label: d.name,
                                                        }))}
                                                        disabled
                                                    />
                                                </Form.Item>
                                            </div>
                                        })}
                                    </div>
                                </div>
                                </div>
                                
                            </td>
                            <td className="house_classify"> 
                                <Form.Item noStyle
                                    initialValue={item?.store_id}
                                    name={`house_${key_}`}
                                >
                                    <Select
                                        mode={"multiple"}
                                        maxTagCount='responsive'
                                        style={{
                                            width: 150,
                                        }}
                                        // value={item.store_id}
                                        placeholder="Cửa hàng"
                                       
                                        // onFocus={() => props.ColorCell("house_classify", true)} 
                                        // onBlur={() => { props.ColorCell("house_classify", false) }} 
                                        onChange={(e) => { props.onChangeStoreItem(e, `house_`, `${key_}`) }}
                                        options={(props.ListStore || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}
                                    />
                                </Form.Item>
                            </td>
                            <td className="sku_classify"> 
                                <Form.Item noStyle
                                        initialValue={
                                            props?.setEditTitok?.keyshow==ECOMMERCE_KEY.LAZADA?item?.lazada_code_sku:
                                            props?.setEditTitok?.keyshow==ECOMMERCE_KEY.TITOK?item?.tiktok_code_sku:
                                            props?.setEditTitok?.keyshow==ECOMMERCE_KEY.SHOPEE?item?.shopee_code_sku:item?.sku
                                        }
                                    name={`sku_${key_}`} 
                                    >
                                    <Input bordered={false}  placeholder=""  style={{ minWidth:"100px" }}
                                        // onFocus={() => props.ColorCell("code_classify", true)}
                                        // onBlur={() => { props.ColorCell("code_classify", false) }} 
                                         />
                                </Form.Item>
                            </td>
                        </tr>

                    )


                }
             
                return item;
            }));
        }
        await updateItemValue(data, 0);

        
        return [<thead>{renderedItemHead}</thead>, <tbody>{renderedItems}</tbody>];
    }



    const scrollRef = useRef(null);
    const [isDragging, setIsDragging] = useState(false);
    const [startX, setStartX] = useState(0);
    const [scrollLeft, setScrollLeft] = useState(0);
    const handleMouseDown = (e) => {
        setIsDragging(true);
        setStartX(e.pageX - scrollRef.current.offsetLeft);
        setScrollLeft(scrollRef.current.scrollLeft);
      };
    
      const handleMouseMove = (e) => {
        if (!isDragging) return;
        e.preventDefault();
        const x = e.pageX - scrollRef.current.offsetLeft;
        const walk = (x - startX) * 2; // Tốc độ cuộn (tăng hoặc giảm giá trị này tùy thích)
        scrollRef.current.scrollLeft = scrollLeft - walk;
      };
    
      const handleMouseUp = () => {
        setIsDragging(false);
      };
    
      const handleMouseLeave = () => {
        setIsDragging(false);
      };
    return (
        <div className={`table-responsive nochose upload-mini ${props?.disableUpload?"disable-upload":""}`}
        ref={scrollRef}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={handleMouseUp}
        onMouseLeave={handleMouseLeave}
        style={{ cursor: isDragging ? 'grabbing' : 'grab' }}>
            <table className="table">
                {tableData}
            </table>
        </div>
    );
};

export default TableClassify;
