import React, { Component, createRef } from "react";
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";

import * as ActionsApp from "../../../../actions/indexApp";
import "./style.css";
import * as LINK from "../../../../constants/Links";
import { Divider, Radio, Table, Pagination, Input, Button, Modal, Image, Tag, Switch as SwitchA, message, DatePicker, Tooltip, Form, TreeSelect, Select, Card, Row, Col, Popover, List, Checkbox, } from 'antd';
import moment from 'moment';
import { arrColors } from "./../../../../constants/Colors";
import * as Colors from "../../../../constants/Colors";
import
{
    FolderViewOutlined,
    DeleteOutlined, AppstoreOutlined, CheckCircleOutlined,
    EditOutlined, SearchOutlined, PlusCircleOutlined, UnorderedListOutlined, DiffOutlined, SyncOutlined

} from '@ant-design/icons';
import { formatMN1, CutString, SetProductsData, getParamsUrl, checkScreenSize, objectToUrlParams, getCookie } from "../../../../service";
import { META } from "../../../../utils/constant";
import ProductsMobile from "./ProductsMobile";

import LoadingSmile from "./../../../../components/loadingSmile/loadingSmile";

import { linkSocket } from "../../../../constants/LinkApi";
import * as caseSocket from "../../../../constants/CaseSocket";
const { RangePicker } = DatePicker;
class Products extends Component
{

    constructor(props)
    {
        super(props);
        this.formSearchRef = createRef(null)
        this.state = {
            meta: {
                ...META
            },
            ListProducts: [],
          
            columns: [
                {
                    title: 'STT',
                    dataIndex: 'key',


                },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    filterMode: 'tree',
                    filterSearch: true,
                    // filters: [
                    //     {
                    //         text: 'Joe',
                    //         value: 'Joe',
                    //     },
                    //     {
                    //         text: 'Category 1',
                    //         value: 'Category 1',
                    //     },
                    //     {
                    //         text: 'Category 2',
                    //         value: 'Category 2',
                    //     },
                    // ],
                    onFilter: (value, record) => record.title.startsWith(value),
                    sorter: (a, b) => a.title - b.title,
                },
                {
                    title: 'Slug',
                    dataIndex: 'slug',
                    sorter: (a, b) => a.slug - b.slug,

                },
                {
                    title: 'Giá',
                    dataIndex: 'price',
                    sorter: (a, b) => a.price - b.price,

                },
                {
                    title: 'Giảm giá',
                    dataIndex: 'discount',
                    sorter: (a, b) => a.discount - b.discount,

                },
                {
                    title: 'Danh mục ',
                    dataIndex: 'category',
                    // render: (text, record, index) => (

                    //     <span>{text}</span>
                    // )

                },
                {
                    title: 'Ẩn',
                    dataIndex: 'hidden',

                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    sorter: (a, b) => a.created_at - b.created_at,


                },
                {
                    title: 'Ngày cập nhật',
                    dataIndex: 'updated_at',
                    sorter: (a, b) => a.updated_at - b.updated_at,

                },
                {
                    title: 'Hành động',
                    dataIndex: 'action',
                    width: "20%",
                    render: (text, record, index) => (
                        <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} >{text}
                            <span className="__action_">
                                <button className="reset_btn btn_edit btn__" onClick={() => this.editRows(index)}> <EditOutlined /> </button>
                                <button className="reset_btn btn_delete btn__" onClick={() => { this.delete(index) }}><DeleteOutlined /></button>
                                <button className="reset_btn btn_view btn__" onClick={() => { this.editRows(index) }}> <FolderViewOutlined /></button>

                            </span>
                        </div>
                    ),
                },
            ],
            pagination: {
                limit: 2,
                page: 1,
                column: "id",
                sort: "DESC",
            },
            data: [],
            StoreProducts: {},
            visible: false,
            confirmLoading: false,
            modalText: "",
            isloading: false,
            ListCategory: [],
          
            search: {},
            ListTags: [],
            isLoadingSearch: false,
            statusSearch: "",
            isShowList:false,
            ListEcommerceConfig: [],
            isloadingSync: "",
            isloadingSyncList:false,
            idsCheck:[],
            next_page_token:"",
            syncProducts:[],
            syncProductsSet:[],
            isSyncEdit:false,
            socket:null
          
        }

    }
    SetisShowList = (type) =>
    {
        localStorage.setItem("isShowList", !type ? 0 : 1)
        this.setState(state =>
        {
            return {
                isShowList: type
            }
        })
    }
    SetisLoadingSeach = (type) =>
    {
        this.setState(state =>
        {
            return {
                isLoadingSearch: type
            }
        })
    }
    

    
    componentDidMount = () =>
    {
        const isshowList = localStorage.getItem("isShowList")
        const urlParams = getParamsUrl();
        this.SetisShowList((1 * isshowList) === 0 ? false : true)
        const { match } = this.props;
        const { params } = match;
        const code = (params.code); // Lấy giá trị của tham số từ URL
        this.props.SearchProductsLazadaRequest({
            limit: this.state.meta.per_page*100,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: code, next_page_token: ""
        }, { data: {} });
        this.setState(state =>
        {
            return {
                code: code
            }
        })

      

    }
    static getDerivedStateFromProps(nextProps, prevState)
    {
        if (nextProps.Web && nextProps.Web.syncProducts !== prevState.syncProducts) {

          
            return {
                syncProducts: nextProps.Web.syncProducts,
                isloadingSync: "",
                isloadingSyncList:false,
                idsCheck:[],
                
            };


        }
        if (nextProps.appReducer && nextProps.appReducer.socket !== prevState.socket) {
          
        
            return {
                socket: nextProps.appReducer.socket,
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false
               
            };


        }
        if (nextProps.Web && nextProps.Web.ListProducts !== prevState.ListProducts) {


            return {
                ListProducts: nextProps.Web.ListProducts,
                isLoadingSearch: false
            };


        }
        if (nextProps.Web.StoreProducts && nextProps.Web.StoreProducts !== prevState.StoreProducts) {


            return {
                StoreProducts: nextProps.Web.StoreProducts,

            };

        }

        if (nextProps.Web.productsData_ && nextProps.Web.productsData_ !== prevState.productsData_) {

            nextProps.Web.productsData_.map((item, index) =>
            {
                if (["ListTags", "ListCategory"].includes(item)) {
                    if (nextProps.Web[item] && nextProps.Web[item] !== prevState[item]) {

                        SetProductsData('ListTags', nextProps.Web.ListTags)
                        return {
                            [item]: nextProps.Web[item].data
                        }

                    }
                }


            })
        }

        return null;


    }
    connectSocket = () =>
    {
       
        // const newSocket = new WebSocket(linkSocket);

        // newSocket.addEventListener('open', () =>
        // {
        //     console.log('Connected to WebSocket server');
        // });
        if (this.state.socket){
            console.log('====================================');
            console.log("connectSocketconnectSocketconnectSocket", this.state.socket);
            console.log('====================================');
            this.state.socket.addEventListener('message', (event) =>
            {

                let json = JSON.parse(event.data);
                console.log('====================================');
                console.log("connectSocketconnectSocketconnectSocketjsonjson", json);
                console.log('====================================');
                switch (json.type) {
                    case caseSocket.PUSH_SYNC_DATA_PR_TIKTOK:
                        let user = getCookie("user");
                        user = JSON.parse(user);
                      
                        if (user.id === json.user_id) {
                            try {
                                let syncProducts = JSON.parse(json.data)
                                this.processSyncPrData(syncProducts);
                                this.props.setIsync(false);
                                // this.props.setNotification({ show: true, title: "Thông báo", text: "Đã xư lý xong dữ liệu tiktok" });
                                console.log('====================================');
                                console.log("json.msgjson.msg", syncProducts);
                                console.log('====================================');
                            } catch (error) {

                                console.error("errorerror", error);
                            }

                        }
                        break;

                    default:
                        break;
                }

            });
        }
    

        // this.setState(state =>
        // {
        //     return { socket: newSocket }
        // });
    }
    sendMessage = (message) =>
    {
        this.state.socket.send(message);
    }

    processSyncPrData = (syncProducts)=>{
        try {
        if(syncProducts){
            const urlParams = getParamsUrl();
            let {  code, isShowList, isSyncEdit } = this.state;
            if (isSyncEdit) {
                const syncId = Object.keys(syncProducts)[0];
                this.props.history.push({
                    pathname: LINK.WEB_PRODUCTS_EDIT + "/" + syncId,
    
                });
                return;
            }
            if (isShowList) {
                this.props.SearchProductsTiktokRequest({
                    limit: this.state.meta.per_page * 100,
                    page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: code, next_page_token: ""
                }, { data: {} });
            }
    
            this.setState(state =>
            {
                return {
                    syncProductsSet: [...state.syncProductsSet, ...Object.values(syncProducts)]
                }
            })
        }
        } catch (error) {

            console.error("errorerror", error);
        }
    }
    modalInfo = (text,content) =>
    {
        Modal.info({
            title: text,
            content: content,
            onOk() { },
            onCancel() { },
        });
    };
    componentDidUpdate(prevProps, prevState)
    {


        if (this.state.syncProducts !== prevState.syncProducts) {
           
            this.modalInfo("Thông báo","Đang xử lý dữ liệu! quá trình có thể mất nhiều thời gian");
           
            this.props.setIsync(true);
          
        }

        if (this.state.ListProducts !== prevState.ListProducts) {
            console.log(this.state.ListProducts);
            
            let { ListProducts } = this.state;
         
            if (ListProducts.code !== 200 && ListProducts.isfilter) {
                message.warn("không tìm thấy sản phẩm!");
            }
          
            this.data()
        }

        if (this.state.ListCategory !== prevState.ListCategory) {
            let { ListCategory } = this.state
   
            // if (ListCategory) {
            //     this.datacate()
            // }

        }
        if (this.state.socket !== prevState.socket) {
            // let { ListCategory } = this.state
          
            this.connectSocket();

        }

        if (this.state.StoreProducts !== prevState.StoreProducts) {
            let { StoreProducts, isDuplicate } = this.state;

            try {
                if (StoreProducts.code === 200) {
                    if (StoreProducts.message && typeof StoreProducts.message === "string") {
                        message.success(StoreProducts.message)
                        this.setState(state =>
                        {
                            return {
                                record: {

                                    hidden: 0,
                                    deleted: 0
                                }
                            }
                        })
                    } else {

                        message.error("Có lỗi!")
                    }
                    const urlParams = getParamsUrl();
                    const filteredvalues = Object.fromEntries(
                        Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
                    );
                    // created_at
                    if (filteredvalues.fromdate) {
                        filteredvalues.created_at = [];
                        filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
                        filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
                    }

                    // this.formSearchRef.current.setFieldsValue(filteredvalues)

                    this.props.SearchProductsRequest({
                        limit: this.state.meta.per_page,
                        page: isDuplicate ? 1 : this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort
                    }, { data: filteredvalues });

                }
                else {
                    if (StoreProducts.message && typeof StoreProducts.message === "string") {

                        message.error(StoreProducts.message)
                    } else {
                        for (let x in StoreProducts.message) {
                            let mess = StoreProducts.message[x];
                            for (let y in mess) {
                                if (mess[y]) {
                                    message.error(mess[y])
                                }

                            }
                        }
                        // message.error("Có lỗi!")
                    }

                }

            } catch (error) {
                message.error("Có lỗi!")
                this.SetisLoading(false)
            }
            this.SetisLoading(false)
            this.setConfirmLoading(false)
            this.setVisible(false)
            this.setState(state =>
            {
                return {
                    StoreProducts: "",
                    isDuplicate: false
                }
            })
            this.props.ResetStoreProducts();



        }
    }
    SetisLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                isloading: type
            }
        })
    }

    setColumn_ = (filters) =>
    {
        this.setState(state =>
        {
            return {
                columns: [
                    // {
                    //     title: 'STT',
                    //     dataIndex: 'key',
                    //     width: "5%",
                    //     align: 'center',

                    // },
                    {
                        title: 'id',
                        dataIndex: 'item_id',
                        // filterMode: 'tree',
                        filterSearch: true,
                        // // filters: filters.id,
                        // // onFilter: (value, record) =>
                        // // {
                         
                        // //     return record.id.startsWith(value)

                        // // },
                        // sorter: (a, b) => a.id - b.id,
                    },
                    {
                        title: 'Tiêu đề',
                        dataIndex: 'title',
                        // filterMode: 'tree',
                        filterSearch: true,
                        // filters: filters.title,
                        // onFilter: (value, record) =>
                        // {
                            
                        //     return record.title.startsWith(value)

                        // },
                        // sorter: (a, b) => a.title - b.title,
                        render: (text, record, index)=>{
                            
                            return CutString(record.attributes.name,200,false)
                        }
                    },
                    
                    {
                        title: 'ngày tạo',
                        dataIndex: 'created_time',
                        // filterSearch: true,
                        // filters: filters.create_time,
                        // sorter: (a, b) => a.created_time - b.created_time,
                        render: (text, record, index) =>
                        {
                            return moment(Number(text)).format('DD/MM/YYYY h:m:s');;
                        }

                    },
                   
                    {
                        title: 'Hành động',
                        dataIndex: 'action',
                        width: "10%",
                        render: (text, record, index) => {
                           
                           return <div style={{ display: "flex", width: "100%", justifyContent: "space-between", alignItems: "center" }} ><div>
                                {(!record.isSyncEd && !this.state.syncProductsSet.includes(record.id)) && <div className='flex_'> <Button
                                    type="primary"
                                    icon={<SyncOutlined />}
                                    loading={this.state.isloadingSync === record.id}
                                    size='small'
                                    onClick={() => this.SyncProductsRe(record)}
                                    style={{ marginRight: "10px" }}
                                /><Checkbox onChange={() => this.onChangeCheck(record)} /></div>}  {(record.isSyncEd || this.state.syncProductsSet.includes(record.id)) && <Button
                                    type="primary"
                                    icon={<CheckCircleOutlined />}
                                    style={{ backgroundColor: Colors.colorGreen, border: "none" }}
                                    size='small'

                                />}</div>
                            </div>
                        },
                    },
                ]
            }
        })
    }

    updateRecord = (e, record) =>
    {
        const tmp = { ...record, hidden: !e }
        let catetmp = [];
        // let tagstmp = Object.keys(record.tags_id);
        let tagstmp = [];

        Object.values(record.tags_id).map((item, index) =>
        {
            item["value"] = item.id;
            item["label"] = item.name;
            tagstmp.push(1 * item.id)
        });
        Object.values(record.category_id).map((item, index) =>
        {
            item["value"] = item.id;
            item["label"] = item.name;
            catetmp.push(1 * item.id)
        });
        tmp.category_id = catetmp;
        tmp.tags_id = tagstmp;

        this.SetisLoading(true)
        this.props.HiddenProductsRequest(tmp, tmp.id);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })
    }
    data = () =>
    {
        try {

            let { ListProducts } = this.state;
         
            if (ListProducts.length <= 0) {
                return;
            }

            let data = [];
            let listfil = { title :[],id:[]};
            (ListProducts?.products || []).map((item, index) =>
            {
                
                listfil = {
                    ...listfil, title: [...listfil.title, {
                        text: item.title,
                        value: item.title,
                    }], id: [...listfil.id, {
                        text: item.id,
                        value: item.id,
                    }], }
                item.key = item.id
                data.push(item)

            });
          
            
            this.setColumn_(listfil);
           
            this.setState(state =>
            {
                return {
                    data: data,
                    next_page_token: ListProducts?.next_page_token

                   
                }
            }, () =>
            {
                this.setState(state =>
                {
                    return {
                        isNewList: false,
                    }
                });

            })
        } catch (error) {
            console.error(error);
        }
    }

    editRows = (record) =>
    {
        if (record.isSyncEd){
            this.props.history.push({
                pathname: LINK.WEB_PRODUCTS_EDIT + "/" + record.product_id,

            });

        }else{
            this.SyncProductsRe(record);
            this.setState(state=>{
                return{
                    isSyncEdit:true,
                   
                }
            })
        }


      

    }
    duplicateItem = (record)=>{
        message.warning("Is coming soon");
        return;
        this.SetisLoading(true)
        this.props.duplicateProductRequest({id:record.id })
        this.setState(state =>
        {
            return {
                isNewList: true,
                isDuplicate: true
            }
        })
    }
    onSearch = (values) =>
    {
        this.SetisLoadingSeach(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
      
        this.props.SearchProductsTiktokRequest({
            limit: this.state.meta.per_page,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: this.state.code, next_page_token: ""
        }, { data: filteredvalues });
        delete filteredvalues["created_at"]

        const searchParams = new URLSearchParams(filteredvalues).toString();

        this.props.history.push(`${LINK.WEB_PRODUCTS_TKTOK}/${this.state.code}?${searchParams}`);
        this.setState(state =>
        {
            return {
                isNewList: true
            }
        })


    }
    onResetFrom = () =>
    {

        this.formSearchRef.current.resetFields();
        this.formSearchRef.current.submit();
        this.setState(state =>
        {
            return {
                isNewList: false
            }
        })
    }
    deleteModal = (record) =>
    {
        message.warning("Is coming soon");
        return;
        this.setState(state =>
        {
            return {
                record: { ...record, deleted: 1 }

            }
        }, () =>
        {
            this.setModalText(<span style={{ color: "red" }}>Bạn Có chắc muốn xóa! Sản phẩm sẽ được đưa vào thùng rác!</span>)
            this.setVisible(true)
        })
        // this.props.DeleteCategoryRequest(record, record.id ? record.id : "")
    }
    setModalText = (type) =>
    {
        this.setState(state =>
        {
            return {
                modalText: type
            }
        })
    }
    setVisible = (type) =>
    {
        this.setState(state =>
        {
            return {
                visible: type
            }
        })
    }
    setConfirmLoading = (type) =>
    {
        this.setState(state =>
        {
            return {
                confirmLoading: type
            }
        })
    }
    handleOkDelete = () =>
    {
        const { record } = this.state
        this.setModalText('Đang xóa');
        let catetmp = [];
        // let tagstmp = Object.keys(record.tags_id);
        let tagstmp = [];

        Object.values(record.tags_id).map((item, index) =>
        {
            item["value"] = item.id;
            item["label"] = item.name;
            tagstmp.push(1 * item.id)
        });
        Object.values(record.category_id).map((item, index) =>
        {
            item["value"] = item.id;
            item["label"] = item.name;
            catetmp.push(1 * item.id)
        });
        record.category_id = catetmp;
        record.tags_id = tagstmp;

        this.setConfirmLoading(true);
        this.props.DeleteProductsRequest(record, record.id)
        this.setState(state =>
        {
            return {
                isNewList: true,
            }
        });
    };

    handleCancel = () =>
    {
        // console.log('Clicked cancel button');
        this.setVisible(false);
    };
    OnPagination = (page, pageSize) =>
    {
        const urlParams = getParamsUrl();
        const filteredvalues = Object.fromEntries(
            Object.entries(urlParams).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }
        urlParams.next_page_token = this.state.next_page_token;
        const params_ = objectToUrlParams(urlParams);
        this.props.history.push(`${LINK.WEB_PRODUCTS_TKTOK}/${this.state.code}?${params_}`);
        this.props.SearchProductsTiktokRequest({
            limit: this.state.meta.per_page * 100,
            page: this.state.meta.current_page, column: this.state.meta.column, sort: this.state.meta.sort, code: this.state.code, next_page_token: this.state.next_page_token
        }, { data: {} });
    }
    SyncProductsRe=(item)=>{
      
        this.setState(state=>{
            return{
                isloadingSync:item.id,
                isNewList:true
            }
        })
        console.log("this.state.codethis.state.code ",this.state.code,item);
        
        this.props.SyncProductsLazadaQueueRequest({ code: this.state.code, }, { data: [item.item_id]});
    }

    onChangeCheck=(item)=>{
        const { idsCheck }=this.state;
        let idsCheck_ = [...idsCheck]
        if (!idsCheck_.includes(item.id)){
            idsCheck_ = [...idsCheck,item.id]
        }else{
            idsCheck_ = idsCheck.filter(el => {
                return el!==item.id
            })
        }
        this.setState(state=>{
            return{
                idsCheck: idsCheck_
            }
        })
    }
    SyncProductsList=()=>{
        const { idsCheck } = this.state;
        this.setState(state =>
        {
            return {
                isloadingSyncList: true,
                isNewList: true
            }
        })
       
        this.props.SyncProductsTiktokQueueRequest({ code: this.state.code, }, { data: idsCheck });
    }
    render()
    {
        let { routers } = this.props;
       
        return (
            <div className="main_content pro_tiktok" style={{ overflow: "unset" }}>

                {this.state.isShowList && this.state.isloadingSync && <div className="ovelay">

                    <div className="loading">
                        <LoadingSmile height={"100%"} width={"100%"} />
                    </div>
                </div>}
                <Modal
                    title="Thông báo"
                    visible={this.state.visible}
                    onOk={this.handleOkDelete}
                    confirmLoading={this.state.confirmLoading}
                    onCancel={this.handleCancel}
                >
                    <p>{this.state.modalText}</p>
                </Modal>
                {this.state.idsCheck.length>0 && <div className="btn_sync_fixed">
                    <Button
                        type="primary"
                        icon={<SyncOutlined />}
                        loading={this.state.isloadingSyncList}
                        size='small'
                        onClick={() => this.SyncProductsList()}
                        style={{ backgroundColor: Colors.colorWarning }}
                    />
                </div>}
             
                <div className="table_w" >
                    <Card title={<div style={{ display: "flex", alignItems: "center",justifyContent:"space-between",width:"100%" }}>
                        <div style={{ display: "flex", alignItems: "center" }}>
                            <button className="reset_btn btn_edit btn__" onClick={() => { this.SetisShowList(!this.state.isShowList) }}
                                style={{ display: "flex", justifyContent: "center", alignContent: "center" }}
                            >
                                {this.state.isShowList && <AppstoreOutlined style={{ color: Colors.pop2[3] }} />}
                                {!this.state.isShowList && <UnorderedListOutlined style={{ color: Colors.pop2[3] }} />}
                            </button>
                            <span style={{ marginLeft: "10px", color: Colors.pop2[3] }}>Sản phẩm trên Lazada</span>
                        </div>
                       
                        <Link to={LINK.WEB_PRODUCTS_EDIT + "/add"}> <Button size="small" type="primary"><PlusCircleOutlined /></Button></Link>
                    </div>
                    } bordered={false}
                        style={{ width: "100%", }}
                        bodyStyle={{
                            padding: "10px 10px",
                            // maxHeight: "90vh",
                            // overflowY: "scroll"
                        }}
                        headStyle={{
                            backgroundColor: Colors.colorgrey,
                            display: "flex",
                            minHeight: "35px",
                            borderRadius: "3px",

                        }}
                    >
                        <div className="btn_action_list">
                            <div className="left_">
                                <Form name="formsearch" ref={this.formSearchRef} onFinish={this.onSearch} style={{
                                    padding: "0px",
                                    width: "100%",
                                    display: "flex"
                                }}>
                                    <Form.Item style={{ margin: 0, width: "100%" }}>
                                        <Input.Group compact>
                                            <div className="row">

                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                           
                                                    <Form.Item name="code" noStyle style={{ margin: 0 }}>
                                                        <Input placeholder="code" />
                                                    </Form.Item>
                                                </div>
                                               

                                             
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                                        <RangePicker style={{width:"100%",}} />
                                                    </Form.Item>
                                                </div>
                                                <div className="col_ col-lg-2 col-md-3 col-sm-6">
                                                    <Form.Item noStyle style={{ margin: 0 }} >
                                                        <div style={{ display: "flex" }}>
                                                            <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={this.state.isLoadingSearch} />
                                                            <Button style={{ backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={this.onResetFrom}>Reset</Button>
                                                        </div>
                                                    </Form.Item>

                                                </div>
                                        </div>






                                        </Input.Group>
                                    </Form.Item>
                                </Form>
                            </div>
                         

                        </div>
                        {(checkScreenSize().width < 600 || !this.state.isShowList)  && <ProductsMobile lists={this.state.data} loading={this.state.loading} 
                            updateRecord={this.updateRecord}
                            editRows={this.editRows}
                            deleteModal={this.deleteModal}
                            duplicateItem={this.duplicateItem}
                            current_page={this.state.meta.current_page}
                            per_page={this.state.meta.per_page}
                            last_page={this.state.meta.last_page}
                            OnPagination={this.OnPagination}
                            isNewList={this.state.isNewList}
                            SyncProductsRe={this.SyncProductsRe}
                            isloadingSync={this.state.isloadingSync}
                            onChangeCheck={this.onChangeCheck}
                            next_page_token={this.state.next_page_token}
                            syncProducts={this.state.syncProductsSet}
                        />}
                        {checkScreenSize().width >= 600 && this.state.isShowList &&  <> <Table
                            rowClassName={(record, index) =>
                            {
                                return "__row_";
                            }}
                           
                            columns={this.state.columns}
                            dataSource={this.state.data}
                            pagination={false}
                           
                            style={{ paddingBottom: "30px" }}
                        />
                            <div className="pagination_">
                                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={this.state.meta && this.state.meta.per_page ? this.state.meta.per_page : 10} total={this.state.meta && this.state.meta.total ? this.state.meta.total : 10} showSizeChanger onChange={this.OnPagination} />
                            </div></>}
                       
                    </Card>
                </div>


            </div>
        );
    }

}

const mapStateToProps = (state) =>
{
    return {

        Web: state.Web,
        appReducer: state.appReducer
    };
};
const mapDispatchToProps = (dispatch, Props) =>
{
    return {
        InfoPackageActiveRequest: (body) =>
        {
            dispatch(ActionsWeb.InfoPackageActiveRequest(body));
        },
        ListCategoryRequest: (body) =>
        {
            dispatch(ActionsWeb.ListCategoryRequest(body));
        },
        // ////////////////

        ListInfoTransportRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoTransportRequest(body));
        },

        // ////////////////

        ListTagsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListTagsRequest(body));
        },
        ////////////////////////

        ListStoreRequest: (body) =>
        {
            dispatch(ActionsWeb.ListStoreRequest(body));
        },



        ////////////////////////

        ListClassifysRequest: (body) =>
        {
            dispatch(ActionsWeb.ListClassifysRequest(body));
        },
        /////////////////////////


        // //////////////

        ListInfoPackageRequest: (body) =>
        {
            dispatch(ActionsWeb.ListInfoPackageRequest(body));
        },

        // ///////////////////////
        ListAttributeProductsRequest: (body) =>
        {
            dispatch(ActionsWeb.ListAttributeProductsRequest(body));
        },
        ListProductsRequest: (data) =>
        {
            dispatch(ActionsWeb.ListProductsRequest(data));
        },
        SearchProductsRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchProductsRequest(body, data));
        },
        StoreProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.StoreProductsRequest(data, id));
        },
        duplicateProductRequest: (data, id) =>
        {
            dispatch(ActionsWeb.duplicateProductRequest(data, id));
        },
        DeleteProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.DeleteProductsRequest(data, id));
        },
        HiddenProductsRequest: (data, id) =>
        {
            dispatch(ActionsWeb.HiddenProductsRequest(data, id));
        },
        ResetStoreProducts: () =>
        {
            dispatch(ActionsWeb.ResetStoreProducts());
        },
        SearchProductsLazadaRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SearchProductsLazadaRequest(body, data));
        },
        SyncProductsTiktokRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SyncProductsTiktokRequest(body, data));
        },
        SyncProductsLazadaQueueRequest: (body, data) =>
        {
            dispatch(ActionsWeb.SyncProductsLazadaQueueRequest(body, data));
        },
        setIsync: (body) =>
        {
            dispatch(ActionsApp.setIsync(body));
        },
        setNotification: (body) =>
        {
            dispatch(ActionsApp.setNotification(body));
        },
    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Products);