import * as Types from "./../constants/TypesactionWeb";
import { eraseCookie } from "./../service";

let Initstate = {


    listTable: [],
    ListRoute: [],
    ListMenusSearch: [],
    Order: {},
    InfoPackageActive: [],

    ListCategory: [],
    StoreCategory: "",
    ListMenuHome: [],
    StoreMenuHome: "",
    ListClassifys: [],
    StoreClassifys: "",
    ListInfoTransport: [],
    StoreInfoTransport: "",
    ListNumberOfPage: [],
    StoreNumberOfPage: "",
    ListRegion: [],
    StoreRegion: "",
    ListRole: [],
    StoreRole: "",
    ListStore: [],
    StoreStore: "",
    ListTags: [],
    StoreTags: "",
    ListTradeMark: [],
    StoreTradeMark: "",
    ListTypeProducts: [],
    StoreTypeProducts: "",
    ListWarehouse: [],
    StoreWarehouse: "",
    ListWarrantyType: [],
    StoreWarrantyType: "",
    ListOrganizationProduction: [],
    StoreOrganizationProduction: "",
    ListOrganizationProductionAddress: [],
    StoreOrganizationProductionAddress: "",
    ListPaperTexture: [],
    StorePaperTexture: "",
    ListProducts: [],
    StoreProducts: "",
    StoreProductsMenuHome: "",
    ListInfoPackage: [],
    StoreInfoPackage: "",
    ListAttributeProducts: [],
    StoreAttributeProducts: "",
    ListSupplier: [],
    StoreSupplier: "",
    ListFunct: [],
    StoreFunct: "",
    ListUsers: [],
    StoreUsers: "",
    ListMenus: [],
    StoreMenus: "",
    ListInventory: [],
    StoreInventory: "",
    ListInventoryCheck: [],
    StoreInventoryCheck: "",
    ListOrders: [],
    StoreOrders: "",
    ListTiktokOrders: [],
    StoreTiktokOrders: "",
    ListStatusTransport: [],
    StoreStatusTransport: "",
    ListStatusTransportType: [],
    StoreStatusTransportType: "",
    ListCustomers: [],
    StoreCustomers: "",
    ListVouchers: [],
    StoreVouchers: "",
    ListVouchersGroup: [],
    StoreVouchersGroup: "",
    CheckVouchers: "",
    getCondisCustomer: "",
    ListProvinces: [],
    StoreProvinces: "",
    ListDistricts: [],
    StoreDistricts: "",
    ListWars: [],
    StoreWars: "",
    ListPosts: [],
    StorePosts: "",
    ListSettings: [],
    StoreSettings: "",
    SettingsShowByCode: "",
    ListBanners: [],
    StoreBanners: "",
    ListPages: [],
    StorePages: "",
    PagesGetView: [],
    PagesGetViewComponent: [],

    ListCollections: [],
    StoreCollections: "",
    CollectionsProducts: [],
    ListCollectionsProducts: [],
    ListFeedback: [],
    StoreFeedback: "",
    ListContacts: [],
    StoreContacts: "",
    DeleteCategory: "",
    UploadFileLa: "",
    Login: "",
    Logout: "",
    SetUser: "",


    ListEcommerceConfig: [],
    StoreEcommerceConfig: "",
    ListEcommerceConfigByCode: {},
    ListEcommerceConfigById: {},

    ResfeshEcommerceConfig: {},


    ListEcommerceType: [],
    StoreEcommerceType: "",
    ListEcommerceTypeByCode: {},
    ListEcommerceTypeById: {},
    syncProducts: [],
    syncProductsToTiktok: null,

    SyncTiktokOrders: [],

    productsData_: [
        "ListCategory",
        "ListClassifys",
        "ListInfoTransport",
        "ListStore",
        "ListTags",
        "InfoPackageActive",
        "ListAttributeProducts",


    ],
    getPdf: "",
    getPdfDownload: "",
    getPdfStream: "",

    getViewDataColection: "",
    Reports: {},


    ListProductsWarehouse: [],
    StoreProductsWarehouse: "",

    
    ListNetworkHome: [],
    StoreNetworkHome: "",

    ListNetworkHomeOperator: [],
    StoreNetworkHomeOperator: "",

    ListUnit: [],
    StoreUnit: "",

    ListSortStamps: [],
    StoreSortStamps: "",


    ListPaperType: [],
    StorePaperType: "",

    ListPaperPriceList: [],
    StorePaperPriceList: "",

    ListPaperAmountAndDiscount: [],
    StorePaperAmountAndDiscount: "",

    TitokCategories: "",
    TitokBrands: "",
    TiktokAtttributes: "",
    createCustomBrandTitok: "",
    ListAttributeByProducts: "",
    
    LazadaCategories: "",
    LazadaBrands: "",
    LazadaAtttributes: "",
    createCustomBrandLazada: "",
    ListAttributeByProducts: "",
    syncProductsLazada: [],
    syncProductsToLazada: null,
    getListCache: [],
    resetCache: null,

};
console.log('====================================');
console.log("Initstate", Initstate);
console.log('====================================');
const reducer = (state = Initstate, action) => {
    switch (action.type) {
        case Types.SYNC_PRODUCTS_TO_LAZADA:
                
        state = {
            ...state,
            syncProductsToLazada: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.syncProductsToLazada,
        };
        return { ...state };
        case Types.LIST_LAZADA_CATEGORIES:
                
        state = {
            ...state,
            LazadaCategories: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.LazadaCategories,
        };
        return { ...state };
        case Types.LIST_LAZADA_BRANDS:
                
        state = {
            ...state,
            LazadaBrands: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.LazadaBrands,
        };
        return { ...state };
        case Types.LIST_LAZADA_ATTRIBUTES:
                
        state = {
            ...state,
            LazadaAtttributes: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.LazadaAtttributes,
        };
        return { ...state };
        case Types.RESET_CACHE:

        state = {
            ...state,
            resetCache: action.result
        };
        return { ...state };
        case Types.GET_CACHE_KEY:

        state = {
            ...state,
            getListCache: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.getListCache,
        };
        return { ...state };
        case Types.REPORTS:

            state = {
                ...state,
                Reports: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.Reports,
            };
            return { ...state };
            case Types.LIST_ATTRIBUTE_BY_PRODUCTS:
               
            state = {
                ...state,
                ListAttributeByProducts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListAttributeByProducts,
            };
            return { ...state };

            case Types.CREATE_CUSTOM_BRAND_TITOK:
                
            state = {
                ...state,
                createCustomBrandTitok: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.createCustomBrandTitok,
            };
            return { ...state };
            case Types.LIST_TIKTOK_ATTRIBUTES:
                
            state = {
                ...state,
                TiktokAtttributes: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.TiktokAtttributes,
            };
            return { ...state };

            case Types.LIST_TIKTOK_BRANDS:
                
            state = {
                ...state,
                TitokBrands: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.TitokBrands,
            };
            return { ...state };

            case Types.LIST_TIKTOK_CATEGORIES:
                
            state = {
                ...state,
                TitokCategories: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.TitokCategories,
            };
            return { ...state };

            case Types.LIST_PAPER_AMOUT_AND_DISCOUNT:
                
            state = {
                ...state,
                ListPaperAmountAndDiscount: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListPaperAmountAndDiscount,
            };
            return { ...state };
        case Types.STORE_PAPER_AMOUT_AND_DISCOUNT:
            state = {
                ...state,
                StorePaperAmountAndDiscount: action.result,
            };
            return { ...state };

            case Types.LIST_PAPER_PRICE_LIST:
                
            state = {
                ...state,
                ListPaperPriceList: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListPaperPriceList,
            };
            return { ...state };
        case Types.STORE_PAPER_PRICE_LIST:
            state = {
                ...state,
                StorePaperPriceList: action.result,
            };
            return { ...state };

            case Types.LIST_PAPER_TYPE:
                
            state = {
                ...state,
                ListPaperType: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListPaperType,
            };
            return { ...state };
        case Types.STORE_PAPER_TYPE:
            state = {
                ...state,
                StorePaperType: action.result,
            };
            return { ...state };


            case Types.LIST_SORT_STAMPS:
                
            state = {
                ...state,
                ListSortStamps: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListSortStamps,
            };
            return { ...state };
        case Types.STORE_SORT_STAMPS:
            state = {
                ...state,
                StoreSortStamps: action.result,
            };
            return { ...state };


            case Types.LIST_UNIT:
                resetFirstdata("ListUnit",action.result.data);
            state = {
                ...state,
                ListUnit: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListUnit,
            };
            return { ...state };
        case Types.STORE_UNIT:
            state = {
                ...state,
                StoreUnit: action.result,
            };
            return { ...state };

          case Types.LIST_NETWORK_HOME_OPERATOR:

            state = {
                ...state,
                ListNetworkHomeOperator: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListNetworkHomeOperator,
            };
            return { ...state };
        case Types.STORE_NETWORK_HOME_OPERATOR:
            state = {
                ...state,
                StoreNetworkHomeOperator: action.result,
            };
            return { ...state };


            case Types.LIST_NETWORK_HOME:

            state = {
                ...state,
                ListNetworkHome: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListNetworkHome,
            };
            return { ...state };
        case Types.STORE_NETWORK_HOME:
            state = {
                ...state,
                StoreNetworkHome: action.result,
            };
            return { ...state };

        case Types.LIST_PRODUCTS_WAREHOUSE:

            state = {
                ...state,
                ListProductsWarehouse: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListProductsWarehouse,
            };
            return { ...state };
        case Types.STORE_PRODUCTS_WAREHOUSE:
            state = {
                ...state,
                StoreProductsWarehouse: action.result,
            };
            return { ...state };
        case Types.SYNC_TIKTOK_ORDERS:

            state = {
                ...state,
                SyncTiktokOrders: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.SyncTiktokOrders,
            };
            return { ...state };
        case Types.SYNC_PRODUCTS_TO_TIKTOK:

            state = {
                ...state,
                syncProductsToTiktok: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.syncProductsToTiktok,
            };
            return { ...state };
        case Types.SYNC_PRODUCTS_TIKTOK:

            state = {
                ...state,
                syncProducts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.syncProducts,
            };
            return { ...state };

        case Types.LIST_ECOMMERCE_TYPE_BY_CODE:

            state = {
                ...state,
                ListEcommerceTypeByCode: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListEcommerceTypeByCode,
            };
            return { ...state };
        case Types.LIST_ECOMMERCE_TYPE_BY_ID:

            state = {
                ...state,
                ListEcommerceTypeById: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListEcommerceTypeById,
            };
            return { ...state };
        case Types.LIST_ECOMMERCE_TYPE:

            state = {
                ...state,
                ListEcommerceType: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListEcommerceType,
            };
            return { ...state };
        case Types.STORE_ECOMMERCE_TYPE:
            state = {
                ...state,
                StoreEcommerceType: action.result,
            };
            return { ...state };


        case Types.REFRESH_ECOMMERCE:

            state = {
                ...state,
                ResfeshEcommerceConfig: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ResfeshEcommerceConfig,
            };
            return { ...state };
        case Types.LIST_ECOMMERCE_BY_CODE:

            state = {
                ...state,
                ListEcommerceConfigByCode: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListEcommerceConfigByCode,
            };
            return { ...state };
        case Types.LIST_ECOMMERCE_BY_ID:

            state = {
                ...state,
                ListEcommerceConfigById: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListEcommerceConfigById,
            };
            return { ...state };
        case Types.LIST_ECOMMERCE:

            state = {
                ...state,
                ListEcommerceConfig: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListEcommerceConfig,
            };
            return { ...state };
        case Types.STORE_ECOMMERCE:
            state = {
                ...state,
                StoreEcommerceConfig: action.result,
            };
            return { ...state };
        case Types.GET_PDF_DOWLOAD:

            state = {
                ...state,
                getPdfDownload: action.result,
            };
            return { ...state };
        case Types.GET_PDF_STREAM:

            state = {
                ...state,
                getPdfStream: action.result,
            };
            return { ...state };
        case Types.GET_PDF:

            state = {
                ...state,
                getPdf: action.result,
            };
            return { ...state };
        case Types.LIST_FEEDBACK:

            state = {
                ...state,
                ListFeedback: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListFeedback,
            };
            return { ...state };
        case Types.STORE_FEEDBACK:
            state = {
                ...state,
                StoreFeedback: action.result,
            };
            return { ...state };
        case Types.LIST_CONTACT:

            state = {
                ...state,
                ListContacts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListContacts,
            };
            return { ...state };
        case Types.STORE_CONTACT:
            state = {
                ...state,
                StoreContacts: action.result,
            };
            return { ...state };
        case Types.LIST_COLLECTIONS_PRODUCTS:

            state = {
                ...state,
                ListCollectionsProducts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListCollectionsProducts,
            };
            return { ...state };
        case Types.SEARCH_COLLECTIONS_PRODUCTS:

            state = {
                ...state,
                CollectionsProducts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.CollectionsProducts,
            };
            return { ...state };
        case Types.LIST_COLLECTIONS:

            state = {
                ...state,
                ListCollections: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListCollections,
            };
            return { ...state };
        case Types.STORE_COLLECTIONS:
            state = {
                ...state,
                StoreCollections: action.result,
            };
            return { ...state };
        case Types.LIST_BANNERS:

            state = {
                ...state,
                ListBanners: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListBanners,
            };
            return { ...state };
        case Types.STORE_BANNERS:
            state = {
                ...state,
                StoreBanners: action.result,
            };
            return { ...state };
        case Types.LIST_SETTINGS:

            state = {
                ...state,
                ListSettings: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListSettings,
            };
            return { ...state };
        case Types.STORE_SETTINGS:
            state = {
                ...state,
                StoreSettings: action.result,
            };
            return { ...state };
        case Types.SETTINGS_BY_CODE:
            state = {
                ...state,
                SettingsShowByCode: action.result,
            };
            return { ...state };
        case Types.LIST_POSTS:

            state = {
                ...state,
                ListPosts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListPosts,
            };
            return { ...state };
        case Types.STORE_POSTS:
            state = {
                ...state,
                StorePosts: action.result,
            };
            return { ...state };
        case Types.GET_VIEW_COLECTION:
            console.log('====================================');
            console.log("action", action);
            console.log('====================================');
            state = {
                ...state,
                getViewDataColection: action.result,
            };
            return { ...state };
        case Types.LIST_PAGES:

            state = {
                ...state,
                ListPages: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListPages,
            };
            return { ...state };
        case Types.STORE_PAGES:
            state = {
                ...state,
                StorePages: action.result,
            };
            return { ...state };

        case Types.PAGES_GETVIEW:
            state = {
                ...state,
                PagesGetView: action.result,
            };
            return { ...state };
        case Types.PAGES_GETVIEW_COMPONENT:
            state = {
                ...state,
                PagesGetViewComponent: action.result,
            };
            return { ...state };

        case Types.LIST_WARS:

            state = {
                ...state,
                ListWars: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListWars,
            };
            return { ...state };
        case Types.STORE_WARS:
            state = {
                ...state,
                StoreWars: action.result,
            };
            return { ...state };
        case Types.LIST_DISTRICTS:

            state = {
                ...state,
                ListDistricts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListDistricts,
            };
            return { ...state };
        case Types.STORE_DISTRICTS:
            state = {
                ...state,
                StoreDistricts: action.result,
            };
            return { ...state };
        case Types.LIST_PROVINCES:

            state = {
                ...state,
                ListProvinces: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListProvinces,
            };
            return { ...state };
        case Types.STORE_PROVINCES:
            state = {
                ...state,
                StoreProvinces: action.result,
            };
            return { ...state };
        case Types.LIST_CUSTOMERS:

            state = {
                ...state,
                ListCustomers: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListCustomers,
            };
            return { ...state };
        case Types.STORE_CUSTOMERS:
            state = {
                ...state,
                StoreCustomers: action.result,
            };
            return { ...state };
        case Types.LIST_VOUCHERS_GROUP:

            state = {
                ...state,
                ListVouchersGroup: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListVouchersGroup,
            };
            return { ...state };
        case Types.STORE_VOUCHERS_GROUP:
            state = {
                ...state,
                StoreVouchersGroup: action.result,
            };
            return { ...state };
        case Types.LIST_VOUCHERS:

            state = {
                ...state,
                ListVouchers: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListVouchers,
            };
            return { ...state };
        case Types.STORE_VOUCHERS:
            state = {
                ...state,
                StoreVouchers: action.result,
            };
            return { ...state };
        case Types.CHECK_VOUCHERS:
            state = {
                ...state,
                CheckVouchers: action.result,
            };
            return { ...state };
        case Types.GET_CONDIS_CUS:
            state = {
                ...state,
                getCondisCustomer: action.result,
            };
            return { ...state };
        case Types.LIST_STATUS_TRANSPORT_TYPE:
            state = {
                ...state,
                ListStatusTransportType: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListStatusTransportType,
            };
            return { ...state };
        case Types.STORE_STATUS_TRANSPORT_TYPE:
            state = {
                ...state,
                StoreStatusTransportType: action.result,
            };
            return { ...state };
        case Types.LIST_STATUS_TRANSPORT:
            state = {
                ...state,
                ListStatusTransport: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListStatusTransport,
            };
            return { ...state };
        case Types.STORE_STATUS_TRANSPORT:
            state = {
                ...state,
                StoreStatusTransport: action.result,
            };
            return { ...state };
        case Types.LIST_TIKTOK_ORDERS:
            state = {
                ...state,
                ListTiktokOrders: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListTiktokOrders,
            };

            return { ...state };

        case Types.STORE_TIKTOK_ORDERS:
            state = {
                ...state,
                StoreTiktokOrders: action.result,
            };
            return { ...state };
        case Types.LIST_ORDERS:
            state = {
                ...state,
                ListOrders: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListOrders,
            };

            return { ...state };

        case Types.STORE_ORDERS:
            state = {
                ...state,
                StoreOrders: action.result,
            };
            return { ...state };
        case Types.ORDER_BY_ID:
            state = {
                ...state,
                Order: action.result,
            };
            return { ...state };
        case Types.LIST_INVENTORY_IN:
            state = {
                ...state,
                ListInventory: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListInventory,
            };

            return { ...state };

        case Types.STORE_INVENTORY_IN:
            state = {
                ...state,
                StoreInventory: action.result,
            };
            return { ...state };

        case Types.LIST_INVENTORY_OUT:
            state = {
                ...state,
                ListInventoryCheck: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListInventoryCheck,
            };

            return { ...state };

        case Types.STORE_INVENTORY_OUT:
            state = {
                ...state,
                StoreInventoryCheck: action.result,
            };
            return { ...state };
        case Types.LIST_MENUS:
            state = {
                ...state,
                ListMenus: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListMenus,
            };

            return { ...state };
        case Types.LIST_MENUS_SEARCH:
            state = {
                ...state,
                ListMenusSearch: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListMenusSearch,
            };

            return { ...state };
        case Types.STORE_MENUS:
            state = {
                ...state,
                StoreMenus: action.result,
            };
            return { ...state };
        case Types.LIST_ROUTE:
            state = {
                ...state,
                ListRoute: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListRoute,
            };

            return { ...state };
        case Types.LIST_USERS:
            state = {
                ...state,
                ListUsers: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListUsers,
            };

            return { ...state };

        case Types.STORE_USERS:
            state = {
                ...state,
                StoreUsers: action.result,
            };
            return { ...state };
        case Types.LIST_FUNCT:
            state = {
                ...state,
                ListFunct: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListAttributeProducts,
            };

            return { ...state };

        case Types.STORE_FUNCT:
            state = {
                ...state,
                StoreFunct: action.result,
            };
            return { ...state };
        case Types.LIST_ATTRIBUTE_PRODUCTS:
            resetFirstdata("ListAttributeProducts",action.result.data);
            state = {
                ...state,
                ListAttributeProducts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListAttributeProducts,
            };

            return { ...state };

        case Types.STORE_ATTRIBUTE_PRODUCTS:
          
            state = {
                ...state,
                StoreAttributeProducts: action.result,
            };
            return { ...state };
        case Types.LIST_SUPPLIER:
            state = {
                ...state,
                ListSupplier: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListSupplier,
            };

            return { ...state };
        case Types.STORE_SUPPLIER:
            state = {
                ...state,
                StoreSupplier: action.result,
            };
            return { ...state };
        case Types.STORE_MENUHOME:
            state = {
                ...state,
                StoreMenuHome: action.result,
            };
            return { ...state };

        case Types.LIST_MENUHOME:

            state = {
                ...state,
                ListMenuHome: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListMenuHome,
            };
            return { ...state };
        case Types.STORE_CATEGORY:
            state = {
                ...state,
                StoreCategory: action.result,
            };
            return { ...state };
        case Types.DELETE_CATEGORY:
            state = {
                ...state,
                DeleteCategory: action.result,
            };
            return { ...state };
        case Types.LIST_CATEGORY:

            state = {
                ...state,
                ListCategory: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListCategory,
            };
            return { ...state };
        case Types.LIST_CLASSIFYS:
            resetFirstdata("ListClassifys",action.result.data);
            state = {
                ...state,
                ListClassifys: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListClassifys,
            };
            return { ...state };
        case Types.STORE_CLASSIFYS:
            state = {
                ...state,
                StoreClassifys: action.result,
            };
            return { ...state };
        case Types.LIST_INFOTRANSPORT:
            state = {
                ...state,
                ListInfoTransport: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListInfoTransport,
            };
            return { ...state };
        case Types.STORE_INFOTRANSPORT:
            state = {
                ...state,
                StoreInfoTransport: action.result,
            };
            return { ...state };
        case Types.LIST_NUMBER_OF_PAGE:
            state = {
                ...state,
                ListNumberOfPage: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListNumberOfPage,
            };
            return { ...state };
        case Types.STORE_NUMBER_OF_PAGE:
            state = {
                ...state,
                StoreNumberOfPage: action.result,
            };
            return { ...state };
        case Types.LIST_REGION:
            state = {
                ...state,
                ListRegion: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListRegion,
            };
            return { ...state };
        case Types.STORE_REGION:
            state = {
                ...state,
                StoreRegion: action.result,
            };
            return { ...state };
        case Types.LIST_ROLE:
            state = {
                ...state,
                ListRole: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListRole,
            };
            return { ...state };
        case Types.STORE_ROLE:
            state = {
                ...state,
                StoreRole: action.result,
            };
            return { ...state };
        case Types.LIST_STORE:
            state = {
                ...state,
                ListStore: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListStore,
            };
            return { ...state };
        case Types.STORE_STORE:
            state = {
                ...state,
                StoreStore: action.result,
            };
            return { ...state };
        case Types.LIST_TAGS:
            resetFirstdata("ListTags",action.result.data);
            state = {
                ...state,
                ListTags: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListTags,
            };
            return { ...state };
        case Types.STORE_TAGS:
            state = {
                ...state,
                StoreTags: action.result,
            };
            return { ...state };
        case Types.LIST_TRADEMARK:
            state = {
                ...state,
                ListTradeMark: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListTradeMark,
            };
            return { ...state };
        case Types.STORE_TRADEMARK:
            state = {
                ...state,
                StoreTradeMark: action.result,
            };
            return { ...state };
        case Types.LIST_TYPE_PRODUCTS:
            state = {
                ...state,
                ListTypeProducts: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListTypeProducts,
            };
            return { ...state };
        case Types.STORE_TYPE_PRODUCTS:
            state = {
                ...state,
                StoreTypeProducts: action.result,
            };
            return { ...state };
        case Types.LIST_WAREHOUSE:
            state = {
                ...state,
                ListWarehouse: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListWarehouse,
            };
            return { ...state };
        case Types.STORE_WAREHOUSE:
            state = {
                ...state,
                StoreWarehouse: action.result,
            };
            return { ...state };
        case Types.LIST_WARRANTY_TYPE:
            state = {
                ...state,
                ListWarrantyType: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListWarrantyType,
            };
            return { ...state };
        case Types.STORE_WARRANTY_TYPE:
            state = {
                ...state,
                StoreWarrantyType: action.result,
            };
            return { ...state };

        case Types.LIST_ORGANIZATION_PRODUCTION:
            state = {
                ...state,
                ListOrganizationProduction: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListOrganizationProduction,
            };
            return { ...state };
        case Types.STORE_ORGANIZATION_PRODUCTION:
            state = {
                ...state,
                StoreOrganizationProduction: action.result,
            };
            return { ...state };
        case Types.LIST_ORGANIZATION_PRODUCTION_ADDRESS:
            state = {
                ...state,
                ListOrganizationProductionAddress: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListOrganizationProductionAddress,
            };
            return { ...state };
        case Types.LIST_INFOPACKAGE:
            state = {
                ...state,
                ListInfoPackage: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListInfoPackage,
            };
            return { ...state };
        case Types.INFOPACKAGE_ACTIVE:


            state = {
                ...state,
                InfoPackageActive: (action.result.code === 200 || action.result.code === 204) && action.result && action.result !== "null" ? action.result : state.InfoPackageActive,
            };
            return { ...state };
        case Types.STORE_INFOPACKAGE:
            state = {
                ...state,
                StoreInfoPackage: action.result,
            };
            return { ...state };
        case Types.STORE_ORGANIZATION_PRODUCTION_ADDRESS:
            state = {
                ...state,
                StoreOrganizationProductionAddress: action.result,
            };
            return { ...state };
        case Types.LIST_PAPER_TEXTURE:
            state = {
                ...state,
                ListPaperTexture: (action.result.code === 200 || action.result.code === 204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListPaperTexture,
            };
            return { ...state };
        case Types.STORE_PAPER_TEXTURE:
            state = {
                ...state,
                StorePaperTexture: action.result,
            };
            return { ...state };

        case Types.LIST_PRODUCTS:
            // console.log("nextProps.Web.ListProducts", action.result);
            state = {
                ...state,
                ListProducts: action.result.data && action.result.data !== "null" ? action.result.data : state.ListProducts,
            };
            return { ...state };
        // case Types.SEACRH_PRODUCTS:

        //     state = {
        //         ...state,
        //         ListProducts: (action.result.code === 200 || action.result.code===204) && action.result.data && action.result.data !== "null" ? action.result.data : state.ListProducts,
        //     };
        //     return { ...state };
        case Types.STORE_PRODUCTS:
            state = {
                ...state,
                StoreProducts: action.result,
            };
            return { ...state };
        case Types.STORE_PRODUCTS_MENU_HOME:
            state = {
                ...state,
                StoreProductsMenuHome: action.result,
            };
            return { ...state };
        case Types.LOGIN_WEB:

            state = {
                ...state,
                Login: action.result,
            };
            return { ...state };
        case Types.LOGOUT_WEB:

            state = {
                ...state,
                Logout: action.result,
            };



            return { ...state };
        case Types.SET_USER:
            state = {
                ...state,
                SetUser: action.result,
            };
            return { ...state };
        case Types.UPLOAD_FILE_LA:
            state = {
                ...state,
                UploadFileLa: action.result,
            };
            return { ...state };
        default:
            return { ...state };


    }
};

const setLocalpage = (data) => {
    localStorage.setItem("pageToken", JSON.stringify(data))
}
const resetFirstdata = (x,data) => {
    let productsData = localStorage.getItem("productsData");

    if (productsData) {
      productsData = JSON.parse(productsData);
    //   if (!productsData[x]?.data) {
        productsData[x] = data;
        localStorage.setItem("productsData", JSON.stringify(productsData));
    //   }

    } else {
      localStorage.setItem("productsData", JSON.stringify({ [x]: data }));
    }

}
const pushLocalpage = (data) => {
    let pageToken = localStorage.getItem("pageToken");
    if (pageToken) {
        pageToken = JSON.parse(pageToken);
    }
    else {
        pageToken = [];
    }
    for (let i = 0; i < pageToken.length; i++) {
        const element = pageToken[i];
        if (element.ID !== data.ID) {
            pageToken.push(data);
        }
        else {
            pageToken[i] = data;
        }

    }
    localStorage.setItem("pageToken", JSON.stringify(pageToken))
}
const mapData = (data) => {
    console.log('====================================');
    console.log(data);
    console.log('====================================');
    let arrtmp = [];
    data.data.map((item, index) => {
        item["value"] = item.id;
        item["lable"] = item.name;
        arrtmp["value"] = (item.id).toString();
        arrtmp["lable"] = (item.name).toString();
    });
    return arrtmp;
}
export default reducer;
