import { Divider, Radio, Table, Pagination, Affix, Input, Button, Modal, Image, Tag, Switch as SwitchA, message, DatePicker, Tooltip, Form, Select, TreeSelect, Checkbox, Avatar, Card, Row, Col, InputNumber, Collapse, Tabs, Popover, Alert, Dropdown, Menu, Empty, List, Skeleton } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import * as LINK from "../../../../../../constants/Links";
import { CutString, checkScreenSize, formatMN1 } from "../../../../../../service";
import {
DownOutlined, CarOutlined, DiffOutlined, DeleteOutlined, GiftOutlined, SafetyOutlined, CheckOutlined

} from '@ant-design/icons';
import { arrColors } from '../../../../../../constants/Colors';
import * as Colors from '../../../../../../constants/Colors';
import { ExportBillVatMultipleRequest } from '../../../../../../actions/indexWeb';
const count = 3;

const App = ({ selectedRows, selectedRowKeys }) => {
    console.log("selectedRowsselectedRows ", selectedRows);

    const [initLoading, setInitLoading] = useState(true);
    const [loading, setLoading] = useState(false);
    const [newRowsSelected, setnewRowsSelected] = useState([]);
    const [list, setList] = useState([]);
    const [vat, setVat] = useState(8);
    const [Totalvat, setTotalvat] = useState(0);
    const [TotalProduct, setTotalProduct] = useState(0);
    const [TotalAmount, setTotalAmount] = useState(0);
    const [TotalPrice, setTotalPrice] = useState(0);
    const [TotalPriceWithoutVat, setTotalPriceWithoutVat] = useState(0);
    const formRefInfoBill = useRef(null);
    const exportBillGroup = async (values) => {
        console.log("valuesvalues ", values);

        const response = await ExportBillVatMultipleRequest({ ids: selectedRowKeys, type: 2, ...values });
        if (response) {
            const result = await response.json();
            if (result.code !== 200) {
                message.warning(result.message)

            } else {
                message.success(result.message)

                //    if(this.formRef.current){
                //     this.formRef.current.submit();
                //    }
            }
            console.log('result:', result);
        }
    }

    useEffect(() => {
        const newRows = [];
        let totalvat_ = 0;
        let totalPrice_ = 0;
        let totalPriceWithoutVat_ = 0;
        let totalPoducts_ = 0;
        let totalAmount_ = 0;
        if (selectedRows) {
            selectedRows.map((value, index) => {
                // if(value.status=="COMPLETED"){
                const newItem = {};
                if (value.line_items) {

                    value.line_items.map((item, i) => {
                        let tmpItem = item;
                        if (item.original_price > 0) {
                            if (newRows[item.product_id_ + item.sku_id]) {
                                tmpItem = newRows[item.product_id_ + item.sku_id];
                                tmpItem.amount += 1;
                                // newItem[item.product_id_].price_without_vat_=(Number(newItem[item.product_id_].original_price)-Number(newItem[item.product_id_].seller_discount))/(1 + vat / 100);
                                // newItem[item.product_id_].vat_price=(newItem[item.product_id_].amount * (newItem[item.product_id_].price_without_vat_ * (vat / 100)))
                                // newItem[item.product_id_].total_price_without_vat_=(newItem[item.product_id_].amount * newItem[item.product_id_].price_without_vat_)
                                // newItem[item.product_id_].total_price=(newItem[item.product_id_].total_price_without_vat_ + newItem[item.product_id_].vat_price)
                                // totalvat_+=newItem[item.product_id_].vat_price
                                // totalPrice_+=newItem[item.product_id_].total_price
                                // totalPriceWithoutVat_+=newItem[item.product_id_].total_price_without_vat_
                            }
                            else {
                                tmpItem.amount = 1;


                            }
                            tmpItem.price_without_vat_ = (Number(tmpItem.original_price) - Number(tmpItem.seller_discount)) / (1 + vat / 100);
                            tmpItem.vat_price = (tmpItem.amount * (tmpItem.price_without_vat_ * (vat / 100)))
                            tmpItem.total_price_without_vat_ = (tmpItem.amount * tmpItem.price_without_vat_)
                            tmpItem.total_price = (tmpItem.total_price_without_vat_ + tmpItem.vat_price)
                            newRows[tmpItem.product_id_ + tmpItem.sku_id] = tmpItem;


                            totalvat_ += tmpItem.vat_price
                            totalPrice_ += tmpItem.total_price
                            totalPriceWithoutVat_ += tmpItem.total_price_without_vat_
                        }


                    })

                }
                // }

                // value.line_items=Object.values(newItem);
                // newRows.push(value);
            })
            setnewRowsSelected([...Object.values(newRows)]);
            setTotalvat(totalvat_);
            setTotalPrice(totalPrice_);
            setTotalPriceWithoutVat(totalPriceWithoutVat_);
            Object.values(newRows).map((value, index) => {
                totalPoducts_++;
                totalAmount_ += value.amount;
            })
            setTotalProduct(totalPoducts_);
            setTotalAmount(totalAmount_);
        }
        return () => {

        };
    }, [selectedRows]);
    console.log("newRowsSelectednewRowsSelected ", newRowsSelected);
    return (
        <div className="modal_content_ h-100">
            <Form name="TiktokOrdersearch" onFinish={exportBillGroup} ref={formRefInfoBill}

                style={{ padding: 0 }}
                labelAlign={"right"}
            >
                <div className="row">
                    <div className="col_ col-lg-7 col-md-6 col-sm-6">
                        <div className="row">

                            <div className="col_ col-lg-12 col-md-12 col-sm-12">

                                <Form.Item name="cus_name" label="Tên khách hàng" style={{
                                    width: "100%"
                                }}>
                                    <Input placeholder="Tên khách hàng" style={{ width: "100%" }} />
                                </Form.Item>

                            </div>
                            <div className="col_ col-lg-12 col-md-12 col-sm-12">

                                <Form.Item name="company_name" label="Tên công ty" style={{
                                    width: "100%"
                                }}>
                                    <Input placeholder="Tên công ty" style={{ width: "100%" }} />
                                </Form.Item>

                            </div>
                            <div className="col_ col-lg-12 col-md-12 col-sm-12">
                                <Form.Item label="Số điện thoại/Email" style={{ width: "100%" }} >
                                    <Input.Group compact>
                                        <Form.Item name="phone_number" noStyle >
                                            <Input placeholder="Số điện thoại" style={{ width: "50%" }} />
                                        </Form.Item>
                                        <Form.Item name="email" noStyle >
                                            <Input placeholder="Email" style={{ width: "50%" }} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>

                            </div>

                            <div className="col_ col-lg-12 col-md-12 col-sm-12">

                                <Form.Item name="address" label="Địa chỉ" style={{
                                    width: "100%"
                                }}>
                                    <Input placeholder="Địa chỉ" style={{ width: "100%" }} />
                                </Form.Item>

                            </div>

                            <div className="col_ col-lg-12 col-md-12 col-sm-12">
                                <Form.Item label="Số TKNH/Tên TKNH" style={{ width: "100%" }} >
                                    <Input.Group compact>
                                        <Form.Item name="bank_name" noStyle >
                                            <Input placeholder="Tên TKNH" style={{ width: "50%" }} />
                                        </Form.Item>
                                        <Form.Item name="bank_num" noStyle>
                                            <Input placeholder="Số TKNH" style={{ width: "50%" }} />
                                        </Form.Item>
                                    </Input.Group>
                                </Form.Item>
                            </div>
                            {/* <div className="col_ col-lg-12 col-md-12 col-sm-12">

                                <Form.Item name="note" label="Ghi chú" style={{
                                    width: "100%"
                                }}>
                                    <Input placeholder="Ghi chú" style={{ width: "100%" }} />
                                </Form.Item>

                            </div> */}
                            <div className="col_ col-lg-12 col-md-12 col-sm-12">

                                <Button type="primary" htmlType="submit">Gửi</Button>
                            </div>
                        </div>
                    </div>

                    <div className="col_ col-lg-5 col-md-6 col-sm-6 ps-2">
                        <div style={{ height: "500px", overflowY: "scroll" }} className='bg-light p-2'>
                            {newRowsSelected && newRowsSelected.map((item, index) => {

                                return <div className='item_product p-2 bg-white mb-2 btn '>

                                    <div className='text-start text-primary' style={{ fontSize: "14px" }}>  {item.product_name} -  {item.sku_id}</div>
                                    <Divider />
                                    <div className='d-flex justify-content-between  align-content-center' >
                                        <div className='d-flex flex-column justify-content-start' style={{ fontSize: "12px" }}>
                                            <span>Số lượng</span>
                                            <span>{Number(item.amount)}</span>

                                        </div>
                                        <div className='d-flex flex-column justify-content-start' style={{ fontSize: "12px" }}>
                                            <span>Đơn giá</span>
                                            <span>{formatMN1(item.price_without_vat_)}</span>

                                        </div>
                                        <div className='d-flex flex-column justify-content-start' style={{ fontSize: "12px" }}>
                                            <span>Thành tiền</span>
                                            <span>{formatMN1(item.total_price_without_vat_)}</span>

                                        </div>

                                        <div className='d-flex flex-column justify-content-start' style={{ fontSize: "12px" }}>
                                            <span>VAT</span>
                                            <span>{vat + "%"} </span>

                                        </div>
                                        <div className='d-flex flex-column justify-content-start' style={{ fontSize: "12px" }}>
                                            <span>Tiền VAT</span>
                                            <span>{formatMN1(item.vat_price)}</span>

                                        </div>
                                        <div className='d-flex flex-column justify-content-start text-danger' style={{ fontSize: "12px" }}>
                                            <span>Tổng tiền</span>
                                            <span>{formatMN1(item.total_price)}</span>

                                        </div>
                                    </div>


                                </div>
                            })}
                        </div>


                    </div>

                    <div className="col_ col-lg-7 col-md-6 col-sm-6"></div>
                    <div className="col_ col-lg-5 col-md-6 col-sm-6  d-flex justify-content-between">
                        <div className='p-4'>
                            <div className='d-flex justify-content-start justify-content-start mb-2' >
                                <span className='fw-bold text-primary me-2'>Tổng sản phẩm: </span>
                                <span className='fw-bold'>{formatMN1(TotalProduct)} </span>
                            </div>
                            <div className='d-flex justify-content-start justify-content-start mb-2' >
                                <span className='fw-bold text-primary me-2'>Tổng số lượng: </span>
                                <span className='fw-bold'>{formatMN1(TotalAmount)} </span>
                            </div>
                           
                        </div>
                        <div className='p-4'>
                            <div className='d-flex justify-content-end justify-content-start mb-2' >
                                <span className='fw-bold text-primary me-2'>Tổng tiền hàng: </span>
                                <span className='fw-bold'>{formatMN1(TotalPriceWithoutVat)} </span>
                            </div>
                            <div className='d-flex justify-content-end justify-content-start  mb-2' >
                                <span className='fw-bold text-warning me-2'>Tổng tiền vat: </span>
                                <span className='fw-bold'>{formatMN1(Totalvat)} </span>
                            </div>
                            <div className='d-flex justify-content-end justify-content-start  mb-2' >
                                <span className='fw-bold text-danger me-2'>Tổng tiền: </span>
                                <span className='fw-bold'>{formatMN1(TotalPrice)} </span>
                            </div>
                        </div>

                    </div>
                </div>

            </Form>
        </div>
    );
};
export default App;