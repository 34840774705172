import React from 'react'
import { Link } from "react-router-dom";

import { withRouter } from "react-router-dom";
import * as Colors from '../../constants/Colors';
import { linkWeb } from '../../constants/LinkApi';
import * as LINK from "../../constants/Links";
import {
CaretLeftOutlined, MenuUnfoldOutlined, MenuFoldOutlined,
ShopOutlined,
UserOutlined,
PieChartOutlined,
DesktopOutlined, LogoutOutlined,
MailOutlined,
AppstoreAddOutlined, SmileOutlined,
LoadingOutlined, RetweetOutlined
} from '@ant-design/icons';
import { Spin } from 'antd';
import { useEffect } from 'react';
import { formatMN1, getCookie } from '../../service';
import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { LogoutRequest, SetUserRequest } from '../../actions/login';
import { SettingsShowByCodeRequest } from '../../actions/indexWeb';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { isloader, toggleColapNav } from '../../actions/indexApp';
import * as Types from "../../constants/TypesactionApp";

export const TotalBottom = ({ groupReport }) => {

  const { Web,appReducer } = useSelector(state => state);
  console.log("groupReportgroupReportgroupReport ", groupReport);

  return (
    <div className={`main-grid  total-bottom  bg-white p-2 ${appReducer.active ? " hide" : ""} w-100`}>
      <div></div>
      <div className=" d-flex justify-content-between  ">
        <div className='me-2 text-secondary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Tổng đơn: </span> <span>{(groupReport?.totalAmount)} </span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT gốc:</span> <span>{formatMN1(groupReport?.totalPriceOrigin)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá sàn:</span> <span>{formatMN1(groupReport?.totalPlatformDiscount)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá shop:</span> <span>{formatMN1(groupReport?.totalSellerDiscount)}</span></div>
        <div className='me-2 text-info fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT sau giảm giá:</span> <span>{formatMN1(groupReport?.totalAfterDiscount)}</span></div>


        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT ship gốc: </span><span>{formatMN1(groupReport?.totalFreeShipOrigin)}</span></div>
        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá  sàn: </span><span>{formatMN1(groupReport?.totalFreeShipPlatformDiscount)}</span></div>
        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>Giảm giá shop: </span><span>{formatMN1(groupReport?.totalFreeShipSellerDiscount)}</span></div>
        <div className='me-2 text-primary fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT ship: </span><span>{formatMN1(groupReport?.totalFreeShip)}</span></div>


        <div className='me-2 text-success fw-bold d-flex flex-column' style={{ fontSize:"12px" }}><span>TT đơn hàng: </span><span>{formatMN1(groupReport?.totalPrice)}</span></div>


      </div>
    </div>

  )
}
