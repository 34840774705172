import React, { useEffect, useRef, useState } from 'react';
import Draggable from 'react-draggable';
import './style.css';
import { Divider, Radio, Table,Cascader, Space, List, Skeleton, Drawer, Button, Input, Pagination, Modal, message, Switch as SwitchA, Form, Card, TreeSelect, Select, Tag, Avatar, Dropdown, Menu, Popover, DatePicker, Image, Checkbox } from 'antd';
import
{
    SearchOutlined,
    CaretDownOutlined
} from '@ant-design/icons';
import * as Services from './../../service';
import * as Colors from './../../constants/Colors';
import { v4 as uuidv4 } from 'uuid';
import * as LINK from './../../constants/Links';
import { useDispatch, useSelector } from 'react-redux';
import { META } from "./../../utils/constant";

import * as ActionsWeb from "./../../actions/indexWeb";
import { Link } from "react-router-dom";
import moment from 'moment';
import ItemProduct from "./../itemProduct/index";
// import { useLocation } from "react-router-dom";
const { RangePicker } = DatePicker;
const count = 3;
const Index = (props) =>
{
    // let location=useLocation();
    const dispatch = useDispatch()
    const { Web } = useSelector(state => state);
    const [meta, setMeta] = useState({ ...META });
    const [isLoadingSearch, SetisLoadingSearch] = useState(false);
    const [productsList, setproductsList] = useState([]);
    const [ListProducts, setListProducts] = useState([]);
    const [data, setdata] = useState([]);
    const [dataList, setdataList] = useState([]);
    const [ListTags, setListTags] = useState([]);
    const [ListCategory, setListCategory] = useState([]);
    const formSearchRef = new useRef(null)
    const [isloading, setisloading] = useState(false);
    const [isFirst, setisFirst] = useState(false);
    const [selectedRowKeys1, SetselectedRowKeys1] = useState([]);
    const [selectedRows1, SetselectedRows1] = useState([]);
    const [indeterminate, setIndeterminate] = useState(true);
    const [checkAll, setCheckAll] = useState(false)
    const [columns, setColumns] = useState([
        {
            title: 'STT',
            dataIndex: 'key',


        },
        {
            title: 'Slug',
            dataIndex: 'slug',

            filterSearch: true,


        },
        {
            title: 'Tên',
            dataIndex: 'name',

            filterSearch: true,
        },

        {
            title: 'ngày tạo',
            dataIndex: 'created_at',
            

        },
        {
            title: 'Ngày cập nhật',
            dataIndex: 'updated_at',
            
        },
        {
            title: 'Hành động',
            dataIndex: 'action',
            width: "20%",
         
        },
    ],);
    const [initLoading, setInitLoading] = useState(true);
    const setProductsSeleted = (selectedRows) =>
    {

        const result = selectedRows.filter(itemA => !productsList.some(itemB => (1 * itemB.id) === (1 * itemA.id)));
        setproductsList([...productsList, ...result]);

    }
 
    const [loading, setLoading] = useState(false);
    const [list, setList] = useState([]);
    useEffect(() =>
    {
     
        if (ListProducts.data && ListProducts.data.length>0){
           
         
            data_()
        
        }
        //  else if (ListProducts.data.length <= 0){
        //     message.warn("không tìm thấy sản phẩm!");
        //     return
        // }
        
        SetisLoadingSearch(false)
      

        return () =>
        {

        };
    }, [ListProducts]);
    useEffect(() =>
    {   
       
        // console.log("Web.ListProductsWeb.ListProductsWeb.ListProductsWeb.ListProductsWeb.ListProducts ",Web.ListProducts);
        
        setListProducts(Web.ListProducts);
      
        return () =>
        {

        };
    }, [Web.ListProducts]);
    useEffect(() =>
    {
      
        if(Web.ListCategory.data){
            setListCategory(Web.ListCategory.data);
        }
       

        return () =>
        {

        };
    }, [Web.ListCategory.data]);
    useEffect(() =>
    {
       
        if(Web.ListTags.data){
            setListTags(Web.ListTags.data);
            console.log("Web.ListTagsWeb.ListTags ",Web.ListTags.data);
        }
      

        return () =>
        {

        };
    }, [Web.ListTags.data]);
 
    useEffect(() =>
    {
        
        try {
          
            dispatch(ActionsWeb.SearchProductsRequest({
                limit: meta.per_page/10,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }, { data: {isdetail:true} }))
            dispatch(ActionsWeb.ListCategoryRequest({
                limit: meta.per_page * 10,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }))
            dispatch(ActionsWeb.ListTagsRequest({
                limit: meta.per_page * 10,
                page: meta.current_page, column: meta.column, sort: meta.sort
            }))

            setisFirst(true)
        } catch (error) {

        }
        return () =>
        {

        };
    }, []);
    const onSearch = (values) =>
    {
        console.log(values);
        
        SetisLoadingSearch(true)


        const filteredvalues = Object.fromEntries(
            Object.entries(values).filter(([_, value]) => { return value !== undefined && value !== "" })
        );

        if (filteredvalues.created_at) {
            filteredvalues.fromdate = filteredvalues.created_at[0].format("YYYY-MM-DD")
            filteredvalues.todate = filteredvalues.created_at[1].format("YYYY-MM-DD")
        }
     
        dispatch(ActionsWeb.SearchProductsRequest({
            limit: meta.per_page * 5,
            page: meta.current_page, column: meta.column, sort: meta.sort
        }, { data: {...filteredvalues,isdetail:true} }))

        setdataList([]);
        setList([]);


    }
   
    
    const [isMetaUpdated, setIsMetaUpdated] = useState(false);
    const onResetFrom = () =>
    {
        formSearchRef.current.resetFields();
        setdataList([]);
        setList([]);
        const meta_={...META}
        SetisLoadingSearch(true)

        dispatch(ActionsWeb.SearchProductsRequest({
            limit: meta_.per_page/10,
            page: meta_.current_page, column: meta_.column, sort: meta_.sort
        }, { data: {isdetail:true} }))
    }
    // useEffect(() => {
    //     dispatch(ActionsWeb.SearchProductsRequest({
    //         limit: meta.per_page/10,
    //         page: meta.current_page, column: meta.column, sort: meta.sort
    //     }, { data: {isdetail:true} }))
    // }, [meta, isMetaUpdated]); // Theo dõi cả meta và isMetaUpdated
    const data_ = () =>
    {


        try {


            if (ListProducts.data.length <= 0) {
                return;
            }
          
            let data_ = [];
            let listfil = {};
            let meta_ = {
                ...meta,
                current_page: ListProducts.current_page,
                first_page_url: ListProducts.first_page_url,
                from: ListProducts.from,
                last_page: ListProducts.last_page,
                last_page_url: ListProducts.last_page_url,
                links: ListProducts.links,
                next_page_url: ListProducts.next_page_url,
                path: ListProducts.path,
                per_page: 1 * ListProducts.per_page,
                prev_page_url: ListProducts.prev_page_url,
                to: ListProducts.to,
                total: ListProducts.total,
            };
          
            let filtilte = [];
            let filcode = [];
            let filslug = [];
            let filprice = [];
            let fildiscount = [];
            let filCreatedate = [];
            let filUpdatedate = [];
            const dtPro = Object.values(ListProducts.data);
           
            dtPro.map((item, index) =>
            {
                filcode = [...filcode, {
                    text: item.code,
                    value: item.code,
                }]
                filtilte = [...filtilte, {
                    text: item.title,
                    value: item.title,
                }]
                filslug = [...filslug, {
                    text: item.slug,
                    value: item.slug,
                }]
                filprice = [...filprice, {
                    text: item.price,
                    value: item.price,
                }]
                fildiscount = [...fildiscount, {
                    text: item.discount,
                    value: item.discount,
                }]
                filCreatedate = [...filCreatedate, {
                    text: moment(item.created_at).format('DD/MM/YYYY'),
                    value: moment(item.created_at).format('DD/MM/YYYY'),
                }]

                filUpdatedate = [...filUpdatedate, {
                    text: moment(item.updated_at).format('DD/MM/YYYY'),
                    value: moment(item.updated_at).format('DD/MM/YYYY'),
                }]

                item.key = item.id

                data_.push(item)

            });
           
            const filhidden = [{
                text: "Hiển thị",
                value: 0,
            }, {
                text: "Ẩn",
                value: 1,
            }]
            listfil = { ...listfil, title: filtilte, hidden: filhidden, slug: filslug, price: filprice, created_at: filCreatedate, updated_at: filUpdatedate, discount: fildiscount, code: filcode }
            setColumn_(listfil);
            setdata(data_)
            setMeta(meta_)

            setInitLoading(false);
            setLoading(false);
            if(!props.isgroup){
                setDataClassify(data_)
                
            }else{
                if (isFirst || !props.isNewList){
                    setdataList(data_);
                    setList(data_);
                    setisFirst(false)
                   
                }else{
                  
                    
                    const newData = dataList.concat(data_);
                  
                    setdataList(newData);
                    setList(newData);
                }
               

            }
          
          
            window.dispatchEvent(new Event('resize'));
        } catch (error) {
            console.error(error);
        }
    }
 
    const setColumn_ = (filters) =>
    {
     
        setColumns([
                {
                    title: '#',
                    dataIndex: 'key',
                    width: "5%",
                    align: 'center',

                },
            {
                title: 'code',
                dataIndex: 'code',
                // filterMode: 'tree',
                filterSearch: true,
                filters: filters.code,
                onFilter: (value, record) =>
                {

                    return record.code.startsWith(value)

                },
                sorter: (a, b) => a.code - b.code,
              
            },
                {
                    title: 'Tiêu đề',
                    dataIndex: 'title',
                    // filterMode: 'tree',
                    filterSearch: true,
                    filters: filters.title,
                    onFilter: (value, record) =>
                    {

                        return record.title.startsWith(value)

                    },
                    sorter: (a, b) => a.title - b.title,
                    render: (text, record, index) =>
                    {

                        return <Link to={LINK.WEB_PRODUCTS_EDIT + "/" + record.id}>Services.CutString(text, 50, false)</Link> ;
                    }
                },
                {
                    title: 'thumbnail',
                    dataIndex: 'thumbnail',
                    render: (text, record, index) =>
                    {

                        return text[0] ? <Image
                            width={80}
                            src={LINK.myHostimgServerImg + "/" + text[0]}
                        />
                            : ""
                    }
                },
                {
                    title: 'ngày tạo',
                    dataIndex: 'created_at',
                    filterSearch: true,
                    filters: filters.created_at,
                    onFilter: (value, record) =>
                    {
                        return moment(record.created_at).format('DD/MM/YYYY') === moment(value).format('DD/MM/YYYY')

                    },
                    sorter: (a, b) => a.created_at - b.created_at,
                    render: (text, record, index) =>
                    {
                        return moment(text).format('DD/MM/YYYY h:m:s');;
                    }

                },


            ])
    }
    const OnPagination = (page, pageSize) =>
    {
      
        const formvalues = formSearchRef.current.getFieldsValue();

        const filteredvalues = Object.fromEntries(
            Object.entries(formvalues).filter(([_, value]) => { return value !== undefined && value !== "" })
        );
        // created_at
        if (filteredvalues.fromdate) {
            filteredvalues.created_at = [];
            filteredvalues.created_at[0] = moment(filteredvalues.fromdate, "YYYY-MM-DD");
            filteredvalues.created_at[1] = moment(filteredvalues.todate, "YYYY-MM-DD");
        }

        // this.formSearchRef.current.setFieldsValue(filteredvalues)

        dispatch(ActionsWeb.SearchProductsRequest({
            limit: pageSize,
            page: page, column: meta.column, sort: meta.sort
        }, { data: {...filteredvalues,isdetail:true} }))


    }
    const onLoadMore = () =>
    {
        if (meta.current_page < meta.last_page) {
            setLoading(true);
            setList(
                data.concat(
                    [...new Array(count)].map(() => ({
                        loading: true,
                        name: {},
                        picture: {},
                    })),
                ),
            );
                   
            OnPagination(meta.current_page + 1, meta.per_page)

        }

    };
    const SetselectedRowKeys1list=(e,id)=>{
        if(e.target.checked===true){
            SetselectedRowKeys1([...selectedRowKeys1, id])
            
        }else{
            const newselected = selectedRowKeys1.filter(item => {
                    return item!==id
            })
            SetselectedRowKeys1(newselected)
        }
       
    }

    const SetselectedRows1list = (e,item) =>
    {
       
        if (e.target.checked === true) {
            setIndeterminate(!![...selectedRows1, item].length && [...selectedRows1, item].length < list.length);
            setCheckAll([...selectedRows1, item].length === list.length);


            SetselectedRows1([...selectedRows1, item])
            if (props.setOderdetailsMutilpe){
                props.setOderdetailsMutilpe(2, item)
            }
        } else {
            const newselected = selectedRows1.filter(el =>
            {
                return el !== item
            })
            setIndeterminate(!!newselected.length && newselected.length < list.length);
            setCheckAll(newselected.length === list.length);
            SetselectedRows1(newselected)
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(1, item)
            }
        }
    }

    const processClassify= async (items,result=[], parentName = '')=>{

        for(let x in items){
               let item=items[x];
              
              // Tạo chuỗi name mới bằng cách nối tên cha và tên con
              let newName = parentName ? `${parentName} x ${item.name}` : item.name;
            //   console.log("itemitemitemitem ",newName);
              // Cập nhật chuỗi name mới vào item hiện tại
              let newItem = { ...item, newName: newName };
              
            if (!item.children || item.children.length === 0) {
                result.push(newItem);
            }else{
               await processClassify(item.children,result,newName);
            }
        }
        // console.log("datadatadatadatadata ",items);
       
        return result;
          // Duyệt qua tất cả các con và lấy giá trị price của con cuối cùng
         
    } 
    const setDataClassify = async (data) =>
    {
        const data_ = []

       
      
        const processItems = async (items) => {
            const promises = items.map(async (item) => {
              item.product_id = item.id;
              item.num = 1;
              item.note = "";
              item.price_or = item.price;
              
              let classify = await processClassify(item.classifys);
              
              if (classify) {
                for (let x in classify) {
                  const elcI = classify[x];
                  for (var xs in elcI.store_ids) {
                    let stID = elcI.store_ids[xs];
                    item.store_id_gen = { ...item.store_id_gen, [stID?.store_id]: { id: stID?.store_id, name: stID?.store_name, amount: stID?.amount } };
                    item.price = elcI?.price ? elcI?.price : item.price;
                    item.priceTmp = item.price * (1 - (elcI?.discount_percent / 100));
                    item.discount_percent = elcI?.discount_percent ? elcI?.discount_percent : item.discount_percent;
                    item.discount = elcI?.discount ? elcI?.discount : item.discount;
                    item.price_or = item.price;
                    item.store_name_c = stID?.store_name ? stID?.store_name : "";
                    item.store_id_c = stID?.store_id ? stID?.store_id : "";
                    item["classifystmp"] = elcI;
                    item["classify_id"] = elcI.id_;
                    item["amount"] = elcI.amount;
                    item["num"] = 1;
                    item["voucher"] = "";
                    item["classifys_name"] = elcI.name; // Thay vì elcI.newName, sử dụng elcI.name
                    item.key = `${item.id}-${elcI.parent_id}-${elcI.id}`;
                    data_.push({ ...item });
                  }
                }
              }
            });
          
            await Promise.all(promises);
          };

          await processItems(data);
          console.log(data_);
        if(!props.isNewList){
            const newData = dataList.concat(data_);
            setdataList(newData);
            setList(newData);
           
        }else{
            
            setdataList(data_);
            setList(data_);
        }
       

    }
    const onCheckAllChange = (e) =>
    {
        if (e.target.checked === true) {
            const keys = list.map((item) => {
                return item.key
            })
            SetselectedRowKeys1(keys);
            SetselectedRows1(list)
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(2, list)
            }
        } else {
            SetselectedRowKeys1([]);
            SetselectedRows1([])
            if (props.setOderdetailsMutilpe) {
                props.setOderdetailsMutilpe(1, [])
            }
        }
     
        setIndeterminate(false);
        setCheckAll(e.target.checked);
    };
    const loadMore =
        !initLoading && !loading ? (
            <div
                style={{
                    textAlign: 'center',
                    marginTop: 12,
                    height: 32,
                    lineHeight: '32px',
                }}
            >
                <Button onClick={onLoadMore}>Xem thêm</Button>
            </div>
        ) : null;
    return (
        <div className='dropdown_rim'>
            <Form name="formsearch" className="fromsearch" ref={formSearchRef} onFinish={onSearch} style={{
                padding: "0px !important",
                width: "100%",
                display: "flex",
                margin: 0
            }}>
                <Form.Item style={{ margin: 0, width: "100%" }}>
                    <Input.Group compact>
                    <div className="row d-flex">
                            <div className="row d-flex" style={{ width:"96%" }}>

                            <div className="col_ ">
                                <Form.Item name="title" noStyle style={{ margin: 0 }}>
                                    <Input placeholder="Tiêu đề" />
                                </Form.Item>
                            </div>

                            <div className="col_ ">
                                <Form.Item name="category_id" noStyle style={{ margin: 0 }}>
                                <Cascader options={ListCategory}  placeholder="Chọn 1 danh mục" />
                                    {/* <TreeSelect
                                        showSearch
                                        style={{ width: "100%" }}
                                        treeData={ListCategory}
                                        dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
                                        placeholder="Danh mục"
                                        multiple
                                        treeDefaultExpandAll

                                    /> */}
                                </Form.Item>
                            </div>
                            <div className="col_ " style={{ minWidth:"100px" }}>
                                <Form.Item name="tags_id" noStyle style={{ margin: 0}}>
                                    <Select
                                        mode="multiple"
                                        style={{ width: "100%" }}
                                        maxTagCount='responsive'
                                        placeholder="Chọn Tags"
                                        options={(ListTags || []).map((d) => ({
                                            value: d.id,
                                            label: d.name,
                                        }))}
                                    />
                                </Form.Item>
                            </div>
                            <div className="col_ ">

                                <Form.Item name="created_at" noStyle style={{ margin: 0 }}>
                                    <RangePicker />
                                </Form.Item>
                            </div>

                            <div className="col_ ">

                                <Form.Item noStyle style={{ margin: 0 }} >
                                    <div style={{ display: "flex" }}>
                                        <Button type="primary" icon={<SearchOutlined />} htmlType="submit" loading={isLoadingSearch} />
                                        <Button style={{ width: "50%", backgroundColor: Colors.colorWarning, color: Colors.white }} htmlType="button" onClick={onResetFrom}>Reset</Button>
                                    </div>
                                </Form.Item>

                            </div>
                           
                        </div>
                        <div className="col_ " style={{display:"flex",justifyContent:"flex-end",alignItems:"center"}}>

                            <Checkbox checked={checkAll}  onChange={(e) => { onCheckAllChange(e) }} indeterminate={indeterminate}></Checkbox>

                        </div>
                    </div>
                        






                    </Input.Group>
                </Form.Item>
            </Form>
            {!props.isListShow && <div className='list_scroll'>
                <List
                    className="demo-loadmore-list"
                    loading={initLoading}
                    itemLayout="horizontal"
                    loadMore={loadMore}
                    dataSource={list}
                    renderItem={(item) => (
                        <List.Item className='list_item_custom'
                            actions={[<Checkbox checked={selectedRowKeys1.includes(item.key)} onChange={(e) => { SetselectedRowKeys1list(e, item.key); SetselectedRows1list(e, item)}}></Checkbox>]}
                        >
                            <Skeleton avatar title={false} loading={item.loading} active>
                                {/* <List.Item.Meta
                                    avatar={<div className='img_list'><Image
                                        className='img'
                                        style={{ objectFit: "contain" }}
                                        src={LINK.myHostimgServerImg + "/" + item?.thumbnail?.[0]}
                                    /></div>}
                                    title={item.code}
                                    description={<div><strong>{Services.CutString(item?.title, 10, true)}</strong><br></br> {Services.CutString(item?.description, 20, true)}</div>}
                                /> */}
                                <ItemProduct item={item} placement={"right"}/>
                                
                                {/* <div>content</div> */}
                            </Skeleton>
                        </List.Item>
                    )}
                />
            </div>}
            {props.isListShow && <><Table rowClassName={(record, index) =>
                {
                    return "__row_";
                }}
                rowSelection={{
                    selectedRowKeys: selectedRowKeys1,
                    onChange: (selectedRowKeys, selectedRows) =>
                    {
                        SetselectedRowKeys1(selectedRowKeys)
                        SetselectedRows1(selectedRows)

                        setProductsSeleted(selectedRows)
                    },
                }}
                columns={columns}
                dataSource={data}
                pagination={false}
                scroll={{

                    y: "60vh",
                }}
                style={{ paddingBottom: "30px" }}
            />
            <div className="pagination_">
                <Pagination showTotal={(total, range) => `${range[0]}-${range[1]} của ${total} dòng`} pageSize={meta && meta.per_page ? meta.per_page : 10} total={meta && meta.total ? meta.total : 10} showSizeChanger onChange={OnPagination} />
                </div></>}
        </div>
    );
};

export default Index;
