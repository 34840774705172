import React, { useState, useRef, useMemo, useEffect } from 'react';
import JoditEditor from 'jodit-react';
import * as LinkApi from "./../../constants/LinkApi";
import * as LINK from "./../../constants/Links";
import { getCookie } from "./../../service";
import { message } from 'antd';

const token = getCookie("acccess_token");
const Index = ({ placeholder, onChangetext, value, editor,configs,linkUpload }) =>
{
  const [content, setContent] = useState('');

  useEffect(
    () =>
    {
      if (value) {
        setContent(value)
      }
    },
    [],
  );
  const config = {
   
    ...configs,
    allowResizeX: true,
    allowResizeY: true,
    // container: {
    //   height: "500px",
    //   allowResizeX: true,
    //   allowResizeY: true,

    // },

    uploader: {
      url: linkUpload?linkUpload:LinkApi.linkWeb + "fileupload",
      imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
      
      headers: {

        'Authorization': `Bearer ${token}`,

      },
      prepareData: function (data)
      {
        console.log(`respdatadatadata`, this);
        // data.append('files[]', data.getAll('files')); //
        data.append('action', "fileupload"); //
        data.append('source', this.source); //
        data.append('path', this.path); //
        data.append('url', this.url); //
        data.append('type', 4); //
        return data;
      },
      
      contentType: function (e)
      {

        return (
          (void 0 === this.jodit.ownerWindow.FormData || 'string' == typeof e) &&
          'multipart/form-data; boundary=<calculated when request is sent>'
        );
      },
   

      isSuccess: function (resp)
      {
        console.log(`resp`, resp);
        // console.log(`resp`, resp);
        resp.files = resp.data
        resp.error = resp.code === 200 ? false : true;
        return !resp.error;
      },
      getMessage: function (resp)
      {
        return resp.message;
      },
      process: function (resp)
      {
        return {
          files: resp.files || [],
          path: resp.path,
          baseurl: resp.baseurl,
          error: resp.error,
          msg: resp.msg
        };
      },
      defaultHandlerSuccess: function (data, resp)
      {
        var i,
          field = 'files';
        console.log(`data`, data);
        if (data[field] && data[field].length) {
          for (i = 0; i < data[field].length; i += 1) {
            if (data[field][i].startsWith('https://')) {
              // Nếu có, chèn trực tiếp hình ảnh mà không nối chuỗi
              this.s.insertImage(data[field][i]);
            } else {
              // Nếu không, nối chuỗi với LINK.myHostimgServerImg rồi chèn hình ảnh
              this.s.insertImage(LINK.myHostimgServerImg + "/" + data[field][i]);
            }
           
          }
        }
      },
      error: function (e)
      {
        console.log('====================================');
        console.log(e);
        console.log('====================================');
        // this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
      }
    },
    filebrowser: {
      createNewFolder: true,
      // contextMenu: false,
      deleteFolder: false,
      moveFolder: false,
      moveFile: false,
      isSuccess: function (resp)
      {
        if (resp.data.code !== 220) {
          message.warn(resp.data.massge)
        }
        console.log('====================================');
        console.log(resp);
        console.log('====================================');
        return resp.success;
      },
      ajax: {
        url: LinkApi.linkWeb + "listfile",

        method: 'GET',
        headers: {
          'Authorization': `Bearer ${token}`,
        },


      },

      uploader: {
        url: LinkApi.linkWeb + "listfile?action=fileupload&source=:source&path=:path&url=:url",
        imagesExtensions: ['jpg', 'png', 'jpeg', 'gif'],
        // format: 'json',
        // filesVariableName: function (r)
        // {
        //   console.log(`rrrrrrr`, r);
        //   // return 'files'
        // },
        headers: {

          'Authorization': `Bearer ${token}`,

        },
        prepareData: function (data)
        {
          // console.log(`respdatadatadata`, data.getAll('files'));
          // data.append('files[]', data.getAll('files')); //
          data.append('folder', "img"); //
          data.append('type', 4); //
          return data;
        },
        // contentType: function ()
        // {
        //   return 'application/json';
        // },
        contentType: function (e)
        {

          return (
            (void 0 === this.jodit.ownerWindow.FormData || 'string' == typeof e) &&
            'multipart/form-data; boundary=<calculated when request is sent>'
          );
        },
        // buildData: function (data)
        // {
        //   // console.log('====================================');
        //   // console.log(data.getAll('files['));
        //   // console.log('====================================');
        //   // data.append('files', data.getAll('files[0]')); //
        //   // data.append('folder', "img"); //
        //   // data.append('type', 4); //
        //   return { folder: "img", type: 4 };
        // },
        // data: {
        //   csrf: document.querySelector('meta[name="csrf-token"]').getAttribute('content')
        // },
        // pathVariableName: 'path',

        isSuccess: function (resp)
        {
          console.log(`resp`, resp);
          // console.log(`resp`, resp);
          resp.files = resp.data
          resp.error = resp.code === 200 ? false : true;
          return !resp.error;
        },
        getMessage: function (resp)
        {
          return resp.message;
        },
        process: function (resp)
        {
          return {
            files: resp.files || [],
            path: resp.path,
            baseurl: resp.baseurl,
            error: resp.error,
            msg: resp.msg
          };
        },
        defaultHandlerSuccess: function (data, resp)
        {
          var i,
            field = 'files';
          console.log(`data`, data);
          if (data[field] && data[field].length) {
            for (i = 0; i < data[field].length; i += 1) {
              if (data[field][i].startsWith('https://')) {
                // Nếu có, chèn trực tiếp hình ảnh mà không nối chuỗi
                this.s.insertImage(data[field][i]);
              } else {
                // Nếu không, nối chuỗi với LINK.myHostimgServerImg rồi chèn hình ảnh
                this.s.insertImage(LINK.myHostimgServerImg + "/" + data[field][i]);
              }
            }
          }
        },
        error: function (e)
        {
          console.log('====================================');
          console.log(e);
          console.log('====================================');
          // this.e.fire('errorMessage', [e.getMessage(), 'error', 4000]);
        }
      },
      folders: {
        data: { action: 'folders' }
      },
      fileFolder: {
        data: { action: 'fileFolder' }
      },
      // fileRemove: {
      //   data: { action: 'fileRemove' }
      // }

    },
  };
  return (
    <JoditEditor
    
      ref={editor}
      value={content}
      config={config}
      tabIndex={1} // tabIndex of textarea
      // onBlur={newContent => setContent} // preferred to use only this option to update the content for performance reasons
      onChange={(newContent) => { }}
    />
  );
};

export default Index;