import * as Types from "./../constants/TypesactionApp";

export const setIsync = (body) =>
{
 
  
  return (dispatch) =>
  {
    const d = (result) =>
    {
      return {
        type: Types.IS_SYNCDATA,
        result,
      };
    };
    return dispatch(d(body));

  };
};

export const setNotification = (body) =>
{

  return (dispatch) =>
  {
    const d = (result) =>
    {
      return {
        type: Types.IS_NOTIFICATION,
        result,
      };
    };
    return dispatch(d(body));

  };
};


export const setSocketConnect = (body) =>
{

  return (dispatch) =>
  {
    const d = (result) =>
    {
      return {
        type: Types.IS_CONNECT_SOCKET,
        result,
      };
    };
    return dispatch(d(body));

  };
};

export const isloader = (body) =>
{

  return (dispatch) =>
  {
    const d = (result) =>
    {
      return {
        type: Types.SET_IS_LOADING,
        result,
      };
    };
    return dispatch(d(body));

  };
};


export const toggleColapNav = (body) =>
{

  return (dispatch) =>
  {
    const d = (result) =>
    {
      return {
        type: Types.ACTIVE,
        result,
      };
    };
    return dispatch(d(body));

  };
};