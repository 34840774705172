
import React, { createRef, useEffect, useState } from 'react'
import { useRef } from "react";
import "./style.css";
import { useDispatch, useSelector } from 'react-redux';
import * as ActionsWeb from "../../actions/indexWeb";

import * as LINK from "../../constants/Links";
import {
  Form,
  Input,
  Button,
  InputNumber, Select,
  Space, message, Cascader,
  Divider, Image,
  Typography, Modal, Radio, Empty, TreeSelect, Card, Checkbox, Drawer
} from 'antd';
import LoadingSmile from "../loadingSmile/loadingSmile";
import {

  FileImageOutlined, SyncOutlined,
  EditOutlined,
  VideoCameraOutlined,
  PlusOutlined,
  PlusCircleOutlined, InfoCircleOutlined, SlidersOutlined, FormOutlined,
  DragOutlined, CarOutlined,
  DeleteOutlined, UserSwitchOutlined, ShoppingCartOutlined, GlobalOutlined
} from '@ant-design/icons';
import { META } from '../../utils/constant';
import Editor from "../editor/index";
import TableClassify from "../../pages/web/Products/edit/RecursiveDisplay";
import { fallbackImg } from '../../constants/DefaultData';

import { DragDropContext, Droppable, Draggable as DraggableB } from "react-beautiful-dnd";
import Validator from "../../utils/Validator";
import * as Colors from "../../constants/Colors";
import { v4 as uuidv4 } from 'uuid';
import { replaceThous, validateNUmber } from '../../service';

const grid = 8;
const getItemStyle = (isDragging, draggableStyle) => ({

  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: isDraggingOver ? "lightblue" : "grey",
  padding: grid,
  // width: 250
});

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const Index = ({ imgesInfo,setimgesInfo,setimgesInfoRemove, checkSync, dataClassifyx, classifyx,setClassifyx,setDataClassifyx,ListStore, dataClassifyStoreIds, record, ListCategory, setEditTitok, formRef, ListClassifys }) => {
  const dispatch = useDispatch()
  const { Web } = useSelector(state => state);
  const [meta, setMeta] = useState({ ...META });

  const [visibleClassifyProducts, setvisibleClassifyProducts] = useState(null);

  const [classify1, setclassify1] = useState({
    groups: [],
    items: []
  });
  const [classify2, setclassify2] = useState({
    groups: [],
    items: []
  });
  const [ListClassifys_, setListClassifys_] = useState(ListClassifys);

  const [classifyx_, setClassifyx_] = useState([
    { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, data: [], items: [{ key: uuidv4(), name: "Biến thể", price: 0, amount: 0, store_id: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
  ]);
  const setclassifyx = () => {

    // const { classifyx } = state;
    const classify_ = { groups: { groupname: "Tên biến thể", name: "Tên biến thể" }, items: [{ key: uuidv4(), name: "Biến thể-" + classifyx.length + "-1", price: 0, amount: 0, store_id: [], store_ids: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 }] }
    if(classifyx.length<ListClassifys.length){
      generateCombinations([...classifyx, classify_])
      setClassifyx([...classifyx, classify_]);
    }
   

  }


// console.log("ListClassifys_ListClassifys_ListClassifys_",ListClassifys_);
  useEffect(() => {
    // console.log("classifyxclassifyxclassifyxclassifyx",classifyx);
       setFormClassify();
     
   
    return () => {

    };
  }, [classifyx]);
  const setFormClassify = () => {
    const classifyxOr_set = {};
    for (let x in classifyx) {
      const item = classifyx[x]
      classifyxOr_set[`ecom_group_classify_${item.key}`] = parseInt(item.id);
      if (item.items) {
        for (let y in item.items) {
          const el = item.items[y]
          if(el.id){
            classifyxOr_set[`ecom_item_classify_${el.key}`] = parseInt(el.id);

          }
        }
      }

    }
   // console.log("classifyxOr_setclassifyxOr_setclassifyxOr_set",classifyxOr_set);
    formRef.current.setFieldsValue({ ...classifyxOr_set });
  }
  const generateCombinations = async (data) => {
    // let counter=0;
    function generate(index) {
      if (index === data.length - 1) {
        return data[index]?.items?.map(item => ({ ...item, children: [] }));
      }

      const currentGroup = data[index]?.items;
      const nextCombinations = generate(index + 1);

      return currentGroup?.map(item => {
        return {
          ...item,
          children: [...nextCombinations],
        }
      });
    }

    const result = generate(0);
    setDataClassifyx(result);

    return result;
  }

  const onchangeGroupClassifyx = async (e, ind) => {
    // const { ListClassifys, classifyx } = state;
    let cl = {};

    ListClassifys_.map((item, index) => {
      if (item.id === e) {
        item.hidden = true;
        cl = item;
      } else {
        item.hidden = false;
      }
   
    });

    classifyx[ind].data = cl?.children ? [...cl?.children] : []
    classifyx[ind] = { ...classifyx[ind], ...cl }
 
    setClassifyx([...classifyx]);
    setListClassifys_(ListClassifys_);
    // setClassifyx_(classifyx);
    

  }
  // console.log("classifyxclassifyxclassifyx",classifyx);
  const onchangeItemClassifyx = async (e, key, ind) => {

    let cl = {};
    let data = classifyx[ind].data;
    data.map((item, index) => {
      if (item.id === e) {
        classifyx[ind].data[index].hidden = true;
        cl = item;
      }
    });


    classifyx.map((item, index) => {
      item.items.map((el, ind) => {
        if (el.key === key) {
          item.items[ind] = { ...el,  ...cl };
        }
      });

    });
   
    generateCombinations(classifyx);
    setClassifyx([...classifyx]);



  }
  const setclassifyitemx = (index) => {

    const classify_ = classifyx[index];
    const item_ = classify_?.items ? classify_?.items : [];

    item_.push({ key: uuidv4(), name: "Biến thể-" + index + "-" + item_.length, price: 0, amount: 0, store_id: [], store_ids: [], code: "", discount_percent: 0, discount: 0, discount_price: 0 })
    classify_.items = item_;
    classifyx[index] = classify_;
    generateCombinations(classifyx)
    setClassifyx([...classifyx]);


  }
  const DeleteclassifyxItem = (ind, index) => {

    const classify_ = classifyx[ind];
    const item_ = classify_.items;
    const newitem = item_.filter((item, i) => {
      return i !== index
    })
    let newClassifyx = classifyx
    if (newitem.length <= 0) {
      newClassifyx = classifyx.filter((item, index) => {
        return index != ind
      })
    } else {
      newClassifyx[ind].items = newitem;
    }
    setClassifyx([...newClassifyx]);
    generateCombinations(classifyx);
  }
 
  // useEffect(() => {
  //   generateCombinations(classifyx);
  //   return () => {

  //   };
  // }, [classifyx]);

  const addItem = (e, type, parent_id) => {

  }

  const onDragEnd = async (type, result) => {
    console.log("resultresultresult", result);
    // dropped outside the list
    if (!result.destination) {
      return;
    }

    const items = await reorder(
      type === 1 ? classify1.items : classify2.items,
      result.source.index,
      result.destination.index
    );

    // sortItems_(type, items);
    // setState({
    //     items
    // });
  };

  const ColorCell = (type = '', isfocus = false) => {

    let els = document.getElementsByClassName(type);
    for (let i = 0; i < els.length; i++) {

      const element = els[i];


      if (isfocus) {
        element.classList.add("bg_color_red")
      } else {
        element.classList.remove("bg_color_red")
      }
    }

  }

  const SubmitChangeAllx = async () => {

    const formvalues = formRef.current.getFieldsValue();
    const price = formvalues.ecom_price;
    const store_id = formvalues.ecom_store_id;
    const amount = formvalues.ecom_amount;
    const discount = formvalues.ecom_discount;
    const discount_percent = formvalues.ecom_discount_percent;
    const discount_price = formvalues.ecom_discount_price;
    if (!store_id) {

      message.warn("vui lòng chọn cửa hàng");
      return;
    }
    let dataset = {};
    let arrParent = {}
    const updateItemValue = async (data, level = 0, parent_item = null) => {
      let check = false;

      return Promise.all(data.map(async (item, indi) => {


        if (item.children && item.children.length > 0) {
          if (parent_item) {
            arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
          }
          await updateItemValue(item.children, level + 1, item);
        } else {
          const tmp = Object.values({ ...arrParent });
          if (parent_item) {
            tmp.push({ ...parent_item, children: [] });
          }

          tmp.push({ ...item, children: [] });
          let key_ = "";
          tmp.map((d_, ide_) => {
            key_ += d_.key
          })
          item.price = price;
          item.store_id = store_id;
          item.amount = amount;
          item.discount = discount;
          item.discount_percent = discount_percent;
          item.discount_price = discount_price;
          let total = formvalues[`ecom_amount_price_${key_}`] ? formvalues[`amount_price_${key_}`] : amount;

          let a_ = Math.floor(total / item.store_id?.length);
          let b_ = total - (a_ * item.store_id?.length);

          let store_ids = [];

          let setformValue = {};
          await Promise.all(item.store_id.map(async (elstore, instore) => {

            if (instore === (item.store_id.length - 1)) {
              store_ids.push({ amount: a_ + b_, store_id: elstore });
              setformValue = { ...setformValue, [`ecom_amount_price_item_d_${key_}_${instore}`]: a_ + b_ };
            } else {
              store_ids.push({ amount: a_, store_id: elstore });
              setformValue = { ...setformValue, [`ecom_amount_price_item_d_${key_}_${instore}`]: a_ };
            }
          }));
          item.store_ids = store_ids


          formRef.current.setFieldsValue({ ...setformValue });

          dataset = {
            ...dataset,
            [`price_${key_}`]: item.price,
            [`discount_${key_}`]: item.discount,
            [`discount_percent_${key_}`]: item.discount_percent,
            [`discount_price_${key_}`]: item.discount_price,
            [`amount_price_${key_}`]: item.amount,
            [`house_${key_}`]: item.store_id,
            [`code_${key_}`]: item.code
          }
        }

        return item;
      }));
    }

    const dataClassifyxnew = await updateItemValue(dataClassifyx, 0);

    formRef.current.setFieldsValue({ ...formvalues, ...dataset })
    setDataClassifyx(dataClassifyxnew);


  }
  const onChangeDiscountItem = (e, type, key) => {
    const formvalues = formRef.current.getFieldsValue();
    let value = '';
    if (e.target) {
      value = e.target.value
    }
    else {
      value = e
    }
    let discount = 0;
    let discount_percent = 0;

    if (formvalues["price_" + key]) {
      discount_percent = formvalues["discount_percent_" + key];
      discount = formvalues["discount_" + key];;
      if (type === "discount") {
        value = replaceThous(value);
        discount_percent = (value / formvalues["price_" + key]) * 100;

        discount = value;
      } else if (type === "discount_percent") {
        discount_percent = value;
        discount = formvalues["price_" + key] * (discount_percent / 100);
      }

      if (!validateNUmber(discount)) {
        discount = 0;
      }
      let discount_price = formvalues["price_" + key] - discount;

      formRef.current.setFieldsValue({
        [`ecom_discount_${key}`]: discount, [`ecom_discount_percent_${key}`]: discount_percent, [`ecom_discount_price_${key}`]: discount_price
      })
      // setState(state =>
      // {
      //     return {
      //         record: { ...state.record, discount: discount, discount_percent: discount_percent, discount_price: discount_price },
      //         dataAllInOne: { ...state.dataAllInOne, discount: discount, discount_percent: discount_percent, discount_price: discount_price },
      //     }
      // })
    } else {
      message.warn("Bạn chưa nhập giá!");

    }

  }

  const onChangeAmountItem = async (e, type, key) => {

    let arrParent = {}
    const updateItemValue = async (data, level = 0, parent_item = null) => {

      return Promise.all(data.map(async (item, indi) => {
        let newItem = { ...item };

        if (item.children && item.children.length > 0) {
          if (parent_item) {
            arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
          }

          let newItemc = await updateItemValue(item.children, level + 1, item);
          let newItem = { ...item, children: newItemc };
          return newItem;
        } else {
          const tmp = Object.values({ ...arrParent });
          if (parent_item) {
            tmp.push({ ...parent_item, children: [] });
          }

          tmp.push({ ...item, children: [] });
          let key_ = "";
          tmp.map((d_, ide_) => {
            key_ += d_.key
          })

          if (key === key_) {
            const stores_ = newItem.store_ids;
            let a_ = Math.floor(e / stores_.length);
            let setformValue = {};
            let b_ = e - (a_ * stores_.length);

            newItem.store_ids = newItem.store_ids.map((elstore, istore) => {
              if (istore === (newItem.store_ids.length - 1)) {
                elstore = { ...elstore, amount: a_ + b_ }
                setformValue = { ...setformValue, [`ecom_amount_price_item_d_${key_}_${istore}`]: a_ + b_ }
              } else {
                elstore = { ...elstore, amount: a_ }
                setformValue = { ...setformValue, [`ecom_amount_price_item_d_${key_}_${istore}`]: a_ }
              }


              return elstore;
            });
            formRef.current.setFieldsValue({ ...setformValue });

          }

          return newItem;
        }


      }));
    }
    const newDataClassifyx = await updateItemValue(dataClassifyx, 0);
    // setState(state=>{
    //     return{
    //         dataClassifyx: newDataClassifyx
    //     }
    // })
  }

  const onChangeAmountItemSub = async (e, type, key, i_) => {
    const formvalues = formRef.current.getFieldsValue();
    // const { dataClassifyx } = state;
    let arrParent = {}
    const updateItemValue = async (data, level = 0, parent_item = null) => {

      return Promise.all(data.map(async (item, indi) => {
        let newItem = { ...item };

        if (item.children && item.children.length > 0) {
          if (parent_item) {
            arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
          }

          let newItemc = await updateItemValue(item.children, level + 1, item);
          let newItem = { ...item, children: newItemc };
          return newItem;
        } else {
          const tmp = Object.values({ ...arrParent });
          if (parent_item) {
            tmp.push({ ...parent_item, children: [] });
          }

          tmp.push({ ...item, children: [] });
          let key_ = "";
          tmp.map((d_, ide_) => {
            key_ += d_.key
          })

          if (key === key_) {
            const stores_ = newItem.store_ids;

            let total = 0;


            newItem.store_ids = newItem.store_ids.map((elstore, istore) => {
              if (i_ === istore) {
                elstore = { ...elstore, amount: e }
              }
              total += formvalues[`ecom_amount_price_item_d_${key_}_${istore}`];


              return elstore;
            });

            formRef.current.setFieldsValue({ [`ecom_amount_price_${key_}`]: total });

          }

          return newItem;
        }


      }));
    }
    const newDataClassifyx = await updateItemValue(dataClassifyx, 0);
    // setState(state =>
    // {
    //     return {
    //         dataClassifyx: newDataClassifyx
    //     }
    // })
  }
  const onChangeStoreItem = async (e, type, key) => {
    // let result = {}
    const formvalues = formRef.current.getFieldsValue();
    // const { dataClassifyx } = state;
    let arrParent = {}
    const updateItemValue = async (data, level = 0, parent_item = null) => {

      return Promise.all(data.map(async (item, indi) => {
        let newItem = { ...item };

        if (item.children && item.children.length > 0) {
          if (parent_item) {
            arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
          }

          let newItemc = await updateItemValue(item.children, level + 1, item);
          let newItem = { ...item, children: newItemc };
          return newItem;
        } else {
          const tmp = Object.values({ ...arrParent });
          if (parent_item) {
            tmp.push({ ...parent_item, children: [] });
          }

          tmp.push({ ...item, children: [] });
          let key_ = "";
          tmp.map((d_, ide_) => {
            key_ += d_.key
          })
          let total = formvalues[`ecom_amount_price_${key_}`];
          if (key === key_) {

            const stores_ = newItem.store_ids;
            const store_id = newItem.store_id;
            newItem.store_id = e;
            newItem.store_ids = [];
            stores_.map((el, index) => {
              if (el) {
                if (newItem.store_id.includes(el.store_id)) {
                  newItem.store_ids.push(el);
                }
              }

            })
            newItem.store_id.map((el, index) => {
              const checkitem = newItem.store_ids.filter(el__ => {
                return el__.store_id === el;
              })
              if (checkitem.length <= 0) {
                newItem.store_ids.push({ amount: 0, store_id: el })
              }
            });


            let a_ = Math.floor(total / newItem.store_ids.length);
            let setformValue = {};
            let b_ = total - (a_ * newItem.store_ids.length);

            newItem.store_ids = newItem.store_ids.map((elstore, istore) => {
              if (istore === (newItem.store_ids.length - 1)) {
                elstore = { ...elstore, amount: a_ + b_ }
                setformValue = { ...setformValue, [`ecom_amount_price_item_d_${key_}_${istore}`]: a_ + b_ }
              } else {
                elstore = { ...elstore, amount: a_ }
                setformValue = { ...setformValue, [`ecom_amount_price_item_d_${key_}_${istore}`]: a_ }
              }


              return elstore;
            });
            formRef.current.setFieldsValue({ ...setformValue });


          }
          // result[key_] = newItem.store_ids;
          return newItem;
        }


      }));
    }
    const newDataClassifyx = await updateItemValue(dataClassifyx, 0);

    setDataClassifyx(newDataClassifyx);

  }

  const setClassifyStoreId = async (classifys) => {

    let arrParent = {}
    let result = {}
    const updateItemValue = async (data, level = 0, parent_item = null) => {

      return Promise.all(data.map(async (item, indi) => {
        let newItem = { ...item };

        if (item.children && item.children.length > 0) {
          if (parent_item) {
            arrParent = { ...arrParent, [level]: { ...parent_item, children: [] } };
          }

          let newItemc = await updateItemValue(item.children, level + 1, item);
          let newItem = { ...item, children: newItemc };
          return newItem;
        } else {
          const tmp = Object.values({ ...arrParent });
          if (parent_item) {
            tmp.push({ ...parent_item, children: [] });
          }

          tmp.push({ ...item, children: [] });
          let key_ = "";
          tmp.map((d_, ide_) => {
            key_ += d_.key
          })


          const stores_ = newItem.store_ids;

          result[key_] = stores_



          return newItem;
        }


      }));
    }
    const newDataClassifyx = await updateItemValue(classifys, 0);

    return result;
  }
  // console.log("record",record);
  return (
    <>
      <Card
        style={{
          width: "100%",
          marginTop: "10px"
        }}
        bodyStyle={{
          padding: "10px 10px",
          position: "relative"
        }}
        headStyle={{
          backgroundColor: Colors.colorgrey,
          display: "flex",
          minHeight: "30px",
        }}
        className="card_pr_order"
        title={<div style={{ display: "flex", alignItems: "center", justifyContent: "space-between", backgroundColor: "#fafafa" }}>
          <div style={{ display: "flex" }}><ShoppingCartOutlined />
            <span style={{ marginLeft: "10px" }}>Thông tin bán hàng</span>
          </div>
          <Button size="small" onClick={() => setvisibleClassifyProducts(true)}><PlusOutlined style={{ color: Colors.colorEdit }} /></Button>

        </div>} bordered={true}
      >
        {classifyx?.length > 0 && classifyx.map((el, ind) => {
          // console.log("elelelelelel",el);
          return <Form.Item label={`Biến thể ${ind + 1}`} >

            <div className="ecom_classify_pr_wr" style={{ backgroundColor: Colors.colorgrey, padding: "10px", border: "" }}><div className="classify_pr">
              <Form.Item
                name={`group_classify_${el.key}`}
                initialValue={el?.id}

              >

                <Select
                  showSearch
                  maxTagCount='responsive'
                  style={{
                    width: "100%"
                  }}
                  placeholder="Nhóm biến thể"
                  onChange={(e) => { onchangeGroupClassifyx(e, ind) }}

                  dropdownRender={(menu) => (
                    <>
                      {menu}
                      <Divider
                        style={{
                          margin: '8px 0',
                        }}
                      />
                      <Space
                        align="center"
                        style={{
                          padding: '0 8px 4px',
                        }}
                      >
                        <Form.Item noStyle name={`group_classify_add_${ind}`} >
                          <Input placeholder="Nhập tên nhóm biến thể" />
                        </Form.Item>
                        <Typography.Link
                          onClick={(event) => addItem(event, `group_classify_add_${ind}`, 0)}
                          style={{
                            whiteSpace: 'nowrap',
                          }}
                        >
                          <PlusOutlined /> Thêm nhóm biến thể
                        </Typography.Link>
                      </Space>
                    </>
                  )}

                  options={(ListClassifys_ || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                    hidden: d.hidden
                  }))}
                />
              </Form.Item>
              <DragDropContext onDragEnd={(result) => onDragEnd(1, result)}>
                <Droppable droppableId="droppable">
                  {(droppableProvided, droppableSnapshot) => (
                    <div
                      ref={droppableProvided.innerRef}
                      style={getListStyle(droppableSnapshot.isDraggingOver)}
                    >
                      {el?.items?.map((item, index) =>
                      (


                        <DraggableB key={item.key} draggableId={item.key} index={index}>
                          {(draggableProvided, draggableSnapshot) => {

                            return <div
                              ref={draggableProvided.innerRef}
                              {...draggableProvided.draggableProps}
                              {...draggableProvided.dragHandleProps}
                              style={getItemStyle(
                                draggableSnapshot.isDragging,
                                draggableProvided.draggableProps.style
                              )}
                            >
                              <div className="input_classfy_wrap d-flex" >
                                <Form.Item
                                  style={{
                                    width: "100%"
                                  }}
                                  name={`ecom_item_classify_${item.key}`}

                                >

                                  <Select
                                    showSearch
                                    maxTagCount='responsive'
                                    style={{
                                      width: "100%"
                                    }}
                                    placeholder="Danh sách biến thể"
                                    onChange={(e) => { onchangeItemClassifyx(e, item.key, ind) }}

                                    dropdownRender={(menu) => (
                                      <>
                                        {menu}
                                        <Divider
                                          style={{
                                            margin: '8px 0',
                                          }}
                                        />
                                        <Space
                                          align="center"
                                          style={{
                                            padding: '0 8px 4px',
                                          }}
                                        >
                                          <Form.Item noStyle name={`item_classify_${index}`} >
                                            <Input placeholder="Nhập tên biến thể" />
                                          </Form.Item>
                                          <Typography.Link
                                            onClick={(event) => addItem(event, `item_classify_${index}`, `group_classify_${ind}`)}
                                            style={{
                                              whiteSpace: 'nowrap',
                                            }}
                                          >
                                            <PlusOutlined /> Thêm biến thể
                                          </Typography.Link>
                                        </Space>
                                      </>
                                    )}

                                    options={(el.data || []).map((d) => ({
                                      value: d.id,
                                      label: d.name,
                                      hidden: d.hidden
                                    }))}
                                  />

                                </Form.Item>
                                <div className="input_classfy d-flex">
                                  <span className="icon_drag"><DragOutlined /></span><span className="icon_drag"><button type='button' className="reset_btn " onClick={() => DeleteclassifyxItem(ind, index)}><DeleteOutlined /></button></span>

                                </div>
                              </div>
                            </div>
                          }}
                        </DraggableB>
                      )
                      )}
                      {droppableProvided.placeholder}
                    </div>
                  )}
                </Droppable>
              </DragDropContext>

              <Button type="dashed" primary="true" style={{ width: "100%" }} onClick={() => { setclassifyitemx(ind) }}>
                <PlusCircleOutlined />
              </Button>

            </div>
            </div>
          </Form.Item>
        })
        }


        <Button type="dashed" primary="true" style={{ width: "100%" }} onClick={() => { setclassifyx() }}>
          {/* <PlusCircleOutlined /> */}
          Thêm 1 biến thể
        </Button>

        <Form.Item label="Mẹo thiết lập phân loại hàng">
          <div className="row">
            <div className="col_ col-lg-3 col-md-3 col-sm-12 mb-1">
              <Form.Item
                name={"ecom_price"}
                style={{ margin: 0 }}
                rules={[
                  {
                    required: true,
                    message: "Giá là bắt buộc"
                  },
                ]}
                initialValue={record?.price}
              >
                <Input addonBefore="₫" style={{ width: "100%" }} placeholder="Giá" onFocus={() => ColorCell("price_classify", true)} onBlur={() => { ColorCell("price_classify", false) }}

                />

              </Form.Item>
            </div>
            <div className="col_ col-lg-1 col-md-1 col-sm-12 mb-1">
              <Form.Item
                name={"ecom_amount"}
                rules={[
                  {
                    required: true,
                    message: "Số lượng là bắt buộc"
                  },
                ]}
                style={{ margin: 0 }}
                initialValue={record?.amount}

              >
                <InputNumber min={0} style={{ width: "100%" }} placeholder="SL" onFocus={() => ColorCell("amount_price_classify", true)} onBlur={() => { ColorCell("amount_price_classify", false) }}
                // value={record.amount}
                // onChange={(e) => { onChangeAllInOne(e, "amount", false) }}

                />

              </Form.Item>
            </div>
            <div className="col_ col-lg-3 col-md-3 col-sm-12 mb-1">
              <Form.Item
                name={"ecom_store_id"}
                rules={[
                  {
                    required: true,
                    message: "Kho hàng là bắt buộc"
                  },
                ]}
                style={{ margin: 0 }}
                initialValue={record?.store_id}
              >
                <Select
                  mode={"multiple"}
                  maxTagCount='responsive'
                  style={{ width: '100%', }}
                  // value={dataAllInOne.store_id}
                  placeholder="Cửa hàng"
                  // name="Store" id="Store"
                  // onChange={(e) => { onChangeAllInOne(e, "store_id") }}
                  onFocus={() => ColorCell("house_classify", true)} onBlur={() => { ColorCell("house_classify", false) }}
                  options={(ListStore || []).map((d) => ({
                    value: d.id,
                    label: d.name,
                  }))}
                />

              </Form.Item>
            </div>
            <div className="col_ col-lg-5 col-md-5 col-sm-12 flex_">
              <Form.Item
                name={"ecom_code"}
                style={{ margin: 0 }}
                initialValue={record?.code}

              >
                <Input style={{ color: "green", fontWeight: "bold", width: "100%" }}
                  placeholder="SKU phân loại" onFocus={() => ColorCell("code_classify", true)} onBlur={() => { ColorCell("code_classify", false) }}
                  // value={record.code}
                  // onChange={(e) => onChangeAllInOne(e, "code")}

                  disabled

                />
              </Form.Item>
              <Form.Item
                style={{ margin: 0 }}
              >
                <Button type="primary" danger primary="true" onClick={() => { SubmitChangeAllx() }}>
                  Áp dụng cho tất cả
                </Button>
              </Form.Item>
            </div>
          </div>

        </Form.Item>
        <Form.Item label="Giảm giá" >
          <div className="row">

            <div className="col_ col-lg-4 col-md-3 col-sm-12 mb-1">
              <Form.Item
                style={{ margin: 0 }}
                name={"ecom_discount"}
                initialValue={record?.discount}

              // rules={[{ required: true, message: 'Giá là bắt buộc' }]}
              >
                <Input addonBefore="₫" style={{}} placeholder="Giảm giá"
                  // value={formatMN1(record.discount)}
                  // onChange={(e) => { onChangeDiscount(e, "discount", true) }}

                  onFocus={() => ColorCell("discount_classify", true)} onBlur={() => { ColorCell("discount_classify", false) }} />
              </Form.Item>
            </div>
            <div className="col_ col-lg-4 col-md-3 col-sm-12 mb-1">
              <Form.Item
                style={{ margin: 0 }}
                name={"ecom_discount_percent"}
                initialValue={record?.discount_percent}

              >
                <Input addonBefore="%" style={{}} placeholder="Giảm giá %"
                  // value={record.discount_percent}
                  // onChange={(e) => {onChangeDiscount(e, "discount_percent", false) }}

                  onFocus={() => ColorCell("discount_percent_classify", true)} onBlur={() => { ColorCell("discount_percent_classify", false) }} />
              </Form.Item>
            </div>
            <div className="col_ col-lg-4 col-md-3 col-sm-12 mb-1 flex_">

              <Form.Item
                style={{ margin: 0 }}
                name={"ecom_discount_price"}
                initialValue={record?.discount_price}

              >
                <Input addonBefore="₫" style={{}} placeholder="Giá giảm" readOnly disabled />
              </Form.Item>

              <Form.Item
                style={{ margin: 0 }}
              >
                <Button type="primary" danger primary="true" onClick={() => { SubmitChangeAllx() }}>
                  Áp dụng cho tất cả
                </Button>
              </Form.Item>
            </div>

          </div>


        </Form.Item>
        <Form.Item label="Danh sách phân loại hàng" >

          <div className="table-responsive">
            <TableClassify data={dataClassifyx} ColorCell={ColorCell} ListStore={ListStore} onChangeDiscountItem={onChangeDiscountItem} onChangeAmountItem={onChangeAmountItem}

              onChangeAmountItemSub={onChangeAmountItemSub}
              onChangeStoreItem={onChangeStoreItem}
              dataClassifyStoreIds={dataClassifyStoreIds}
              fileList={imgesInfo} 
              setEditTitok={setEditTitok}
               type_f={1} setimgesInfo={setimgesInfo} setimgesInfoRemove={setimgesInfoRemove}
            />

          </div>


        </Form.Item>



      </Card>
    </>
  )
}
export default Index;