import { linkWeb,domainApi } from "./LinkApi";

const endpoint = "/";

// /home/web
export const ArrLink = {


    myHost: window.location.protocol + '//' + window.location.host + "",
    myHostimg: window.location.protocol + '//' + window.location.host + "/images",
    myHostAnimates: window.location.protocol + '//' + window.location.host + "/animates",
    myHostimgServerImg: `${domainApi}uploads`,
    myHostimgServerVide:`${domainApi}uploads`,
    myHostimgServerfiles: `${domainApi}uploads/files`,
    myHostapp: `${domainApi}`,
    // myHostimgServerImg: "http://app.rimmiel.com",
    // myHostimgServerVide: "http://app.rimmiel.com/uploads",


    endpoint: "/",
    HOME: `${endpoint}/home`,
    LIVE_CHAT: `${endpoint}/home/livechat`,
    // SETTING: `${endpoint}/home/setting`,
    // SETTING_GEN: `${endpoint}/home/setting/gene`,
    // SETTING_TAGS: `${endpoint}/home/setting/tags`,
    // SETTING_ADD_PAGE: `${endpoint}/home/setting/addpage`,
    // SETTING_CHAT: `${endpoint}/home/setting/chatsp`,
    PASS: `${endpoint}passpro`,
    PASS_ADDORDER: `${endpoint}passpro/addorder`,
    PASS_ORDER: `${endpoint}passpro/order`,
    DASHBOARD: `${endpoint}dashboard`,
    LOGIN: `${endpoint}login`,

    WEB: `${endpoint}`,
    WEB_DASHBOARD: `${endpoint}dashboard`,

    WEB_PRODUCTS: `${endpoint}products`,
    WEB_PRODUCTS_EDIT: `${endpoint}products_edit`,


    WEB_PRODUCTS_TKTOK: `${endpoint}products_tiktok`,
    WEB_PRODUCTS_EDIT_TKTOK: `${endpoint}products_tiktok_edit`,

    WEB_PRODUCTS_LAZADA: `${endpoint}products_lazada`,
    WEB_PRODUCTS_EDIT_LAZADA: `${endpoint}products_lazada_edit`,

    WEB_ORDERS: `${endpoint}orders`,
    WEB_ORDERS_EDIT: `${endpoint}orders_edit`,

    WEB_ORDERS_TIKTOK: `${endpoint}orders_tiktok`,
    WEB_ORDERS_EDIT_TIKTOK: `${endpoint}orders_edit_tiktok`,

    WEB_CATEGORY: `${endpoint}category`,
    WEB_CATEGORY_EDIT: `${endpoint}category_edit`,

    WEB_MENUHOME: `${endpoint}menuhome`,
    WEB_MENUHOME_EDIT: `${endpoint}menuhome_edit`,

    WEB_CLASSIFY: `${endpoint}classify`,
    WEB_CLASSIFY_EDIT: `${endpoint}classify_edit`,

    WEB_INFO_TRANSPORT: `${endpoint}infotransport`,
    WEB_INFO_TRANSPORT_EDIT: `${endpoint}infotransport_edit`,

    WEB_NUMBER_OF_PAGE: `${endpoint}number_of_page`,
    WEB_NUMBER_OF_PAGE_EDIT: `${endpoint}number_of_page_edit`,


    WEB_REGION: `${endpoint}region`,
    WEB_REGION_EDIT: `${endpoint}region_edit`,


    WEB_ROLE: `${endpoint}role`,
    WEB_FUNC: `${endpoint}funct`,
    WEB_FUNC_EDIT: `${endpoint}funct_edit`,
    WEB_SETTING: `${endpoint}setting`,
    WEB_ROLE_EDIT: `${endpoint}role_edit`,

    WEB_STORE: `${endpoint}store`,
    WEB_STORE_EDIT: `${endpoint}store_edit`,

    WEB_TAGS: `${endpoint}tags`,
    WEB_TAGS_EDIT: `${endpoint}tags_edit`,

    WEB_TRADEMARK: `${endpoint}trademark`,
    WEB_TRADEMARK_EDIT: `${endpoint}trademark_edit`,
    WEB_TYPE_PRODUCTS: `${endpoint}typeproducts`,
    WEB_TYPE_PRODUCTS_EDIT: `${endpoint}typeproducts_edit`,


    WEB_WAREHOUSE: `${endpoint}warehouse`,
    WEB_WAREHOUSE_EDIT: `${endpoint}warehouse_edit`,
    WEB_WARRANTY_TYPE: `${endpoint}warrantytype`,
    WEB_WARRANTY_TYPE_EDIT: `${endpoint}warrantytype_edit`,


    WEB_ORGANIZATION_PRODUCTION: `${endpoint}orproduction`,
    WEB_ORGANIZATION_PRODUCTION_EDIT: `${endpoint}orproduction_edit`,


    WEB_PAPER_TEXTURE: `${endpoint}paper_texture`,
    WEB_PAPER_TEXTURE_EDIT: `${endpoint}paper_texture_edit`,

    WEB_INFOPACKAGE: `${endpoint}infopackage`,
    WEB_INFOPACKAGE_EDIT: `${endpoint}infopackage_edit`,
    WEB_ORGANIZATION_PRODUCTION_ADDRESS: `${endpoint}orproductionaddress`,
    WEB_ORGANIZATION_PRODUCTION_ADDRESS_EDIT: `${endpoint}orproductionaddress_edit`,



    WEB_INVENTORY_IN: `${endpoint}inventory`,
    WEB_INVENTORY_IN_EDIT: `${endpoint}inventory_edit`,

    WEB_INVENTORY_CHECK: `${endpoint}inventorycheck`,
    WEB_INVENTORY_CHECK_EDIT: `${endpoint}inventorycheck_edit`,


    WEB_ATTRIBUTE_PRODUCTS: `${endpoint}attribute`,
    WEB_ATTRIBUTE_PRODUCTS_EDIT: `${endpoint}attribute_edit`,


    WEB_SUPPLIER: `${endpoint}supplier`,
    WEB_SUPPLIER_EDIT: `${endpoint}supplier_edit`,
    WEB_BUILDER: `${endpoint}builder`,

    WEB_USER: `${endpoint}user`,
    WEB_USER_EDIT: `${endpoint}user_edit`,

    WEB_MENUS: `${endpoint}menus`,
    WEB_MENUS_EDIT: `${endpoint}menus_edit`,

    WEB_STATUS_TRANSPORT: `${endpoint}statustransport`,
    WEB_STATUS_TRANSPORT_EDIT: `${endpoint}statustransport_edit`,

    WEB_STATUS_TRANSPORT_TYPE: `${endpoint}statustransporttype`,
    WEB_STATUS_TRANSPORT_TYPE_EDIT: `${endpoint}statustransporttype_edit`,

    WEB_CUSTOMERS: `${endpoint}customers`,
    WEB_CUSTOMERS_EDIT: `${endpoint}customers_edit`,

    WEB_VOUCHERS: `${endpoint}vouchers`,
    WEB_VOUCHERS_EDIT: `${endpoint}vouchers_edit`,


    WEB_VOUCHERS_GROUP: `${endpoint}vouchersgroup`,
    WEB_VOUCHERS_GROUP_EDIT: `${endpoint}vouchersgroup_edit`,

    WEB_PROVINCES: `${endpoint}provinces`,
    WEB_PROVINCES_EDIT: `${endpoint}provinces_edit`,

    WEB_DISTRICTS: `${endpoint}district`,
    WEB_DISTRICTS_EDIT: `${endpoint}district_edit`,

    WEB_WAR: `${endpoint}war`,
    WEB_WAR_EDIT: `${endpoint}war_edit`,

    WEB_POST: `${endpoint}post`,
    WEB_POST_EDIT: `${endpoint}post_edit`,

    WEB_SETTINGS: `${endpoint}settings`,
    WEB_SETTINGS_EDIT: `${endpoint}settings_edit`,
    WEB_SETTINGS_ORDER: `${endpoint}settings/settings_order`,
    WEB_SETTINGS_WEB: `${endpoint}settings/settings_web`,
    WEB_SETTINGS_ADMIN: `${endpoint}settings/settings_admin`,
    WEB_SETTINGS_SOCIAL: `${endpoint}settings/settings_socails`,
    WEB_SETTINGS_PRINT_BILLS: `${endpoint}settings/settings_print_bills`,
    WEB_SETTINGS_ECOMMERCE: `${endpoint}settings/settings_ecommerce`,

    WEB_SETTINGS_SOCIAL_FACEBOOK: `${endpoint}settings/settings_socails/facebook`,
    WEB_SETTINGS_SOCIAL_ZALO: `${endpoint}settings/settings_socails/zalo`,
    WEB_SETTINGS_CACHE: `${endpoint}settings/cache`,

    WEB_BANNERS: `${endpoint}banners`,
    WEB_BANNERS_EDIT: `${endpoint}banners_edit`,

    WEB_COLLECTIONS: `${endpoint}collections`,
    WEB_COLLECTIONS_EDIT: `${endpoint}collections_edit`,


    WEB_FEEDBACK: `${endpoint}feedback`,
    WEB_FEEDBACK_EDIT: `${endpoint}feedback_edit`,

    WEB_PAGE: `${endpoint}pages`,
    WEB_PAGE_EDIT: `${endpoint}pages_edit`,

    WEB_CONTACT: `${endpoint}contact`,
    WEB_CONTACT_EDIT: `${endpoint}contact_edit`,


    WEB_REPORTS: `${endpoint}report`,
    WEB_REPORT_BESTSELLING: `${endpoint}report-bestselling`,
    WEB_REPORT_REVENUE_PRODUCT: `${endpoint}report-revenue-product`,
    WEB_REPORT_REVENUE_CUSTOMER: `${endpoint}report-revenue-customer`,
    WEB_PRODUCTS_WAREHOUSE: `${endpoint}products_warehouse`,
    WEB_PRODUCTS_WAREHOUSE_EDIT: `${endpoint}products_warehouse_edit`,

    WEB_NETWORK_HOME: `${endpoint}network_home`,
    WEB_NETWORK_HOME_EDIT: `${endpoint}network_home_edit`,

    WEB_NETWORK_HOME_OPERATOR: `${endpoint}network_home_operator`,
    WEB_NETWORK_HOME_OPERATOR_EDIT: `${endpoint}network_home_operator_edit`,

    
    WEB_UNIT: `${endpoint}unit`,
    WEB_UNIT_EDIT: `${endpoint}unit_edit`,

    
    
    WEB_MERTERIAL_NORMS: `${endpoint}meterial_norms`,
    WEB_MERTERIAL_NORMS_EDIT: `${endpoint}meterial_norms_edit`,
}
