import React, { Component, createRef } from "react";
import { Row, Col, Container } from 'react-bootstrap';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import { connect } from "react-redux";
import * as ActionsWeb from "../../../../actions/indexWeb";

import "./style.css";
import * as LINK from "../../../../constants/Links";

import * as LinkApi from "../../../../constants/LinkApi";
import {
Modal, Upload, Progress, message
} from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ImgCrop from 'antd-img-crop';

class Upload_ extends Component {

    constructor(props) {
        super(props);

        this.state = {
            isCropImg: false,
            progress: 0,
            fileList: [

            ],

            previewVisible: false,
            previewImage: "",
            previewTitle: "",
            isOk: "",
        }

    }
    setPreviewVisible = (type) => {
        this.setState(state => {
            return {
                previewVisible: type
            }
        })
    }
    setPreviewTitle = (type) => {
        this.setState(state => {
            return {
                previewTitle: type
            }
        })
    }
    setPreviewImage = (type) => {
        this.setState(state => {
            return {
                previewImage: type
            }
        })
    }
    setProgress = (type) => {
        this.setState(state => {
            return {
                process: type
            }
        })
    }
    handleCancel = () => this.setPreviewVisible(false);
    handlePreview = async (file) => {

        if (!file.url && !file.preview) {
            file.preview = await this.getBase64(file.originFileObj);
        }

        this.setPreviewImage(file.url || file.preview);
        this.setPreviewVisible(true);
        this.setPreviewTitle(file.name || file.url.substring(file.url.lastIndexOf('/') + 1));
    };
    handleChange = async ({ fileList: newFileList }, type_f) => {
        // if (type_f === "avata" || type_f === "video") {
        //     this.props.setimgesInfoRemove(this.props.type_, this.state.fileList[0])
        // }
        // for (let i = newFileList.length - 1; i >= 0; i--) {
        //     const file = newFileList[i];
        //       try {
        //         const isValid = await this.beforeUpload(file);
        //         if (!isValid) {
        //             newFileList.splice(i, 1); // Loại bỏ tệp không hợp lệ
        //         }
        //       } catch (error) {
        //         console.log('====================================');
        //         console.log("errorerrorerror", error);
        //         console.log('====================================');
        //       }

        // }
        console.log('====================================');
        console.log("newFileListnewFileListnewFileList", newFileList);
        console.log('====================================');
        let { max } = this.props;
        if (max === 1) {

            newFileList = [newFileList[newFileList.length - 1]];

        }
        else {
            if (max < newFileList.length) {
                newFileList = [newFileList.slice(-max, newFileList.length)];
            }

        }

        this.setState(state => {
            return {
                fileList: newFileList,

            }
        }, () => {
            this.props.setimgesInfo(this.props.type_, newFileList)



        })

        this.setFileList(newFileList);


    }

    setFileList = (type) => {
        this.setState(state => {
            return {
                fileList: type
            }
        })
    }
    uploadButton = (focus) => {
        let { title, icon } = this.props;
        return <div className="upload-icon-w" style={focus ? {
            border: "dashed 1px red",
            backgroundColor: "#fb929266"
        } : {}} >
            {icon ? icon : <PlusOutlined />}
            <div
                style={{
                    marginTop: 8,
                }}
            >
                {title}
            </div>
        </div>
    }
    static getDerivedStateFromProps(nextProps, prevState) {

        if (nextProps.fileList && nextProps.fileList !== prevState.fileList) {


            return {
                fileList: nextProps.fileList
            }
        }
        return null;

    }
    componentDidMount = () => {


    }
    getBase64 = (file) =>
        new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);

            reader.onload = () => resolve(reader.result);

            reader.onerror = (error) => reject(error);
        });
    // upload = (e) =>
    // {

    //     let value = e.file;

    //     let form_data = new FormData();
    //     form_data.append('folder_post', "products");
    //     form_data.append('files[]', value);

    //     this.props.UploadFile(form_data);
    //     // this.handlePreview(value);
    // }
    removeFile = (file) => {

        this.props.setimgesInfoRemove(this.props.type_, file)

    }
    uploadImage = async ({ onSuccess, onError, onProgress, file }) => {
        console.log("filefilefilefilefile ", file);
        if (this.props.multiple == false) {
            file.originFileObj = file;
            let newFileList = [...this.state.fileList, file]
            let { max } = this.props;
            if (max === 1) {

                newFileList = [newFileList[newFileList.length - 1]];

            }
            else {
                if (max < newFileList.length) {
                    newFileList = [newFileList.slice(-max, newFileList.length)];
                }

            }

            this.setState(state => {
                return {
                    fileList: newFileList,

                }
            }, () => {
                this.props.setimgesInfo(this.props.type_, newFileList)

                onSuccess("ok");

            })
        } else {
            onSuccess("ok");
            this.props.setimgesInfo(this.props.type_, this.state.fileList)
        }


        // this.setState(state=>{
        //     return {
        //         isOk:"ok"
        //     }
        // })


        // console.log('====================================');
        // console.log("onSuccessonSuccessonSuccess",onSuccess);
        // console.log('====================================');

    }

    // onSuccess = (res) =>
    // {  
    //     console.log('====================================');
    //     console.log("onSuccessonSuccessonSuccess",res);
    //     console.log('====================================');

    // }
    // beforeUpload = (file) => {
    //   this.removeFile(file);
    //     const that=this;
    //     return new Promise((resolve, reject) => {
    //       const reader = new FileReader();
    //       reader.readAsDataURL(file);
    //       reader.onload = () => {
    //         const img = new Image();
    //         img.src = reader.result;
    //         img.onload = () => {
    //           if (img.width >= 300 && img.height >= 300) {
    //             resolve();
    //           } else {

    //             message.error('Chiều rộng và chiều cao của hình ảnh phải ít nhất 300x300.');
    //             reject();
    //           }
    //         };
    //       };
    //     });
    // };
    beforeUpload = async (file) => {
        if(this.props.type_!="video"){
            try {
                await this.checkImageDimensions(file);
                return true; // Return true to proceed with the upload
            } catch (error) {
                return Upload.LIST_IGNORE; // Return false to ignore the file
            }
        }
        
    };
    checkImageDimensions = (file) => {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                const img = new Image();
                img.src = reader.result;
                img.onload = () => {
                    if (img.width >= 300 && img.height >= 300) {
                        resolve();
                    } else {
                        message.error('Chiều rộng và chiều cao của hình ảnh phải ít nhất 300x300.');
                        reject();
                    }
                };
            };
        });
    };
    render() {
        let { progress } = this.state;
        let { isCropImg, accept, multiple, max, type_f, focus } = this.props;
        return (
            <>

                {isCropImg && <ImgCrop rotate>
                    <Upload
                        accept={accept}
                        customRequest={() => this.uploadImage}
                        onRemove={multiple ? this.removeFile : false}
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        // action={`http://127.0.0.1:8000/api/v1/uploadfile`}
                        onChange={(e) => this.handleChange(e, type_f)}
                        multiple={multiple}
                        beforeUpload={this.beforeUpload}
                    >
                        {this.state.fileList.length >= 8 ? null : this.uploadButton(focus)}
                    </Upload>
                </ImgCrop>}
                {!isCropImg && <>
                    <Upload
                        accept={accept}
                        customRequest={this.uploadImage}
                        onRemove={multiple ? this.removeFile : false}
                        // action={`http://127.0.0.1:8000/api/v1/uploadfile`}
                        listType="picture-card"
                        fileList={this.state.fileList}
                        onPreview={this.handlePreview}
                        onChange={(e) => this.handleChange(e, type_f)}
                        multiple={multiple}
                        beforeUpload={this.beforeUpload}
                    >
                        {this.state.fileList.length >= 8 ? null : this.uploadButton(focus)}
                    </Upload>
                    {progress > 0 ? <Progress percent={progress} /> : null}
                </>}
                <Modal visible={this.state.previewVisible} title={this.state.previewTitle} footer={null} onCancel={this.handleCancel}>
                    <img
                        alt="example"
                        style={{
                            width: '100%',
                        }}
                        src={this.state.previewImage}
                    />
                </Modal>
            </>

        );
    }

}

const mapStateToProps = (state) => {
    return {

    };
};
const mapDispatchToProps = (dispatch, Props) => {
    return {

    };
};
export default connect(mapStateToProps, mapDispatchToProps)(Upload_);