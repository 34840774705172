import { Button, Modal, notification } from 'antd';
import React, { useEffect, useState } from 'react';
import {
SmileOutlined, MehOutlined, WarningOutlined
} from '@ant-design/icons';
import * as CaseNotification from '../../constants/CaseNotification';
import { useDispatch, useSelector } from 'react-redux';
import { getCookie } from '../../service';
import * as CaseSocket from "../../constants/CaseSocket";

import * as ActionsWeb from "./../../actions/indexWeb";
import * as Colors from "./../../constants/Colors";
import { setIsync } from '../../actions/indexApp';
const Index = ({ ...props }) => {
  const [socket, setSocket] = useState(null);
  const [showExportRes, setshowExportRes] = useState(false);
  const [dataExportRes, setdataExportRes] = useState(null);
  const dispatch = useDispatch()
  const { appReducer, Web } = useSelector(state => state);
  useEffect(() => {
    setSocket(appReducer.socket)
    return () => {

    };
  }, [appReducer.socket]);
  useEffect(() => {
    if (socket) {
      socket.addEventListener('message', (event) => {
        try {
          let json = JSON.parse(event.data);
         
          if (json.confirm) {
            swichConfirmNotify(json);
          } else {
            swichNotify(json)
          }
        } catch (error) {

        }


      });
    }

    return () => {

    };
  }, [socket]);

  const swichNotify = (json) => {

    let user = getCookie("user");
    if (!user) {
      return;
    }
    user = JSON.parse(user);
    console.log('====================================');
    console.log("jsonjsonjson", json,CaseSocket.SYNC_EXPORT_BILL_MULTIPLE);
    console.log('====================================');
    dispatch(setIsync(false));
    switch (json.type) {
      case CaseSocket.NOTIFICATON:


        if (user.id === json.user_id) {
          try {

            let title = "Thông tin";
            let text = json.msg;
            let log_level = json.log_level;
            openNotification(log_level, title, text);
          } catch (error) {

            // console.error("errorerror", error);
          }

        }
        break;
      case CaseSocket.PUSH_SYNC_DATA_PR_TIKTOK:

        if (user.id === json.user_id) {
          try {
          
            let title = "Thông tin";
            let text = json.msg;
            let log_level = json.log_level;
            openNotification(log_level, title, text);
          } catch (error) {

            // console.error("errorerror", error);
          }

        }
        break;
      case CaseSocket.PUSH_SYNC_DATA_ORDER_TIKTOK:
       
        if (user.id === json.user_id) {
          try {

            let title = "Thông tin";
            let text = json.msg;
            let log_level = json.log_level;
            openNotification(log_level, title, text);
          } catch (error) {

            // console.error("errorerror", error);
          }

        }
        break;
      case CaseSocket.SYNC_EXPORT_BILL_MULTIPLE:
       
        if (user.id === json.user_id) {
          if(json.data){
            setdataExportRes(JSON.parse(json.data));
          }
          
          setshowExportRes(true)

         
          try {

            let title = "Thông tin";
            let text = json.msg;
            let log_level = json.log_level;
            openNotification(log_level, title, text);
          } catch (error) {

            // console.error("errorerror", error);
          }

        }
        break;
        case CaseSocket.SYNC_EXPORT_BILL_MULTIPLE:
         
        if (user.id === json.user_id) {
          if(json.data){
            setdataExportRes(JSON.parse(json.data));
          }
          
          setshowExportRes(true)

         
          try {

            let title = "Thông tin";
            let text = json.msg;
            let log_level = json.log_level;
            openNotification(log_level, title, text);
          } catch (error) {

            // console.error("errorerror", error);
          }

        }
        break;

        case CaseSocket.SYNC_TO_LOCAL_WEBHOOK:
       
            try {

              let title = "Thông tin";
              let text = json.msg;
              let log_level = json.log_level;
              openNotification(log_level, title, text);
            } catch (error) {

              // console.error("errorerror", error);
            }
        break;
      default:
        // try {

        //   let title = "Thông tin";
        //   let text = json.msg;
        //   let log_level = json.log_level;
        //   openNotification(log_level, title, text);
        // } catch (error) {

        //   // console.error("errorerror", error);
        // }
        break;
    }
  }
  const swichConfirmNotify = (json) => {

    switch (json.type) {
      case CaseSocket.NOTIFICATON:
        let user = getCookie("user");
        if (!user) {
          return;
        }
        user = JSON.parse(user);

        if (user.id === json.user_id) {
          try {
          
            let title = "Thông tin";
            let text = json.msg;
            let log_level = json.log_level;
            openConfirmNotification(log_level, title, text, json.notification_id);
          } catch (error) {

            console.error("errorerror", error);
          }

        }
        break;

      default:
        break;
    }
  }
  const infoNotification = (title, text) => {
    notification.info({
      message: title,
      description: text,
      icon: (
        <SmileOutlined
          style={{
            color: Colors.colorInfo,
          }}
        />
      ),
    });
  };
  const errorNotification = (title, text) => {
    notification.error({
      message: title,
      description: text,
      icon: (
        <MehOutlined
          style={{
            color: Colors.colorError,
          }}
        />
      ),
    });
  };
  const wanrningNotification = (title, text) => {
    notification.error({
      message: title,
      description: text,
      icon: (
        <WarningOutlined
          style={{
            color: Colors.colorWarning,
          }}
        />
      ),
    });
  };

  const successNotification = (title, text) => {
    notification.error({
      message: title,
      description: text,
      icon: (
        <SmileOutlined
          style={{
            color: Colors.colorSuccess,
          }}
        />
      ),
    });
  };
  const close = (id) => {
    dispatch(ActionsWeb.confirmNotificationRequest(id))

  };
  const confirmInfoNotification = (title, text, id) => {
    const key = `open${Date.now()}`;
    const btn = (
      <Button type="primary" size="small" onClick={() => { notification.close(key); close(id) }}>
        xác nhận
      </Button>
    );
    notification.info({
      message: title,
      description: text,
      btn,
      key,
      onClose: () => close(),
    });
  }
  const openNotification = (level, title, text) => {

    switch (level) {
      case CaseNotification.INFO:
        return infoNotification(title, text);
        break;
      case CaseNotification.ERROR:
        title = "Lỗi";
        return errorNotification(title, text);
        break;
      case CaseNotification.WARNING:
        title = "Chú ý";
        return wanrningNotification(title, text);
        break;
      case CaseNotification.SUCCESS:
        return successNotification(title, text);
        break;
      default:
        return infoNotification(title, text);
        break;
    };
  }

  const openConfirmNotification = (level, title, text, id) => {

    switch (level) {
      case CaseNotification.INFO:
        return confirmInfoNotification(title, text, id);
        break;

      default:
      case CaseNotification.INFO:
        return confirmInfoNotification(title, text, id);
        break;
    };
  }
  return (
    <>
      <Modal
        title="Thông báo"
        visible={showExportRes}
        onOk={() => { setshowExportRes(true) }}
        onCancel={() => { setshowExportRes(false) }}

        footer={false}
      >
        <div className="d-flex">
          <div className="" style={{ width: "50%" }}>
            <span>Phiếu lỗi</span>
            <div>
              {dataExportRes?.fail && dataExportRes?.fail.map((value, index) => {
                return <p className="text-danger">{value}</p>
              })}
            </div>
          </div>
          <div className="" style={{ width: "50%" }}>
            <span>Phiếu Thành công</span>

            <div>
              {dataExportRes?.success && dataExportRes?.success.map((value, index) => {
                return <p className="text-success">{value}</p>
              })}
            </div>
          </div>
        </div>
      </Modal>
    </>
  )
}

export default Index;
