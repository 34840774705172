import React, { useEffect, useState } from 'react'
import {  Button, message, } from 'antd';

import * as ActionWeb from "./../../../../../../actions/indexWeb";
import { formatMN1 } from '../../../../../../service';
const Index = ({ record ,formRef,setRecord}) => {

  const [loading, setloading] = useState(false);
  const [record_, setrecord_] = useState(false);

  console.log('recordrecord:', record);
  const exportBill= async (type)=>{
      try {
        let response=null;
        if(type==1){
           response= await  ActionWeb.ExportBillVatRequest(record);
      
        
        }
        if(type==2){
           response= await  ActionWeb.SignBillVatRequest(record);
      
         
        }

        if(type==3){
          record.sign=1;
           response= await  ActionWeb.ExportBillVatRequest(record);
      
        
        }
        if(response){
          const result = await response.json();
          if(result.code!==200){
             message.warning(result.message)
            
          }else{
             message.success(result.message)
             setRecord(result.data)
             if(formRef.current){
               formRef.current.submit();
             }
          }
          console.log('result:', result);
        }
        
      } catch (error) {
        console.error('Error:', error);
      }
  }

  useEffect(() => {
    setrecord_(record);
    return () => {
      
    };
  }, [record.id]);
  return (
    <div className='position-relative  h-100'>
      <div className='row'>
        <div className='col-lg-7 col-md-7 col-sm-12'>
          <div className=''>
            <h5>{record?.status}</h5>
            <div className='row'>
              <div className='col-lg-4 col-md-4 col-sm-12'>

              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>

              </div>
              <div className='col-lg-4 col-md-4 col-sm-12'>

              </div>
            </div>
          </div>

          <div className=''>
            <h5>sản phẩm</h5>
            <div className='pro-show'>
              {record_?.line_items && record_?.line_items.map((value, index) => {
                return <div className='item_ p-2 mb-2'  >
                  <div className='w-100 p-1'>
                    <span>{value.sku_id}</span>
                  </div>
                  <div className='w-100 d-flex p-1 justify-content-start align-items-center'>
                    <div className='img_  p-1  ' style={{ width: "70px" }}>
                      <img src={value.sku_image} style={{ width: "50px", height: "50px" }} />
                    </div>
                    <div className='info p-1 ms-2 ps-4' style={{ width: "90%" }}>
                      <span>{value.product_name}</span>
                    </div>
                    <div className='price p-1 justify-content-end align-items-center'>
                      <span>{value.sale_price}</span>
                    </div>
                  </div>
                </div>

              })}
            </div>
          </div>
        </div>
        <div className='col-lg-1 col-md-1 col-sm-0'>

        </div>
        <div className='col-lg-4 col-md-4 col-sm-12'>
          <div className=''>
            <h5>Thanh toán</h5>
            <p>{record_?.payment?.payment_method_name}</p>
            <div>
              <div className='d-flex  justify-content-between'>
                <div className='fw-bold me-2'>Tổng (các) mặt hàng sau khi chiết khấu </div>
                <div className=''>{record_?.payment?.sub_total} </div>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='me-2'>Tổng (các) mặt hàng trước khi chiết khấu </div>
                <div className=''>{formatMN1(record_?.payment?.original_total_product_price)} </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='me-2'>Chiết khấu của người bán cho các mặt hàng </div>
                <div className=''>{formatMN1(record_?.payment?.seller_discount)} </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='me-2'>Chiết khấu của TikTok Shop cho các mặt hàng </div>
                <div className=''>{formatMN1(record_?.payment?.platform_discount)} </div>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='fw-bold me-2'>Phí vận chuyển sau khi chiết khấu </div>
                <div className=''>{formatMN1(record_?.payment?.shipping_fee)} </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='me-2'>Phí vận chuyển trước khi chiết khấu </div>
                <div className=''>{formatMN1(record_?.payment?.original_shipping_fee)} </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className='me-2'>Chiết khấu phí vận chuyển của người bán </div>
                <div className='align-end'>{formatMN1(record_?.payment?.shipping_fee_seller_discount)} </div>
              </div>
              <div className='d-flex justify-content-between'>
                <div className=''>Chiết khấu phí vận chuyển của TikTok Shop </div>
                <div className=''>{formatMN1(record_?.payment?.shipping_fee_platform_discount)} </div>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='fw-bold me-2'>Thuế </div>
                <div className=''>{formatMN1(record_?.payment?.tax)} </div>
              </div>

              <div className='d-flex justify-content-between'>
                <div className='fw-bold me-2'>Tổng cộng </div>
                <div className=''>{formatMN1(record_?.payment?.total_amount)} </div>
              </div>
            </div>
          </div>

          <div className='mt-4'>
            <h5>Thông tin khách hàng</h5>
            <p>Tên: {record_?.recipient_address?.name}</p>
            <div>
              <div className='d-flex  justify-content-between'>
                <div className='fw-bold me-2'>Địa chỉ vận chuyển </div>
               
              </div>
              <div className='d-flex justify-content-between'>
               
                <div className='wrap-text'>{record_?.recipient_address?.full_address} </div>
              </div>
              <div className='d-flex justify-content-between'>
               
                <div className='wrap-text'>{record_?.recipient_address?.phone_number} </div>
              </div>
              {record_?.recipient_address?.district_info && record_?.recipient_address?.district_info.map((value, index) => {
  
                  return  <div className='d-flex justify-content-between'>
               
                      <div className='wrap-text'>{value?.address_name} </div>
                    </div>
              }) }


             
              
            </div>
          </div>
        </div>
      </div>

      <div className=' position-absolute d-flex justify-content-end w-100' style={{ bottom:0 }}>  

      <Button type='primary' loading={loading} onClick={() => exportBill(1)} disabled={record_?.invCode}>Xuất hóa đơn vat</Button>
      <Button type='primary' loading={loading} onClick={() => exportBill(2)} disabled={!record_?.invCode || (record_?.invCode && record_.isSign==1 )  }>Ký hóa đơn vat</Button>
      <Button type='primary' loading={loading} onClick={() => exportBill(3)} disabled={record_?.invCode}>Xuất + Ký hóa đơn vat</Button>
      </div>
    </div>
  )
}

export default Index
